<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button icon="add" hint="{{ 'SHARED.ADD' | translate }}" (onClick)="add()"></dx-button>
    <dx-button icon="print" hint="{{ 'SHARED.PRINT' | translate }}" (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #auctionSuppliersGrid id="printSection"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="true"
                [dataSource]="customDataSource"
                [hoverStateEnabled]="true"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="edit($event)"
                (onInitialized)="initColumn($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_suppliers"></dxo-state-storing>
    <dxo-remote-operations [paging]="true"
                           [filtering]="true"
                           [sorting]="true"></dxo-remote-operations>
    <dxo-pager [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showInfo]="true"
               [showNavigationButtons]="true"
               [showPageSizeSelector]="true"
               [visible]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isActiveOnAuctionCluster" caption="{{ 'SHARED.IS_ACTIVE' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="supplierName" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="supplierNumber" caption="{{ 'SUPPLIERS.NUMBER' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="email" caption="{{ 'SHARED.EMAIL' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="telNumber" caption="{{ 'SHARED.TEL_NUMBER' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="vat" caption="{{ 'SHARED.VAT' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="address1" caption="{{ 'SHARED.ADDRESS1' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="zip" caption="{{ 'SHARED.ZIP' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="city" caption="{{ 'SHARED.CITY' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="country" caption="{{ 'SHARED.COUNTRY' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column type="buttons" caption="..." [allowHiding]="false">
      <dxi-button icon="edit" hint="{{ 'SHARED.EDIT' | translate }}"
                  [onClick]="edit"></dxi-button>
      <dxi-button icon="trash" hint="{{ 'SHARED.DELETE' | translate }}"
                  [onClick]="deleteItem"></dxi-button>
      <dxi-button icon="remove" hint="{{ 'SUPPLIERS.DEACTIVATE_SUPPLIER' | translate }}"
                  [onClick]="enableDisable"
                  [visible]="calculateDisabledDisplayValue"></dxi-button>
      <dxi-button icon="check" hint="{{ 'SUPPLIERS.ACTIVATE_SUPPLIER' | translate }}"
                  [onClick]="enableDisable"
                  [visible]="calculateEnabledDisplayValue"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

<supplier-component #details
                    (onClosed)="onDetailsComponentClosed($event)"></supplier-component>
<confirmation-component #confirmation title="{{ 'SUPPLIERS.DELETE_SUPPLIER' | translate }}"
                        message="{{ 'SUPPLIERS.DELETE_SUPPLIER_MESSAGE' | translate }}" buttonCloseText="DELETE" cancellable="true"
                        (close)="deleteSelected();"></confirmation-component>
