import { Component, ViewChild, OnInit, Injector, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import DataGrid from 'devextreme/ui/data_grid';
import * as _moment from 'moment';

// components
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { MasterDetailsDetailComponent } from './master-details-detail.component';
import { PublishLotComponent } from './publish-lot.component';

// models
import { Cookies } from '../../shared/constants/cookies';
import { DecimalTypeEnum } from '../../shared/enum/decimalTypeEnum';
import { NotificationTypeEnum } from '../../shared/enum/notificationTypeEnum';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { Catalog } from '../../shared/models/catalog';
import { GenericLotRow, GenericLotColumn } from '../../shared/models/generic-lot';
import { MasterData } from '../../shared/models/master-data';
import { Language } from '../../shared/models/language';
import { Lot } from '../../shared/models/lot';
import { Product } from '../../shared/models/product';

// services
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';
import { CatalogService } from '../shared/services/catalog.service';
import { LotService } from '../shared/services/lot.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { ProductService } from '../shared/services/product.service';


export class MasterDataDropdown {
  value: any;
  label: string;
  img?: string;
}

@Component({
  selector: 'master-details-component',
  templateUrl: 'master-details.component.html',
  styleUrls: ['./master-details.component.scss']
})
export class MasterDetailsComponent extends FullListComponent<GenericLotRow, MasterDetailsDetailComponent> implements OnInit, OnDestroy {

  @ViewChild('publishLotComponent') publishLotComponent: PublishLotComponent;
  @ViewChild('groupFilter') groupFilter: any;
  @ViewChild('removeLotsConfirmation') removeLotsConfirmation: ConfirmationComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  catalog: Catalog;
  product: Product;
  columns: Array<any> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  languages: Array<Language> = [];
  masterDatas: Array<MasterData> = [];
  masterDataDropdowns: Array<any> = [];
  items = [];
  currentPage = 1;
  lastRecordedPage = 1;
  catalogId: number;
  groupFiltersShowing = false;
  confirmationErrorMessage: string;

  dataGridInstance: DataGrid;
  gridColumns = [];
  allMode: string;
  checkBoxesMode: string;
  selectedRows: Array<number> = [];
  gridItems = [];
  itemsToRemove = [];
  showActionsMessage = false;
  isMultiEditorModeEnabled: boolean = false;
  isTouchMode: boolean = false;
  auctionClusterNotification: AuctionClusterNotification;
  lastTimeSentNotification = '';

  lang = 'nl-be';

  constructor(
    protected injector: Injector,
    private dateTimeService: DateTimeService,
    private catalogService: CatalogService,
    private dataService: LotService,
    private masterDataService: MasterDataService,
    private productService: ProductService,
    private languageService: LanguageService,
    private notificationService: AuctionClusterNotificationService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {
    super(injector, GenericLotRow);
    this.lang = translateService.currentLang;
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.allMode = 'allPages';
    if (this.isForecastCatalog)
      this.checkBoxesMode = 'none';
    else
      this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.setTranslations('LOTS');
    this.isTouchMode = this.catalogService.setSupplyEditorMode(this.cookieService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }


  getData() {
    this.lastRecordedPage = this.currentPage;

    this.fetchLots();

    this.catalogId = +this.route.snapshot.params['catalogId'];

    this.spinner.show();
    const sources: Array<any> = [];

    if (this.isForecastCatalog) {
      sources.push(this.catalogService.getAllForecastCatalogs(this.id));
      this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');
    }
    else {
      sources.push(this.catalogService.getAllSupplyCatalogs(this.id));
      this.title.set('AUCTION.SUPPLY_CATALOG_MANAGEMENT');
    }

    this.title.add('AUCTION.MASTER_DETAILS.TITLE');

    forkJoin(...sources).subscribe((res: Array<any>) => {
      this.catalog = res[0].find(f => f.catalogId === this.catalogId);

      if (this.route.snapshot.routeConfig.data && this.route.snapshot.routeConfig.data['isForecast']) {
        this.getAuctionClusterNotificationLastTimeSent(NotificationTypeEnum.PublishingForecastCatalog);
      } else {
        this.getAuctionClusterNotificationLastTimeSent(NotificationTypeEnum.PublishingSupplyCatalog);
      }

      this.title.append(this.catalog.name);

      forkJoin(
        this.languageService.getAuctionClusterLanguages(this.id),
        this.productService.getProduct(this.id, this.catalog.productId)
      ).subscribe(result => {
        this.languages = result[0];
        this.product = result[1];
        if (this.product.enableDesktopScreenLayout && this.product.enableTouchScreenLayout) {
          this.isMultiEditorModeEnabled = true;
        } else {
          this.isMultiEditorModeEnabled = false;
        }

        this.spinner.hide();

        this.getMasterDataLists(true);
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });

    });
  }

  getMasterDataLists(isFirstLoad = false) {
    const masterDataListIds: Array<number> = [];

    let manualGroupingSelection = this.product.manualProductPropertyFilterSelections;

    manualGroupingSelection.forEach(p => {
      const property = this.product.productProperties.find(pp => pp.productPropertyId === p.productPropertyId);
      if (property && property.masterDataListId && masterDataListIds.indexOf(property.masterDataListId) === -1) {
        masterDataListIds.push(property.masterDataListId);
      }
    });

    this.spinner.show();
    this.masterDataService.getMasterDataListByIds(masterDataListIds, this.id)
      .subscribe(res => {
        res.forEach((result: MasterData) => {
          this.masterDatas.push(result);
          /*const catalogMasterDataIds: Array<number> = [];
          this.masterDatas.forEach(masterData => {
            masterData.fields.forEach(masterDataField => {
              if (masterDataField.useFieldInCatalog) {
                catalogMasterDataIds.push(masterDataField.masterDataListFieldId);
              }
            });
          });*/
        });
        this.parseMasterDataDropdown();

        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  parseMasterDataDropdown() {
    this.product.manualProductPropertyFilterSelections.forEach(s => {
      const dropdownValues: Array<MasterDataDropdown> = [];
      if (this.product.productProperties
        .find(pp => pp.productPropertyId === s.productPropertyId)
        .propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
        const masterData = this.masterDatas
          .find(md => md.masterDataListId === this.product.productProperties
            .find(pp => pp.productPropertyId === s.productPropertyId).masterDataListId);

        const useInCatalogFields = masterData.fields.filter(f => f.useFieldInCatalog);

        if (useInCatalogFields.length === 0) {
          useInCatalogFields.push(masterData.fields[0]);
        }

        useInCatalogFields.forEach(field => {
          masterData.rows.forEach(row => {
            row.values.forEach(rowValue => {
              if (rowValue.masterDataListFieldId === field.masterDataListFieldId) {
                switch (field.propertyTypeId) {
                  case ProductPropertyTypeEnum.NUMBER:
                    if (rowValue.intValue !== null) {
                      dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.intValue).toString() });
                    }
                    break;
                  case ProductPropertyTypeEnum.TEXT:
                    if (rowValue.stringValue !== null) {
                      let text = rowValue.stringValue.replace(/<[^>]*>/g, '');
                      if (field.translatable) {
                        text = this.getTranslation(text);
                      }
                      if (text != null) {
                        dropdownValues.push({ value: row.masterDataListRowId, label: text });
                      }
                    }
                    break;
                  case ProductPropertyTypeEnum.DECIMAL:
                    if (rowValue.decimalValue !== null) {
                      dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.decimalValue).toString() });
                    }
                    break;
                  case ProductPropertyTypeEnum.DATE:
                    if (rowValue.dateTimeValue !== null) {
                      const dateString = this.dateTimeService.getDateStringByFormatAnyUtc(rowValue.dateTimeValue, field.propertyTypeFormatId);
                      dropdownValues.push({ value: row.masterDataListRowId, label: dateString });
                    }
                    break;
                  case ProductPropertyTypeEnum.IMAGE:
                    if (rowValue.imageValue !== null) {
                      dropdownValues.push({ value: row.masterDataListRowId, label: '', img: rowValue.imageValue });
                    }
                    break;
                  case ProductPropertyTypeEnum.BOOLEAN:
                    if (rowValue.booleanValue !== null) {
                      dropdownValues.push({
                        value: row.masterDataListRowId,
                        label: `${rowValue.booleanValue ? 'true' : 'false'}`
                      });
                    }
                    break;
                  default: break;
                }
              }
            });
          });
        });
      }

      for (let i = 0; i < dropdownValues.length; i++) {
        for (let j = i + 1; j < dropdownValues.length; j++) {
          if (dropdownValues[i].value === dropdownValues[j].value) {
            if (dropdownValues[j].label !== '') {
              dropdownValues[i].label = dropdownValues[i].label + ' - ' + dropdownValues[j].label;
            }
            dropdownValues.splice(j, 1);
          }
        }
      }
      dropdownValues.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }

        return 0;
      });
      this.masterDataDropdowns.push({ productPropertyId: s.productPropertyId, values: dropdownValues });
    });

  }

  fetchLots() {
    this.data = [];
    this.items = [];
    this.spinner.show();

    this.dataService.getMasterDetails(this.id, +this.route.snapshot.params['catalogId'], this.isForecastCatalog)
      .subscribe(result => {
        this.items = result.rows;
        this.parseColumns(result.columnTitles);
        this.parseObject(result.rows);
        this.generateTable(result);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  private parseObject(rows: Array<GenericLotRow>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    rows.forEach(row => {
      const obj = new Object();
      obj['isInManualGroup'] = row.isInManualGroup;
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          if (row.values[i])
            obj['key' + i] = _moment.utc(row.values[i]).local().toISOString();
        }
        else if (column.propertyTypeId === ProductPropertyTypeEnum.TEXT
          || column.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypeEnum.IMAGE
          || column.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i];
        }
      });
      row.selected = false;
      obj['__item__'] = row;

      this.data.push(obj);
    });

    if (this.isForecastCatalog) {
      let sortBy = [];
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          sortBy.push({ prop: 'key' + i, direction: -1 });
        }
        else {
          sortBy.push({ prop: 'key' + i, direction: 1 });
        }
      });

      this.data.sort(function (a, b) {
        let i = 0, result = 0;
        while (i < sortBy.length && result === 0) {
          if (a[sortBy[i].prop] && b[sortBy[i].prop]) {
            result = sortBy[i].direction * (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString() ? -1 : (a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString() ? 1 : 0));
          }
          else if (a[sortBy[i].prop] && !b[sortBy[i].prop]) {
            result = -1;
          }
          else if (!a[sortBy[i].prop] && b[sortBy[i].prop]) {
            result = 1;
          }
          i++;
        }
        return result;
      })
    }

    // Reset default page size
    this.currentPage = this.lastRecordedPage;
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });


    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  edit(lotId: number, event: Event) {
    event.stopPropagation();

    if (this.isForecastCatalog) {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId], { queryParams: { masterDetailsLotId: lotId } });
    } else {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId], { queryParams: { masterDetailsLotId: lotId } });
    }
  }

  add() {
    if (this.isForecastCatalog) {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId]);
    } else {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId]);
    }
  }
  remove(lotId: number, event: Event) {

  }


  printSupply(lotId: number, event: Event) {
    event.stopPropagation();

    const ids = [];
    ids.push(lotId);

    this.catalogService.getAllSupplyCatalogs(this.id).subscribe(res => {
      this.catalog = res.find(f => f.catalogId === this.catalogId);

      this.dataService.printSupply(this.catalog.auctionClusterId, this.catalogId, ids, true)
        .subscribe(r => {
        }, error => {
          this.errorService.show(error);
        });
    });
  }

  publishLots() {
    this.publishLotComponent.modalTitle = this.translations.PUBLISH;
    this.publishLotComponent.open(this.catalog.auctionId, this.catalogId, this.isForecastCatalog);
  }

  switchEditorMode() {
    let supplyEditorMode = this.cookieService.get(Cookies.SUPPLY_EDITOR_MODE);
    if (supplyEditorMode && supplyEditorMode === 'desktop') {
      this.isTouchMode = true;
      this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'touch');
    } else {
      this.isTouchMode = false;
      this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
    }
  }

  goBack() {
    if (this.isForecastCatalog) {
      this.router.navigate(['/auction/forecastcatalogs/' + this.id]);
    } else {
      this.router.navigate(['/auction/supplycatalogs/' + this.id]);
    }
  }

  showGroupFilters() {

    var ids = [];

    if (this.data && this.data.length) {
      this.data.forEach(d => {
        if (d.__item__ && d.__item__.selected) {
          ids = ids.concat(d.__item__.groupedIds);
        }
      });
    }

    if (ids.length === 0) {
      this.groupFiltersShowing = true;
      this.groupFilter.open(this.catalog.auctionId, this.catalogId, this.languages, this.product, this.masterDataDropdowns);
    }
    else {
      this.groupFiltersShowing = true;
      this.groupFilter.openPreselected(this.catalog.auctionId, this.catalogId, this.languages, this.product, this.masterDataDropdowns, ids);
    }

  }

  isSendNotificationDisabled() {
    if (this.data) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].__item__.isPublished)
          return false;
      }
    }
    return true;
  }

  sendNotification(event: Event) {
    let type = NotificationTypeEnum.PublishingSupplyCatalog;
    if (this.route.snapshot.routeConfig.data && this.route.snapshot.routeConfig.data['isForecast']) {
      type = NotificationTypeEnum.PublishingForecastCatalog;
    }

    this.spinner.show();
    this.notificationService.sendNotifications(this.catalog.auctionId, type, this.catalog.catalogId, null, null, new Date().getTimezoneOffset())
      .subscribe((result) => {
        //refresh last sent
        this.getAuctionClusterNotificationLastTimeSent(type);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  getAuctionClusterNotificationLastTimeSent(notificationType: NotificationTypeEnum) {
    this.notificationService.getAuctionClusterNotificationLastTimeSent(notificationType, this.catalog.auctionClusterId, this.catalog.catalogId)
    .subscribe((res: Date) => {
      if (res != null) {
        this.lastTimeSentNotification =  moment.utc(res).local().format('DD.MM.YYYY. HH:mm:ss');
      }
      else {
        this.lastTimeSentNotification = '';
      } 
    },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  hasLastTimeSentNotification() {
    var hasValue = this.lastTimeSentNotification != null && this.lastTimeSentNotification != undefined && this.lastTimeSentNotification != '';
    return hasValue;
  }

  hideGroupFilter() {
    this.groupFiltersShowing = false;
    this.manualRefresh();
  }

  manualRefresh() {
    this.getData();
  }

  deleteSelected() {
    //
  }

  anyRowSelected() {
    return this.data && this.data.length > 0 && this.data.filter(d => d.__item__ && d.__item__.selected === true).length > 0;
  }

  clearAll() {
    this.data.forEach(d => {
      if (d.__item__) {
        d.__item__.selected = false;
      }
    });
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    let allGridColumns;

    if (this.dataGridInstance.option().stateStoring) {
      allGridColumns = <any>localStorage.getItem(this.dataGridInstance.option().stateStoring.storageKey);
      if (allGridColumns) {
        allGridColumns = JSON.parse(allGridColumns);

        let storegeColumnNames = allGridColumns.columns.map(_ => _.dataField);
        let gridColumnNames = rows.columnTitles.map(_ => this.getTranslation(_.name));
        let columnNamesDiff = storegeColumnNames.map(_ => gridColumnNames.indexOf(_) !== -1 ? null : _).filter(_ => _ !== null);
        columnNamesDiff.forEach(element => {
          if (element !== 'isInManualGroup' && element !== '__buttons' && element !== 'isPublished') {
            this.dataGridInstance.deleteColumn(element);
          }
        });
      }
    }

    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);

      let isColumn = allGridColumns?.columns?.find(_ => _.dataField === columnName)

      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i + 2,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          format: this.getformatDevX(row.propertyTypeId, row.propertyTypeFormatId),
          editorOptions: {
            showClearButton: true
          }
        });
      }
    });

    this.gridColumns.push('isPublished');
    if (!this.isForecastCatalog) {
      this.gridColumns.push('isInManualGroup');
    }
    this.generateTableData();
  }

  getPrecision(propertyTypeId: number, propertyTypeFormatId: number) {
    if (propertyTypeId == ProductPropertyTypeEnum.DECIMAL) {
      if (propertyTypeFormatId == DecimalTypeEnum.DECIMAL_0digit)
        return 0;
      else if (propertyTypeFormatId == DecimalTypeEnum.DECIMAL_1digit)
        return 1;
      if (propertyTypeFormatId == DecimalTypeEnum.DECIMAL_2digit)
        return 2;
      if (propertyTypeFormatId == DecimalTypeEnum.DECIMAL_3digit)
        return 3;
      if (propertyTypeFormatId == DecimalTypeEnum.DECIMAL_4digit)
        return 4;
      if (propertyTypeFormatId == DecimalTypeEnum.DECIMAL_5digit)
        return 5;
    }

    return undefined;
  }

  getformatDevX(propertyTypeId: number, propertyTypeFormatId: number) {
    if (propertyTypeId == ProductPropertyTypeEnum.DECIMAL) {
      var decimalPoints = this.getPrecision(propertyTypeId, propertyTypeFormatId);

      return {
        precision: decimalPoints,
        type: 'decimal',
        formatter: (value) => {
          let numberFormatter = new Intl.NumberFormat(this.lang, { useGrouping: true, minimumFractionDigits: decimalPoints, maximumFractionDigits: decimalPoints });
          return numberFormatter.format(value);
        },
        parser: (value) => {
          return parseFloat(value);
        }
      };
    }

    return undefined;
  }

  generateTableData() {
    this.gridItems = [];
    this.data.forEach((row, i) => {
      let gridItem = {};
      this.gridColumns.forEach((column, j) => {
        if (column !== 'isPublished' && column !== 'isInManualGroup') {
          gridItem[column] = this.getTranslation(row['key' + j]);
        } else {
          if (column === 'isPublished') {
            gridItem[column] = row.__item__.isPublished;
          }
          if (column === 'isInManualGroup') {
            gridItem[column] = row['isInManualGroup'];
          }
        }
      });
      gridItem['__item__'] = row.__item__;

      this.gridItems.push(gridItem);
    });

    const rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (this.gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', this.gridItems);
      }
    } else {
      this.dataGridInstance.option('dataSource', this.gridItems);
    }
  }

  selectionChangedHandler = (e: any) => {
    this.data.forEach(d => {
      d.__item__.selected = false;
    });
    this.selectedRows.forEach(row => {
      const index = this.gridItems.findIndex(data => data === row);
      this.data[index].__item__.selected = true;
    });
  }

  rowClick = (e: any) => {
    this.edit(+e.data.__item__.id, new Event('click'));
  }

  editItem = (e: any) => {
    this.edit(+e.row.data.__item__.id, new Event('click'));
  }

  parseLotItems = (e: any) => {

    this.itemsToRemove = [];
    let lotIds = e.row.data.__item__.groupedIds;

    this.showActionsMessage = false;

    lotIds.forEach(itemId => {
      this.dataService.checkLotActions(this.catalog.auctionId, itemId).subscribe(res => {
        if (res) {
          this.itemsToRemove.push(itemId);
          this.showActionsMessage = true;
        } else {
          this.itemsToRemove.push(itemId);
        }
      }, error => {
        this.itemsToRemove.push(itemId);
      });
    });

    this.removeLotsConfirmation.opened = true;

  }

  removeSelectedLots = function () {

    this.spinner.show();

    this.itemsToRemove.forEach(element => {

      this.dataService.delete(this.catalog.auctionId, element)
        .subscribe((lots: Array<Lot>) => {

          this.manualRefresh();

        },
          error => {
            this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
            this.spinner.hide();
          });
    });

    this.spinner.hide();
  }

  printItem = (e: any) => {
    this.printSupply(+e.row.data.__item__.id, new Event('click'));
  }

  viewGridDeleteIcon = (e: any) => {
    let alreadyServedInClock = e.row.data.__item__.alreadyServedInClock;

    if(!alreadyServedInClock && this.catalog && this.catalog.enableDelete && !this.catalog.viewOnlyMode)
      return true;
    else
      return false;
  }
}
