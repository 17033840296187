<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}"
               icon="add"
               (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}"
               icon="print"
               (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #usersGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                (onRowClick)="editItem($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="font" caption="{{ 'PRODUCT_PROPERTY_STYLING.FONT' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="fontSize" caption="{{ 'PRODUCT_PROPERTY_STYLING.FONT_SIZE' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="colorTemplate" dataField="color" caption="{{ 'SHARED.COLOR' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="colorTemplate" dataField="backgroundColor" caption="{{ 'PRODUCT_PROPERTY_STYLING.BACKGROUND_COLOR' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="colorTemplate" dataField="borderColor" caption="{{ 'PRODUCT_PROPERTY_STYLING.BORDER_COLOR' | translate }}">
    </dxi-column>
    <dxi-column type="buttons">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'colorTemplate'">
      <dx-color-box [value]="data.value" [readOnly]="true" style="border: none"></dx-color-box>
    </div>
  </dx-data-grid>
</div>

<product-property-styling-component #details
                                    (onClosed)="onDetailsComponentClosed($event)"></product-property-styling-component>
<confirmation-component #confirmation title="{{ 'PRODUCT_PROPERTY_STYLING.DELETE_PRODUCT_PROPERTY_STYLING' | translate }}"
                        message="{{ 'PRODUCT_PROPERTY_STYLING.DELETE_PRODUCT_PROPERTY_STYLING_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
