import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid from 'devextreme/ui/data_grid';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { SupplierComponent } from './supplier.component';

// models
import { AuctionClusterSupplier } from '../shared/models/auction-cluster-supplier';
import { AuctionClusterUser } from '../shared/models/auction-cluster-user';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterSupplierService } from '../shared/services/auction-cluster.supplier.service';
import { AuctionClusterUserService } from '../shared/services/auction-cluster-user.service';


@Component({
  selector: 'suppliers-component',
  templateUrl: 'suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent extends FullListComponent<AuctionClusterSupplier, SupplierComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: SupplierComponent;

  users: Array<AuctionClusterUser> = [];
  customDataSource: CustomStore;
  dataGridInstance: DataGrid;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterSupplierService,
    private userService: AuctionClusterUserService,
    private languageService: LanguageService
  ) {
    super(injector, AuctionClusterSupplier);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });

    this.customDataSource = new CustomStore({
      key: 'supplierId',
      load: (loadOptions) => {

        const observables = [];
        observables.push(this.dataService.getSuppliersPaged(this.id, loadOptions));
          

        return new Promise((resolve, reject) => {
          forkJoin(observables).subscribe(result => {
            this.items = result[0]['data'];
            this.items.sort((a, b) => {
              if (a.supplierName < b.supplierName) {
                return -1;
              }
              if (a.supplierName > b.supplierName) {
                return 1;
              }
              return 0;
            });

            resolve({
              data: result[0]['data'],
              totalCount: result[0]['totalCount']
            });
          }, error => {
            reject(error);
          });
        });
      }
    });
  }

  ngOnInit() {
    this.title.set('SHARED.SUPPLIER_MANAGEMENT');

    this.setTranslations('SUPPLIERS');
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
  }

  edit = (e: any) => {
    const supplierId = e.row !== undefined ? e.row.data.supplierId : e.data.supplierId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, supplierId, this.id);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.id);
  }

  onDetailsComponentClosed(isDataChanged: boolean) {
    if (isDataChanged) {
      this.dataGridInstance.getDataSource().reload();
    }
  }

  enableDisable = (e: any) => {
    this.dataService.enableDisable(this.id, e.row.data).subscribe(() => {
      this.dataGridInstance.getDataSource().reload();
    },
      error => {
        this.errorService.show(error);
      });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.supplierId;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete).subscribe((suppliers) => {
      this.dataGridInstance.getDataSource().reload();
      this.spinner.hide();
    });

    this.confirmation.opened = false;
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActiveOnAuctionCluster) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActiveOnAuctionCluster) {
      return true;
    } else {
      return false;
    }
  }
}
