import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../../shared/components/full-list/full-list.component';
import { ProductFunctionkeysExpressionComponent } from './product-functionkeys-expression.component';

// models
import { ProductFunctionKeysExpression, ProductProperty } from '../../../shared/models/product';

@Component({
  selector: 'product-functionkeys-expressions-component',
  templateUrl: './product-functionkeys-expressions.component.html'
})
export class ProductFunctionkeysExpressionsComponent extends FullListComponent<ProductFunctionKeysExpression, ProductFunctionkeysExpressionComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ProductFunctionkeysExpressionComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  productProperties: Array<ProductProperty> = [];

  @Input('productFunctionkeysExpressions')
  set data(value: Array<ProductFunctionKeysExpression>) {
    this.items = value;
    this.items.forEach(item => {
      if (item.productPropertyId) {
        var productProperty = this.productProperties.filter(_ => _.productPropertyId == item.productPropertyId)[0];
        item.productPropertyName = productProperty.productPropertyNameText;
      }
    });
  }

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value;
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  constructor(
    protected injector: Injector,
  ) {
    super(injector, ProductFunctionKeysExpression);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PRODUCT.FUNCTIONKEYS_EXPRESSION');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  edit = (e: any) => {
    const id = e.row !== undefined ? e.row.data.id : e.data.id;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, id);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.id;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.items.splice(this.items.indexOf(this.items.filter(_ => _.productFunctionKeysExpressionId == this.itemIdToDelete)[0]), 1);
    this.dataChanged.emit();
  }

  onDetailsClosed(expression: ProductFunctionKeysExpression) {
    if (this.items.some(_ => _.productFunctionKeysExpressionId === expression.productFunctionKeysExpressionId)) {
      this.items[this.items.indexOf(this.items.filter(_ => _.productFunctionKeysExpressionId == expression.productFunctionKeysExpressionId)[0])] = expression;
    }
    else {
      this.items.push(expression);
    }
    this.dataChanged.emit();
  }
}