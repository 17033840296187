<div class="item-list-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div style="display: flex; flex-direction: row">
    <div style="flex: 1; margin-right: 15px;">
      <div class="grid-buttons">
        <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="cancel()"></dx-button>
      </div>
      <br />
      <dx-data-grid #groupFilterGrid
                    id="printSection"
                    [rtlEnabled]="rtlEnabled"
                    [dataSource]="items"
                    [showBorders]="true"
                    [allowColumnReordering]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    [rowAlternationEnabled]="true"
                    (onRowClick)="onRowClick($event)">
        <dxo-selection mode="single"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-pager [visible]="true"
                   [showPageSizeSelector]="true"
                   [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                   [showNavigationButtons]="true"
                   [showInfo]="true"
                   infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
        </dxo-pager>
        <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}" [customizeText]="translateField">
        </dxi-column>
        <dxi-column type="buttons" [width]="120">
          <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
          <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
        </dxi-column>
      </dx-data-grid>
    </div>
    <div style="flex: 1;">
      <div class="card">
        <div class="card-header">
          {{ cardTitle }}
        </div>
        <div class="card-block">
          <form #filterForm="ngForm" *ngIf="product && product.manualProductPropertyFilterSelections" style="margin-bottom:30px;">
            <translatable-field [caption]="'SHARED.NAME'" [(data)]="model.name" [name]="'name'" [clusterLanguages]="languages" #name></translatable-field>
            <div class="form-group group-filter-form-group" style="margin-top:25px;" *ngFor="let prop of product.manualProductPropertyFilterSelections">
              <label>{{ prop.label | translateJson }}</label>
              <masterdata-tagbox name="{{'multipleSelect' + prop.productPropertyGroupingSelectionId}}"
                                 [auctionClusterId]="product.auctionClusterId"
                                 [listId]="prop.masterDataListId"
                                 [(selectedRowIds)]="prop.groupFilterSelections">
              </masterdata-tagbox>
            </div>
          </form>
        </div>
      </div>
      <button type="button" class="btn btn-primary publish-btn" (click)="save()" [disabled]="saveDisabled">{{ 'SHARED.SAVE' | translate }}</button>
      <button type="button" class="btn btn-primary publish-btn" (click)="cancel()">{{ 'SHARED.CANCEL' | translate }}</button>
    </div>
    <br /><br />
  </div>
</div>
<button type="button" class="btn btn-outline" (click)="onClose();">{{ 'SHARED.CLOSE' | translate }}</button>
<confirmation-component #confirmation title="{{ 'GROUP_FILTER.DELETE_FILTER' | translate }}"
                        message="{{ 'GROUP_FILTER.DELETE_FILTER_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
