import { Component, Injector, ViewChild, OnInit, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';

// components
import { DxPopupComponent, DxDataGridComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';
import { PauseMessageComponent } from './pause-message.component';

// models
import { Auction } from '../../shared/models/auction';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { Catalog } from '../../shared/models/catalog';
import { Clock, AudioAuctioneer, AudioBuyer, BuyerPopupType, ClockAudioSettings, Modes, ShowTransactions, CoinCodeMode, AudioDisconnectType } from '../../shared/models/clock';
import { Product } from '../../shared/models/product';
import { User } from '../../shared/models/user';

import { AuctionClusterBuyer } from '../shared/models/auction-cluster-buyer';
import { AuctionUserType } from '../shared/models/auctionusertype';
import { WidgetType } from '../shared/models/widgettype';

// services
import { ClockService } from '../../shared/services/clock.service';
import { LanguageService } from '../../shared/services/language.service';
import { ProductService } from '../../auction/shared/services/product.service';
import { UserService } from '../../shared/services/user.service';

import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';

const ESC_KEYCODE = 27;

@Component({
  selector: 'clock-component',
  templateUrl: 'clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent extends ItemDetailsComponent<Clock> implements OnInit, OnDestroy {

  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  @ViewChild('name', { read: ElementRef }) nameInput: any;
  @ViewChild('pauseMessageDetails') pauseMessageDetails: PauseMessageComponent;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  readonly milliSeconds = 60000;
  readonly ticks = 100;

  translations: any;
  auctionId: number;
  auctionClusterId: number;
  selectedCoinCode: number;
  auctions: Array<Auction> = [];
  minValue = 1;
  buyersPaginated: any = {};
  user: User = new User();
  buyers: Array<AuctionClusterBuyer> = [];
  catalogs: Array<Catalog> = [];
  selectedCatalog: Catalog;
  filteredCatalogs: Array<Catalog> = [];
  pauseMessages: Array<{ message: string }>;
  coinCodeModes: Array<CoinCodeMode> = [];

  auctioneerAudioSettings: any = AudioAuctioneer;
  buyerAudioSettings: any = AudioBuyer;
  audioDisconnectTypes: any = AudioDisconnectType;
  buyerPopupTypes: any = BuyerPopupType;
  audioSettings: any = ClockAudioSettings;
  modes: any = Modes;
  showTransactionSettings: any = ShowTransactions;
  videoTabVisible: boolean = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  formDirty: boolean = false;

  constructor(
    protected injector: Injector,
    private dataService: ClockService,
    private auctionService: AuctionClusterAuctionService,
    private languageService: LanguageService,
    private userService: UserService,
    private productService: ProductService
  ) {
    super(injector);
    this.auctioneerAudioSettings = Object.keys(this.auctioneerAudioSettings).filter(f => !isNaN(Number(f))).map(key => ({ name: this.auctioneerAudioSettings[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.audioSettings = Object.keys(this.audioSettings).filter(f => !isNaN(Number(f))).map(key => ({ name: this.audioSettings[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.buyerAudioSettings = Object.keys(this.buyerAudioSettings).filter(f => !isNaN(Number(f))).map(key => ({ name: this.buyerAudioSettings[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.buyerPopupTypes = Object.keys(this.buyerPopupTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.buyerPopupTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.modes = Object.keys(this.modes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.modes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.showTransactionSettings = Object.keys(this.showTransactionSettings).filter(f => !isNaN(Number(f))).map(key => ({ name: this.showTransactionSettings[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.audioDisconnectTypes = Object.keys(this.audioDisconnectTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.audioDisconnectTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    console.log('INIT');
    this.model = new Clock();
    this.setTranslations('CLOCK');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(clocks: Array<Clock>, clockId: number, auctionId: number, buyers: Array<AuctionClusterBuyer>,
    catalogs: Array<Catalog>, auctionClusterId: number) {
    console.log('Open');
    this.pauseMessages = null;
    this.allItems = clocks;
    this.auctionId = auctionId;
    this.auctionClusterId = auctionClusterId;
    this.buyers = buyers.filter(b => b.isActive);
    this.catalogs = catalogs;
    this.filteredCatalogs = catalogs.filter(c => c.auctionId === auctionId);

    this.buyersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.buyers,
      sort: 'name'
    };

    forkJoin(
      this.auctionService.getAuctionsForPermissions(this.auctionClusterId, [AuctionClusterPermissionEnum.ClockManagement]),
      this.userService.getCurrentUser()
    ).subscribe(result => {
      this.auctions = result[0];
      this.user = result[1];
    }, error => {
      this.errorService.show(error);
    });

    if (clockId !== null) {
      this.isEditMode = true;
      this.spinner.show();
      this.getCoinCodes(this.auctionId)
      this.dataService.getClock(this.auctionId, clockId)
        .subscribe((res: Clock) => {

          let productId = this.catalogs.filter(c => c.catalogId === res.catalogId)[0].productId;
          this.productService.getProduct(this.auctionClusterId, productId)
            .subscribe((product: Product) => {
              this.videoTabVisible = product.widgets.filter((w) => w.widgetTypeId == WidgetType.ClockVideo && w.auctionUserTypeId == AuctionUserType.buyer && w.isAvailable).length > 0;
            }, error => {
              this.errorService.show(error);
            });

          this.model = res;
          this.selectedCatalog = this.catalogs.find(_ => _.catalogId === this.model.catalogId);

          this.setPauseMessages(this.model);
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            // this.errorService.show(error);
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new Clock();
      this.setPauseMessages(this.model);
      this.isEditMode = false;
      this.isOpened = true;
      this.model.audio.enableAudio = false;
      this.model.settings.autoValidateBuyerChoice = false;
      this.model.settings.autoRestartPartialLot = false;
      this.selectedCatalog = null;
    }
  }

  setPauseMessages(clock: Clock) {
    if (this.pauseMessages == null) {
      let pauseMessages = [];
      if (clock != null && clock.settings != null && clock.settings.pauseMessages != null) {
        clock.settings.pauseMessages.forEach((pauseMessage: string) => {
          pauseMessages.push({ message: pauseMessage });
        });
        this.pauseMessages = pauseMessages;
      }
      console.log('Set');
    }
  }

  getPauseMessages() {
    this.model.settings.pauseMessages = [];
    this.pauseMessages = this.dataGrid.instance.getVisibleRows().map(_ => ({ message: _.data['message'] }))
    this.pauseMessages.forEach((pauseMessage: { message: string }) => {
      this.model.settings.pauseMessages.push(pauseMessage.message);
    });
  }

  save() {
    this.model.auctionClusterId = this.auctionClusterId;
    this.getPauseMessages();
    this.setMinSteps();

    if (!this.model.audio.enableAudio) {
      this.model.audio.audioAuctioneer = null;
      this.model.audio.audioBuyer = null;
    }

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.dataService.save(this.model.auctionId, this.model)
        .subscribe((res: any) => {
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  translateSelectBoxes = (item) => {
    if (item) {
      let label = 'CLOCK.' + item.name;
      this.translate.get(label).subscribe((res: string) => {
        label = res;
      });
      return label;
    }
  }

  filterCatalogs() {
    this.filteredCatalogs = [];
    this.filteredCatalogs = this.catalogs.filter(c => c.auctionId === this.model.auctionId);
    this.model.catalogId = null;
  }

  getCoinCodes(auctionId: number) {
    this.dataService.getCoinCodes(auctionId).subscribe((result: any) => {
      this.coinCodeModes = result;
    },
      error => {
        this.errorService.show(error);
      });
  }

  calculateClockStep() {
    this.model.mode.clockStep = Math.round(this.milliSeconds / (this.ticks * this.model.mode.clockSpeed));
  }

  calculateClockStepUp() {
    this.model.mode.clockStepUp = Math.round(this.milliSeconds / (this.ticks * this.model.mode.clockSpeedUp));
  }

  calculateClockSpeed() {
    if (this.model.mode.clockStep < 1) {
      this.model.mode.clockStep = 1;
    }
    this.model.mode.clockStep = Math.round(this.model.mode.clockStep);
    this.model.mode.clockSpeed = this.milliSeconds / (this.ticks * this.model.mode.clockStep);
  }

  calculateClockSpeedUp() {
    if (this.model.mode.clockStepUp < 1) {
      this.model.mode.clockStepUp = 1;
    }
    this.model.mode.clockStepUp = Math.round(this.model.mode.clockStepUp);
    this.model.mode.clockSpeedUp = this.milliSeconds / (this.ticks * this.model.mode.clockStepUp);
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  setMinSteps() {
    if (this.model.mode.mode === 1 || this.model.mode.mode === 4) {
      if (this.model.mode.clockStep <= 0) {
        this.model.mode.clockStep = 1;
      }

      if (this.model.mode.clockSpeed <= 0) {
        this.model.mode.clockSpeed = 1;
      }

    } else if (this.model.mode.mode === 3 || this.model.mode.mode === 4) {
      if (this.model.mode.clockStepUp <= 0) {
        this.model.mode.clockStepUp = 1;
      }

      if (this.model.mode.clockSpeedUp <= 0) {
        this.model.mode.clockSpeedUp = 1;
      }
    }
  }

  onFieldDataChanged(e: any) {
    let updatedField = e.dataField;
    // Clock steps and speeds calculations on data change
    if (updatedField === 'mode.clockStep') {
      this.calculateClockSpeed();
    } else if (updatedField === 'mode.clockSpeed') {
      this.calculateClockStep();
    } else if (updatedField === 'mode.clockStepUp') {
      this.calculateClockSpeedUp();
    } else if (updatedField === 'mode.clockSpeedUp') {
      this.calculateClockStepUp();
    } else if (updatedField === 'catalogId') {
      this.selectedCatalog = this.catalogs.find(_ => _.catalogId === this.model.catalogId);
    }

    if (updatedField === 'auctionId') {
      this.filterCatalogs();
      this.getCoinCodes(this.model.auctionId);
    }

    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnClockSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnClockSubmit')[0].removeAttribute('disabled');
      }

      if (!this.formDirty) this.formDirty = true;
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnClockSubmit')[0].removeAttribute('disabled');
      }
    }
  }

  restartStepValidation(params: any) {
    return params.value % 2 === 0;
  }
}
