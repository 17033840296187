import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

// models
import { Cookies } from '../../shared/constants/cookies';

// services
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../shared/services/auth.service';
import { DynamicRoutingService } from '../../shared/services/dynamic-routing.service';
import { ErrorService } from '../../shared/services/error.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TermsAndConditionsService } from '../../shared/services/terms-and-conditions.service';

@Component({
  selector: 'accept-terms-and-conditions-component',
  templateUrl: './accept-terms-and-conditions.component.html',
  styleUrls: ['./accept-terms-and-conditions.component.scss']
})
export class AccepTermsAndConditionsComponent {
  auctionClusterId: number;
  isAccepted = false;
  isOpened = false;
  errorMessage = '';

  @Output() onClosed = new EventEmitter<any>();

  constructor(
    private router: Router,
    private dataService: TermsAndConditionsService,
    private cookies: CookieService,
    //private tokenService: TokenService,
    private authService: AuthService,
    private dynamicRouting: DynamicRoutingService,
    protected route: ActivatedRoute,
    private spinner: SpinnerService,
    private errorService: ErrorService,
    private fb: FormBuilder
  ) {
    this.dynamicRouting.doLogout.subscribe(ev => this.authService.logout());
  }

  open(auctionClusterId: number) {
    this.auctionClusterId = auctionClusterId;
    this.isOpened = true;
    this.spinner.show();
    this.dataService.getWithContent(this.auctionClusterId).subscribe(terms => {
      // Embed the PDF into the HTML page and show it to the user
      const obj = document.createElement('object');
      obj.style.width = '100%';
      obj.style.height = '600px';
      obj.type = 'application/pdf';
      obj.data = 'data:application/pdf;base64,' + terms.content;
      document.querySelector('#pdfViewerHolder').appendChild(obj);
      this.spinner.hide();
    }, error => {
      this.errorMessage = this.errorService.getErrorMessage(error);
      this.spinner.hide();
    });
  }

  private close(isAccepted: boolean) {
    this.isOpened = false;
    this.errorMessage = '';
    this.onClosed.emit(isAccepted);
    document.querySelector('#pdfViewerHolder').innerHTML = '';
  }

  cancel() {
    let rejectedTOS = this.cookies.get(Cookies.REJECTED_TOS).split(',').map(id => +id);

    if (rejectedTOS.indexOf(this.auctionClusterId) === -1) {
      rejectedTOS.push(this.auctionClusterId);
      this.cookies.set(Cookies.REJECTED_TOS, rejectedTOS.join(','));
    }

    this.close(false);

    if (this.dynamicRouting.updateDefaultRoutes()) {
      this.router.navigate(['/'], { queryParamsHandling: 'merge' });
    } else {
      this.authService.logout();
    }
  }

  accept() {
    this.dataService.acceptTerms(this.auctionClusterId).subscribe((res: any) => {
      this.authService.processAuthenticationInfo(res);
      if (this.dynamicRouting.updateDefaultRoutes()) {
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      }
    },
      error => this.errorService.show(error));

    this.close(true);
  }
}
