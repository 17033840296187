<div class="catalogs-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">

    <div class="row" id="auctionCatalogs">
      <div class="filter-column">
        <label for="catalogSelect">{{ 'CATALOGS.SELECT_AUCTION' | translate }}</label>
        <dx-select-box name="catalogSelect"
                       [items]="auctions"
                       displayExpr="name"
                       valueExpr="auctionId"
                       [(value)]="selectedAuction"
                       [rtlEnabled]="rtlEnabled"
                       [searchEnabled]="true"
                       [showClearButton]="true"
                       placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                       (onValueChanged)="getSelectedAuctions()"></dx-select-box>
      </div>
    </div>
    <div class="grid-buttons">
      <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
      <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
    </div>
    <br />
    <dx-data-grid #clocksGrid
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [dataSource]="items"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onRowClick)="onRowClick($event)"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true" [showInfo]="true" infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="auctionName" caption="{{ 'SHARED.AUCTION' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="description" caption="{{ 'SHARED.DESCRIPTION' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="productName" caption="{{ 'SHARED.PRODUCT' | translate }}">
      </dxi-column>
      <dxi-column type="buttons" [width]="180">
        <dxi-button [visible]="checkCatalogPermission" hint="{{ 'CATALOGS.CLEANUP' | translate }}" icon="clearformat" [onClick]="refreshCatalog"></dxi-button>
        <dxi-button hint="{{ 'CATALOGS.LOTS' | translate }}" icon="detailslayout" [onClick]="clickOpenLots"></dxi-button>
        <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
        <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>

  <forecast-catalog-component #details (onClosed)="onDetailsComponentClosed($event)"></forecast-catalog-component>
  <confirmation-component #confirmation title="{{ 'CATALOGS.DELETE_CATALOG' | translate }}"
                          message="{{ 'CATALOGS.DELETE_CATALOG_MESSAGE' | translate }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
  <confirmation-component #confirmationCleanupDialog title="{{ 'CATALOGS.CLEANUP' | translate }}"
                          message="{{ 'CATALOGS.CLEANUP_CONFIRMATION_MESSAGE' | translate }} {{getDate()}}" [errorMessage]=confirmationErrorMessage
                          buttonCloseText="YES" cancellable="true" (close)="cleanupConfirmed();"></confirmation-component>
  <confirmation-component #confirmationEmptyBuffer title="{{ 'CATALOGS.CLEANUP' | translate }}"
                          message="{{ 'CATALOGS.CLEANUP_EMPTY_BUFFER' | translate }}"
                          buttonCancelText="NO" buttonCloseText="YES" cancellable="true" (cancel)="emptyBufferCanceled();" (close)="emptyBufferConfirmed();"></confirmation-component>
  <confirmation-component #cleanupDoneMessage title="{{ 'CATALOGS.CLEANUP' | translate }}"
                          message="{{ 'CATALOGS.CLEANUP_DONE' | translate }}" buttonCloseText="OK"></confirmation-component>
  <cleanup-confirmation-component #confirmationCleanup title="{{ 'CATALOGS.CLEANUP' | translate }}"
                          message="{{ 'CATALOGS.CLEANUP_MESSAGE' | translate }}" [errorMessage]=confirmationErrorMessage
                          buttonCloseText="CLEANUP" cancellable="true" [date]="catalogCleanupDate" (dateChanged)="catalogCleanupDateChanged($event)" (close)="cleanupCatalog();"></cleanup-confirmation-component>
</div>
