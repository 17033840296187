<div class="item-list-component">

    <div class="grid-buttons">
      <clr-tooltip>
        <clr-icon shape="add" clrTooltipTrigger size="24" (click)="add()"></clr-icon>
        <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
          <span>{{ 'SHARED.ADD' | translate }}</span>
        </clr-tooltip-content>
      </clr-tooltip>
    </div>
  
    <clr-datagrid #dataGrid>
      <clr-dg-column *ngFor="let prop of prebidPropertyFields">
        {{ prop.name }}
      </clr-dg-column>
      <clr-dg-column class="two-buttons-column">&nbsp;</clr-dg-column>
  
      <clr-dg-placeholder>{{'SHARED.NO_DATA' | translate }}</clr-dg-placeholder>
  
      <clr-dg-row *ngFor="let lot of items" (click)="edit(lot.lotId, $event)">
        <clr-dg-cell *ngFor="let prop of prebidPropertyFields; let i = index;">
            {{ lot.displayLotProperties[i].displayValue }}
        </clr-dg-cell>
        <clr-dg-cell class="no-print two-buttons-cell">
  
          <clr-tooltip role="tooltip" aria-haspopup="true">
            <clr-icon clrTooltipTrigger shape="edit" class="edit-btn" size="16" (click)="edit(lot.lotId, $event)"></clr-icon>
            <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
              <span>{{ 'SHARED.EDIT' | translate }}</span>
            </clr-tooltip-content>
          </clr-tooltip>
  
          <clr-tooltip role="tooltip" aria-haspopup="true">
            <clr-icon clrTooltipTrigger shape="trash" class="delete-btn" size="16" (click)="delete(lot.lotId, $event)"></clr-icon>
            <clr-tooltip-content class="tooltip-content" clrPosition="top-left" clrSize="sm">
              <span>{{ 'SHARED.DELETE' | translate }}</span>
            </clr-tooltip-content>
          </clr-tooltip>
        </clr-dg-cell>
      </clr-dg-row>
  
      <clr-dg-footer>
        <pagination-component class="footer-pagination" tootltip-content="{{ 'SHARED.SET_PAGE_SIZE' | translate }}" [pageSizeValue]="pageSizeValue" (changed)="setPageSize($event)"></pagination-component>
        <span class="pagination-fixed footer-pagination">
          <span *ngIf="items?.length > 0">{{ 'PREBID_ON_PRODUCT.PAGINATION_TEXT' | translate:{firstItem: pagination.firstItem + 1, lastItem: pagination.lastItem + 1, totalItems: pagination.totalItems} }}</span>
          <span *ngIf="items?.length == 0">{{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate:{totalItems: pagination.totalItems} }}</span>
          <clr-dg-pagination #pagination [clrDgPageSize]="pageSize"></clr-dg-pagination>
        </span>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  
  <confirmation-component #confirmation title="{{ 'PREBID_ON_PRODUCT.DELETE_PREBID_ON_PRODUCT_LOT' | translate }}" message="{{ 'PREBID_ON_PRODUCT.DELETE_PREBID_ON_PRODUCT_LOT_MESSAGE' | translate }}" buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
  <buyer-prebid-lot-component #details (onClosed)="onDetailsComponentClosed($event)"></buyer-prebid-lot-component>
