<div class="supplier-user-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup
  #supplierUser
  [width]="600"
  [showTitle]="true"
  title="{{ modalTitle }}"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [rtlEnabled]="rtlEnabled"
  [(visible)]="isOpened"
  (onShown)="onFieldDataChanged($event)">
  <dx-scroll-view width="100%" height="100%">
        <dx-form
            #supplierUserForm
            id="userForm"
            [showValidationSummary]="false"
            (onFieldDataChanged)="onFieldDataChanged($event)"
            colCount="auto"
            [(formData)]="model"
            [minColWidth]="500">
            <dxi-item 
            itemType="tabbed">
                <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">
                  
                  <dxi-item itemType="group">
                    <dxi-item dataField="email" [disabled]="true">
                      <dxo-label text="{{ 'SHARED.EMAIL' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
                      <dxi-validation-rule type="email" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="firstname" [disabled]="true">
                      <dxo-label text="{{ 'SHARED.FIRST_NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SHARED.FIRST_NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="lastname" [disabled]="true">
                      <dxo-label text="{{ 'SHARED.LAST_NAME' | translate }}"></dxo-label>
                      <dxi-validation-rule type="required" message="{{ 'SHARED.LAST_NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="telNumber" [editorOptions]="{disabled: !emailVerified}">
                      <dxo-label text="{{ 'SHARED.TEL_NUMBER' | translate }}"></dxo-label>
                    </dxi-item>
                    <dxi-item [disabled]="true"
                              dataField="languageId"
                              editorType="dxSelectBox"
                              [editorOptions]="{ items: languages, valueExpr:'languageId' ,
                        displayExpr:'name'}">
                      <dxo-label text="{{ 'USER.PREFERRED_LANGUAGE' | translate }}"></dxo-label>
                    </dxi-item>
                  </dxi-item>
                </dxi-tab>
                <dxi-tab title="{{ 'SHARED.SUPPLIER' | translate }}">
                  <div *dxTemplate>
                  <dx-data-grid
                    [rtlEnabled]="rtlEnabled"
                    [(dataSource)]="model.supplierRoles"
                    [showBorders]="true"
                    [allowColumnReordering]="true" [hoverStateEnabled]="true" [columnAutoWidth]="true"
                    (onEditorPreparing)="onEditorPreparing($event)">
                    <dxo-selection mode="single"></dxo-selection>
                    <dxo-editing
                      mode="cell"
                      [allowUpdating]="true"
                      [useIcons]="true">
                    </dxo-editing>
                    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]" [showNavigationButtons]="true"></dxo-pager>
                      <dxi-column
                          [editorOptions]="{ disabled: true}"
                          dataField="supplierId"
                          caption="{{ 'SUPPLIER.NAME' | translate }}">
                          <dxo-lookup
                            [dataSource]="suppliers"
                            displayExpr="name"
                            valueExpr="supplierId">
                        </dxo-lookup>
                      </dxi-column>
                      <dxi-column
                          dataField="supplierRoleId"
                          caption="{{ 'ROLE' | translate }}">
                          <dxo-lookup
                            [dataSource]="supplierRoles"
                            [displayExpr]="translateSelectBoxes"
                            valueExpr="supplierRoleId">
                        </dxo-lookup>
                      </dxi-column>
                    </dx-data-grid>
                    </div>
                </dxi-tab>
            </dxi-item>
            
        </dx-form>
        <div class="au-dx-popup-padder">
          <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnSupplierUserSubmit" disabled="disabled">
            {{ 'SHARED.SAVE' | translate }}
          </button>
          <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
          </div>
</dx-scroll-view>
</dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'SHARED.LEAVE_PAGE' | translate }}" buttonCloseText="OK" cancellable="true" (close)="onCancel();"></save-confirmation-component>
