<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <label [innerHtml]="'PRODUCT.FUNCTIONKEYS_EXPRESSION.DOCUMENTATION' | translate"></label>
  <dx-data-grid #productFunctionkeysExpressionsGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items"
                [showBorders]="true"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="edit($event)"
                (onExporting)="onExporting($event)">
    <!--<<dxo-export [enabled]="true"></dxo-export>>-->
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <!--<dxo-search-panel [visible]="true"></dxo-search-panel>-->
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="productPropertyName" caption="{{ 'SHARED.PRODUCT_PROPERTY' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="expression" caption="{{ 'PRODUCT.FUNCTIONKEYS_EXPRESSION.EXPRESSION' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="usableDuringClockRun" caption="{{ 'PRODUCT.FUNCTIONKEYS_EXPRESSION.USABLE_DURING_CLOCK_RUN' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" [width]="70">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>
  
  <product-functionkeys-expression-component #details [productProperties]="productProperties" (onDetailsClosed)="onDetailsClosed($event)"></product-functionkeys-expression-component>
  <confirmation-component #confirmation title="{{ 'PRODUCT.FUNCTIONKEYS_EXPRESSION.DELETE' | translate }}"
                          message="{{ 'PRODUCT.FUNCTIONKEYS_EXPRESSION.DELETE_MESSAGE' | translate }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
