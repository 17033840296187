import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../../shared/components/item-details/item-details.component';

// models
import { ProductFunctionKeysAction, ProductFunctionKeysActionEnum } from '../../../shared/models/product';

@Component({
  selector: 'product-functionkeys-action-component',
  templateUrl: './product-functionkeys-action.component.html',
  styleUrls: ['./product-functionkeys-action.component.scss']
})
export class ProductFunctionkeysActionComponent extends ItemDetailsComponent<ProductFunctionKeysAction> implements OnInit, OnDestroy {

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  auctionClusterId: number;
  productId: number;
  productFunctionKeysActionEnum: any = ProductFunctionKeysActionEnum;

  @Output() onDetailsClosed = new EventEmitter<ProductFunctionKeysAction>();

  constructor(
    protected injector: Injector,
    private route: ActivatedRoute,
  ) {
    super(injector);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.productId = +this.route.snapshot.params['productId'];
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.productFunctionKeysActionEnum = Object.keys(this.productFunctionKeysActionEnum).filter(f => !isNaN(Number(f))).map(key => ({ name: this.productFunctionKeysActionEnum[key], value: Number(key) })); // tslint:disable-line:max-line-length
  }

  ngOnInit() {
    this.model = new ProductFunctionKeysAction();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(items: Array<ProductFunctionKeysAction>, expressionId: number) {
    if (expressionId != null) {
      this.model = items.filter(_ => _.productFunctionKeysActionId == expressionId)[0];
      this.isEditMode = true;
    } else {
      this.model = new ProductFunctionKeysAction();
      this.isEditMode = false;
    }
    this.isOpened = true;
  }

  save() {
    this.model.productId = this.productId;

    this.onDetailsClosed.emit(this.model);
    this.close(true);
    this.errorMessage = null;
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnProductFunctionkeysActionSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnProductFunctionkeysActionSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnProductFunctionkeysActionSubmit')[0].removeAttribute('disabled');
      }
    }
  }  

  translateSelectBoxes = (item) => {
    if (item) {
      let label = item.name;
      this.translate.get('PRODUCT.FUNCTIONKEYS_ACTION.' + label).subscribe((res: string) => {
        label = res;
      });
      return label;
    }
  }
}