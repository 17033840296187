import { Component, Output, OnInit, Injector, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// components
import { DxSelectBoxComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { MasterData, MasterDataListField } from '../../shared/models/master-data';
import { ProductProperty } from '../../shared/models/product';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';

import { CalculationsField } from '../shared/models/calculation';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { MasterDataService } from '../shared/services/master-data.service';
import { ProductService } from '../shared/services/product.service';

@Component({
  selector: 'calculations-field-component',
  templateUrl: 'calculations-field.component.html',
})
export class CalculationsFieldComponent extends ItemDetailsComponent<CalculationsField> implements OnInit, OnDestroy {

  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  isMasterData = false;
  masterDataListFields: Array<MasterDataListField> = [];
  auctionClusterId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<CalculationsField>();
  @Output() onUpdated = new EventEmitter<CalculationsField>();
  // tslint:enable:no-output-on-prefix

  @ViewChild('masterDataCombo') selectbox: DxSelectBoxComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private productService: ProductService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new CalculationsField();    
    this.setTranslations("CALCULATIONS");
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(productId: number, property: CalculationsField) { // tslint:disable-line:max-line-length
    this.isMasterData = false;
    if (property){
      this.modalTitle = this.translations.EDIT;
    }
    else{
      this.modalTitle = this.translations.ADD_NEW;
    }

    this.productService.getProductProperties(productId, this.translateService.currentLang).subscribe(result => {
      this.productProperties = result;
  
      if (property !== null) {
        this.isEditMode = true;
        this.model = property;
        this.filterMasterDataFields();
      } else {
        this.model = new CalculationsField();
        this.isEditMode = false;
      }
      this.isOpened = true;
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });    
  }

  save() {
    this.model.productProperty = this.productProperties.find(_ => _.productPropertyId === this.model.productPropertyId);
    this.model.masterDataListField = this.masterDataListFields.find(_ => _.masterDataListFieldId === this.model.masterDataListFieldId);

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new CalculationsField();
    this.close(true);
  }

  onMasterDataChanged = (e: any) => {
    this.filterMasterDataFields();
    this.model.masterDataListFieldId = null;
  }

  filterMasterDataFields() {
    var productProperty = this.productProperties.find(_ => _.productPropertyId === this.model.productPropertyId);
    if (productProperty.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA){
      this.masterDataService.getMasterDataListForEdit(productProperty.masterDataListId, this.auctionClusterId, this.translateService.currentLang).subscribe(mdl => {
        this.masterDataListFields = mdl.fields;
        this.isMasterData = true;
      });
    } else {
      this.masterDataListFields = [];
      this.isMasterData = false;
    }      
  }

  onFieldDataChanged(e: any) {
    if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnCalculationFieldSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnCalculationFieldSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnCalculationFieldSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnCalculationFieldSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }

    if (e.dataField === 'masterDataListFieldId') {

      const masterInterval = setInterval(() => {
        if (this.isMasterData) {
          clearInterval(masterInterval);
          this.model.masterDataListFieldId = this.model.masterDataListField?.masterDataListFieldId;
        }
      }, 50);
    }
  }

  translateFields = (item: any) => {
    if (item) {
      return item.name;
    }
  }
}
