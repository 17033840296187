import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Directive } from '@angular/core';
import { Output, HostListener, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// models
import { ApplicationSettings } from '../../shared/models/application-settings';
import { RegisterInfo } from '../../shared/models/register-info';

// services
import { AuthService } from '../../shared/services/auth.service';
import { ErrorService } from '../../shared/services/error.service';
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'register-component',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {

  message: string;
  model: RegisterInfo;
  errorMessage: string;
  public showPassword: boolean;
  public showRepeatPassword: boolean;
  capsOn;

  @ViewChild('email') email: any;

  constructor(
    private router: Router,
    private appSettings: ApplicationSettings,
    private authenticationService: AuthService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private titleService: TitleService,
  ) { }

  ngOnInit() {
    this.titleService.set('REGISTER.TITLE');
    this.model = new RegisterInfo(this.appSettings.applicationCode);
  }


  ngAfterViewInit() {
    this.email.nativeElement.focus();
  }

  async onSubmit() {
    this.errorMessage = null;
    var regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!regExp.test(this.model.email)) {
      this.errorMessage = await this.translate.get('REGISTER.NOT_VALID_EMAIL').toPromise();
      return;
    }
    if (this.model.password !== this.model.confirmPassword) {
      this.errorMessage = await this.translate.get('REGISTER.REPEAT_PASSWORD_VALIDATION').toPromise();
      return;
    }
    var regExp = /(.*[A-Z].*)/;
    if (!regExp.test(this.model.password)) {
      this.errorMessage = await this.translate.get('REGISTER.UPPERCASE_PASSWORD').toPromise();
    }
    var regExp = /(.*[a-z].*)/;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = await this.translate.get('REGISTER.LOWERCASE_PASSWORD').toPromise();
      else
        this.errorMessage += await this.translate.get('REGISTER.LOWERCASE_PASSWORD_REPEAT').toPromise();  
    }
    var regExp = /(.*\d.*)/;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = await this.translate.get('REGISTER.DIGIT_PASSWORD').toPromise();
      else
        this.errorMessage += await this.translate.get('REGISTER.DIGIT_PASSWORD_REPEAT').toPromise();
    }
    var regExp = /(.{8,}$)/;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = this.errorService.translations.SHORT_PASSWORD;
      else
        this.errorMessage += this.errorService.translations.SHORT_PASSWORD_REPEAT
    }
    var regExp = /^(?!.*(.)\1{2})/s;
    if (!regExp.test(this.model.password)) {
      if(!this.errorMessage)
        this.errorMessage = await this.translate.get('REGISTER.THREE_SYMBOLS_PASSWORD').toPromise();
      else
        this.errorMessage += this.errorService.translations.THREE_SYMBOLS_PASSWORD_REPEAT;
    }
    if (this.errorMessage == null) {
      this.register();
    }
    if(this.errorMessage != null) {
      return;
    }
  }

  register() {
    this.authenticationService.register(this.model)
      .subscribe(result => {
        if (result) {
          // register successful
          this.router.navigate(['/core/register-profile/'], { queryParamsHandling: 'merge' });
        } else {
          // register failed
          this.translate.get('USER.EMAIL_NOT_UNIQUE').subscribe((res: string) => {
            this.message = res;
          });
        }
      }, error => {
        if(error.error[0].ErrorMessage) {
          this.translate.get(error.error[0].ErrorMessage).subscribe((res: string) => {
            this.message = res;
          });
        } 
        else if (error.error) {
          this.translate.get("ERRORS." + error.error).subscribe((res: string) => {
            this.message = res;
          });
        }
      });
  }
}

@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }
}
