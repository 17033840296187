import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// interfaces
import { ILookupTableService } from '../../../shared/interfaces/lookup-table';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Lot } from '../../../shared/models/lot';

import { LookupTable, LookupTableField, LookupTableRow } from '../models/lookup-table';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class LookupTableService implements ILookupTableService{

  lookupTables: Array<LookupTable> = [];
  lookupTableFields: Array<LookupTableField> = [];

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getLookupTables(auctionClusterId: number, langCode: string): Observable<Array<LookupTable>> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable?langCode=${langCode}`);
  }

  getLookupTablesPage(auctionClusterId: number, lookupTableId: number, from: number, size: number): Observable<LookupTable> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable/${lookupTableId}/${from}/${size}`);
  }

  loadLookupTablesPageSearchSort(
    auctionClusterId: number,
    lookupTableId: number,
    language: string,
    loadOptions: any
    ): Observable<LookupTable> {
    const url = this.apiPath + '/' + auctionClusterId + '/lookuptable/' + lookupTableId + '/load';

    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }

    let params: HttpParams = new HttpParams();
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "requireGroupCount",
                    "sort",
                    "filter",
                    "totalSummary",
                    "group",
                    "groupSummary"
                ].forEach(function(i) {
                    if (i in loadOptions && loadOptions[i] != undefined)
                        params = params.set(i, JSON.stringify(loadOptions[i]));
                });
      let result = this.webApiService.getWithParams(url  + queryString, params);
      return result;
  }

  getLookupTablesPageSearchSort(
    auctionClusterId: number,
    lookupTableId: number,
    from: number,
    size: number,
    language: string,
    sort?: any,
    filter?: Array<any>): Observable<LookupTable> {
    const url = this.apiPath + '/' + auctionClusterId + '/lookuptable/' + lookupTableId + '/' + from + '/' + size + '/search';

    let queryString = `?timezoneoffset=${new Date().getTimezoneOffset()}`;
    if (language) {
      queryString += `&language=${language}`;
    }
    if (sort) {
      if (sort.by) {
        queryString += `&sortPropertySelectionId=${sort.by}&sortAscending=${!!sort.reverse}`;
      }
    }
    const selections = [];
    if (filter) {
      filter.forEach(f => {
        selections.push({
          'key': f.property,
          'value': f.value
        });
      });
    }

    return this.webApiService.save(url + queryString, { selections });
  }

  getLookupTable(auctionClusterId: number, lookupTableId: number): Observable<LookupTable> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable/${lookupTableId}`);
  }

  getLookupTableWithoutRows(auctionClusterId: number, lookupTableId: number): Observable<LookupTable> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable/${lookupTableId}/edit`);
  }

  saveRow(auctionClusterId: number, row: LookupTableRow) {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/lookuptable/row`, row);
  }

  getRow(auctionClusterId: number, rowId: number) {
    return this.webApiService.get(`${this.apiPath}/${auctionClusterId}/lookuptable/row`, rowId);
  }

  editRow(auctionClusterId: number, row: LookupTableRow) {
    return this.webApiService.editSingle(`${this.apiPath}/${auctionClusterId}/lookuptable/row`, row);
  }

  deleteRow(auctionClusterId: number, rowId: number) {
    return this.webApiService.delete(`${this.apiPath}/${auctionClusterId}/lookuptable/row`, rowId);
  }

  getLookupTableForProductProperty(auctionClusterId: number, productPropertyId: number, lot: Lot): Observable<LookupTable> {
    return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/lookuptable/productproperty/${productPropertyId}`, lot);
  }

  getLookupTablesForMasterDataProperty(auctionClusterId: number, productId: number, masterDataListId: number) {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable/product/${productId}/masterdatalist/${masterDataListId}`);
  }

  getLookupTablesForResultProperty(auctionClusterId: number, productId: number, propertyTypeId: number) {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable/product/${productId}/resulttype/${propertyTypeId}`);
  }

  save(lookuptable: LookupTable): Observable<any> {
    return this.webApiService.save(`${this.apiPath}/${lookuptable.auctionClusterId}/lookuptable`, lookuptable);
  }

  edit(lookuptable: LookupTable): Observable<any> {
    return this.webApiService.edit(`${this.apiPath}/${lookuptable.auctionClusterId}/lookuptable`, lookuptable.lookupTableId, lookuptable);
  }

  delete(lookupTableId: number, auctionClusterId: number): Observable<any> {
    return this.webApiService.delete(`${this.apiPath}/${auctionClusterId}/lookuptable`, lookupTableId);
  }

  //ILookupTableService
  getLookupTablesForReports(auctionClusterId: number, productId: number): Observable<Array<LookupTable>> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/lookuptable`);
  }

  getLookupTableForProductPropertyForReports(auctionClusterId: number, productPropertyId: number, lot: Lot): Observable<LookupTable> {
      return this.webApiService.save(`${this.apiPath}/${auctionClusterId}/lookuptable/productproperty/${productPropertyId}`, lot);
  }

  addLookUpImage(auctionClusterId: number, formData: FormData): Observable<string> {
    return this.webApiService.uploadImage(`${this.apiPath}/${auctionClusterId}/lookuptable/uploadimage`, formData);
  }  
}
