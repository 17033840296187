import { DOCUMENT, Location as ngLocation } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Injector, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as _moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';

// components
import { DxDateBoxComponent, DxPopupComponent } from 'devextreme-angular';
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';
import { LotComponent } from './lot.component';

// models
import { Cookies } from '../../shared/constants/cookies';
import { DateTimeTypeEnum } from '../../shared/enum/dateTimeTypeEnum';
import { DecimalTypeEnum } from '../../shared/enum/decimalTypeEnum';
import { Catalog } from '../../shared/models/catalog';
import { Language } from '../../shared/models/language';
import { Lot, LotProperty } from '../../shared/models/lot';
import { MasterData, MasterDataListField, MasterDataValue } from '../../shared/models/master-data';
import { AllowMultilpleValuesWithingFieldDependency, FocusTypeEnum, LotEditorProperty, MasterDataFieldTypeEnum, Product, ProductPropertyGroup, SystemProductPropertyTypesEnum } from '../../shared/models/product';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';

import { AuctionClusterSupplier } from '../shared/models/auction-cluster-supplier';
import { Calculation, CalculationsField } from '../shared/models/calculation';
import { Location } from '../shared/models/location';
import { LookupTable, LookupTableField } from '../shared/models/lookup-table';
import { WeighingScaleConnection } from '../shared/models/weighing-scale-connection';

// services
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../../shared/services/datetime.service';
import { LanguageService } from '../../shared/services/language.service';
import { MailService } from '../../shared/services/mail.service';
import { TitleService } from '../../shared/services/title.service';

import { CalculationsService } from '../shared/services/calculations.service';
import { CatalogService } from '../shared/services/catalog.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { LotService } from '../shared/services/lot.service';
import { MaskService } from '../shared/services/mask.service';
import { MasterDataService } from '../shared/services/master-data.service';
import { ProductService } from '../shared/services/product.service';

// signalr
import { HubConnectionBuilder, LogLevel, JsonHubProtocol, IReconnectPolicy, HttpTransportType } from '@aspnet/signalr';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

const ENTER_KEY = 13;
const TIMEOUT = 500;

export class MasterDataDropdown {
  value: any;
  label: string;
  img?: string;
}

@Component({
  selector: 'supply-lots-touch-component',
  templateUrl: 'supply-lots-touch.component.html',
  styleUrls: ['./supply-lots-touch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupplyLotsTouchComponent extends FullListComponent<Lot, LotComponent> implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('lotForm') lotForm: NgForm;
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];
  @ViewChild('camera') camera: ElementRef<HTMLVideoElement>;
  @ViewChild('imageTaken') imageTaken: ElementRef<HTMLCanvasElement>;
  @ViewChild('popUp') popUp: DxPopupComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  catalogs: Array<Catalog> = [];
  product: Product;
  locations: Array<Location> = [];
  suppliers: Array<AuctionClusterSupplier> = [];
  catalogId: number;
  catalog: Catalog;
  lot: Lot = new Lot();
  newLot: Lot = new Lot();
  lotProperties: Array<LotProperty> = [];
  latestNewLot = new Lot();
  lotProperty: LotProperty;
  languages: Array<Language> = []
  clusterLanguages: Array<Language> = [];
  defaultClusterLanguage: Language;
  translatableValid = false;
  fields: HTMLCollectionOf<any>;
  fieldIndex: number;
  lastSupplier: number;
  lastLocation: number;
  numberErrorMessage = '';
  textErrorMessage = '';
  decimalErrorMessage = '';
  dateErrorMessage = '';
  masterDataErrorMessage = '';
  validationTranslation: any;
  lookupTables: Array<LookupTable> = [];
  masterDatas: Array<MasterData> = [];
  masterDataRowValues: Array<MasterDataDropdown> = [];
  lotAddingInProgress = false;
  masterDataDropdowns: Array<any> = [];
  masterDataDropdownsFiltered: Array<any> = [];
  dropdownsData: Array<any> = [];
  masterDataErrors: Array<string> = [];
  deviceMasterDataListRowId: number;
  emptyPictureSource: string = '../../../assets/images/image_placeholder.jpg';
  cameraPictureSource: string = '../../../assets/images/camera.png';

  weighingScaleConnections: Array<WeighingScaleConnection> = [];
  reconnectionTimeoutHandler = null;
  private messageReceiveTimeoutHandler = null;

  // Used for filtering lookups
  suggestedValuesDropdowns: Array<any> = [];
  suggestedValues: Array<any> = [];

  groupsWithProperties: Array<ProductPropertyGroup> = [];

  updateAllConfirmationText: string;
  updateAllNumberOfPropsProcessed = 0;
  updateAllAppliedToAll = false;
  //firstLoad = true;
  total: number;
  JSON: any;
  lang = 'nl-be';
  showActionsMessage = false;
  calculations: Array<Calculation> = [];
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

  addNew = false;
  isFilling = false;
  savePending = false;

  masterDetailsLotId: number = null;
  lotId: number = null;

  selectedLotProperty: LotProperty;
  value: any = null;
  isDataValid = true;

  isWindowsOS = false;
  popUpShowed = false;
  isCaptured = false;
  videoError: any;
  isMultiEditorModeEnabled: boolean = false;
  isTouchMode: boolean = true;
  weightingScaleConnectionStatus: number = 0;

  get editorProperties(): Array<LotEditorProperty> {
    return this.product.supplyDataTouchEditorProperties;
  }

  @ViewChildren(DxDateBoxComponent) private dxDateBoxComponents: QueryList<DxDateBoxComponent>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected injector: Injector,
    private location: ngLocation,
    private dataService: LotService,
    private productService: ProductService,
    private catalogService: CatalogService,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private lookupTableService: LookupTableService,
    private maskService: MaskService,
    private translateService: TranslateService,
    private calculationService: CalculationsService,
    private dateTimeService: DateTimeService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private mailService: MailService,
    private cookies: CookieService,
  ) {
    super(injector, Lot);
    this.JSON = JSON;
    this.lang = translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {

    this.checkForOSVersion();

    this.catalogId = +this.route.snapshot.params['catalogId'];
    this.masterDetailsLotId = +this.route.snapshot.queryParams['masterDetailsLotId'];
    this.lotId = this.route.snapshot.queryParams['lotId'];

    if (this.lotId) {
      this.lotId = +this.lotId;
    }

    this.translate.get('VALIDATION').subscribe((res: string) => {
      this.validationTranslation = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('VALIDATION').subscribe((res: string) => {
        this.validationTranslation = res;
      });
    });

    this.setTranslations('LOTS');
  }

  private createHubConnection(weighingScaleUrl: string) {
    //const userId = this.cookies.get(Cookies.USER_ID_COOKIE);

    let hubConnection = new HubConnectionBuilder()
      .withUrl(weighingScaleUrl, { transport: HttpTransportType.WebSockets, skipNegotiation: true, })
      .configureLogging(LogLevel.Error)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect(<IReconnectPolicy>{
        nextRetryDelayInMilliseconds: this.nextRetryDelayInMilliseconds
      })
      .build();

    /*hubConnection.onreconnected((connectionId: string) => {
      
    });*/
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === weighingScaleUrl);

    hubConnection.onclose(() => {
      this.reconnectionTimeoutHandler = setTimeout(() => this.startConnection(weighingScaleUrl), 2000);
      console.log('Reconnecting in 2 sec...');
      existingConnection.started = false;
      existingConnection.error = true;
    });

    hubConnection.on('ReceiveMessage', (response: string) => {
      clearTimeout(this.messageReceiveTimeoutHandler);
      let message = JSON.parse(response);
      this.updateWeighingScaleCache(weighingScaleUrl, message.Weight, message.Stable);
      this.messageReceiveTimeoutHandler = setTimeout(() => existingConnection.error = true, 5000);
    });

    return hubConnection;
  }

  nextRetryDelayInMilliseconds(previousRetryCount: number, elapsedMilliseconds: number) {
    return 2000;
  }

  updateWeighingScaleCache(weighingScaleUrl: string, weight: number, stable: boolean) {
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === weighingScaleUrl);
    let previousStable = false;
    if (existingConnection) {
      previousStable = existingConnection.stable;
      existingConnection.weight = weight;
      existingConnection.stable = stable;

      let lotProperty = this.lotProperties.find(_ => _.isWeighingScaleOn);
      if (lotProperty) {
        lotProperty.decimalValue = weight;
        let currentConnection = this.weighingScaleConnections.find(_ => _.productProperties.includes(lotProperty.productPropertyId));

        if (currentConnection.stable) {
          this.weightingScaleConnectionStatus = 1;
        } else if (!currentConnection.stable) {
          this.weightingScaleConnectionStatus = 2;
        } else if (currentConnection.error) {
          this.weightingScaleConnectionStatus = 3;
        }
        if (!previousStable && stable) {
          this.onPropertyChanged(lotProperty);
        }
      }
    }
  }

  addWeighingScaleConnection(weighingScaleUrl: string, useSimulator: boolean, productPropertyId: number) {
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === weighingScaleUrl);
    if (existingConnection) {
      if (!existingConnection.productProperties.find(_ => _ === productPropertyId))
        existingConnection.productProperties.push(productPropertyId);

      return;
    }

    let newConnection = new WeighingScaleConnection();
    newConnection.url = weighingScaleUrl;
    newConnection.productProperties = [];
    newConnection.productProperties.push(productPropertyId);
    newConnection.useSimulator = useSimulator;
    newConnection.hubConnection = this.createHubConnection(weighingScaleUrl);

    this.weighingScaleConnections.push(newConnection);

    this.startConnection(newConnection.url);


  }

  startConnection(url: string) {
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === url);

    if (existingConnection && !existingConnection.started) {
      existingConnection.hubConnection
        .start()
        .then(() => {
          if (existingConnection.useSimulator) {
            existingConnection.hubConnection.send('SendMessage', 'start-simulator');
          }

          else {
            existingConnection.hubConnection.send('SendMessage', 'start');
          }

          existingConnection.started = true;
          existingConnection.error = false;
          this.messageReceiveTimeoutHandler = setTimeout(() => existingConnection.error = true, 5000);
        })
        .catch(error => {
          console.log('Error while establishing SignalR connection ' + url);
          console.log('Reconnecting in 2 sec...');
          this.reconnectionTimeoutHandler = setTimeout(() => this.startConnection(url), 2000);
          existingConnection.started = false;
          existingConnection.error = true;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getPropertyWidth(property: LotProperty) {
    return property.width && property.width > 0 ? `${property.width - 3}%` : '';
  }

  getPropertyMargin(property: LotProperty) {
    return property.width && property.width > 0 ? `3%` : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.removeFocusableFromDropdowns();
      this.removeFocusableFromCheckbox();
      this.setFocus();
    }, TIMEOUT);
  }

  removeFocusableFromDropdowns() {
    const dds = this.document.querySelectorAll('input[role="combobox"]:not(.dx-texteditor-input)');
    for (let i = 0; i < dds.length; i += 1) {
      const ti = dds[i]
        && dds[i].parentElement
        && dds[i].parentElement.parentElement
        && dds[i].parentElement.parentElement.parentElement
        && dds[i].parentElement.parentElement.parentElement.parentElement
        && dds[i].parentElement.parentElement.parentElement.parentElement.getAttribute('tabindexforchildren') ?
        dds[i].parentElement.parentElement.parentElement.parentElement.getAttribute('tabindexforchildren') : '0';

      dds[i].setAttribute('tabIndex', ti);
      dds[i].setAttribute('class', 'focusable');
    }
  }

  removeFocusableFromCheckbox() {
    const cb = this.document.querySelectorAll('clr-checkbox input');
    for (let i = 0; i < cb.length; i += 1) {
      const ti = cb[i]
        && cb[i].parentElement
        && cb[i].parentElement.getAttribute('tabindexforchildren') ?
        cb[i].parentElement.getAttribute('tabindexforchildren') : '0';

      cb[i].setAttribute('tabIndex', ti);
      cb[i].setAttribute('class', 'focusable');
    }
  }

  // if newLot is set, we are already at loaded editor, no need to get all foreign data or empty newLot object
  getData(newLot: Lot = null) {
    this.spinner.show();

    if (!this.catalog) {
      if (this.isForecastCatalog) {
        this.catalogService.getAllForecastCatalogs(this.id).subscribe(res => {
          this.catalogs = res;
          const catalog = res.find(f => f.catalogId === this.catalogId);
          this.getDataProccess(catalog, newLot);
          this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');

          if (this.masterDetailsLotId) {
            this.title.add('AUCTION.MASTER_DETAILS.TITLE');
          }

          this.title.add(catalog.name);
          this.title.add('LOTS.LOT_EDITOR');
        });
      } else {
        this.catalogService.getAllSupplyCatalogs(this.id).subscribe(res => {
          this.catalogs = res;
          const catalog = res.find(f => f.catalogId === this.catalogId);
          this.getDataProccess(catalog, newLot);
          this.title.set('AUCTION.SUPPLY_CATALOG_MANAGEMENT');

          if (this.masterDetailsLotId) {
            this.title.add('AUCTION.MASTER_DETAILS.TITLE');
          }

          this.title.add(catalog.name);
          this.title.add('LOTS.LOT_EDITOR');
        });
      }
    } else {
      this.getDataProccess(this.catalog, newLot);
      if (this.isForecastCatalog) {
        this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');
      } else {
        this.title.set('AUCTION.SUPPLY_CATALOG_MANAGEMENT');
      }

      if (this.masterDetailsLotId) {
        this.title.add('AUCTION.MASTER_DETAILS.TITLE');
      }

      this.title.add(this.catalog.name);
      this.title.add('LOTS.LOT_EDITOR');
    }
  }

  // tslint:disable:no-magic-numbers
  getDataProccess(catalog: Catalog, newLot: Lot) {
    this.catalog = catalog;

    //this.masterDetailsData = [];
    //this.masterDetailsItems = [];

    const sources: Array<any> = [];

    //if (!this.firstLoad) {
    //sources.push(this.dataService.getNew(this.catalog.auctionId, this.catalogId, this.masterDetailsLotId));
    //}

    sources.push(this.dataService.getNew(this.catalog.auctionId, this.catalogId, this.masterDetailsLotId, true));
    sources.push(this.productService.getProduct(this.id, this.catalog.productId));
    sources.push(this.languageService.getLanguages());
    sources.push(this.lookupTableService.getLookupTables(this.id, this.translateService.currentLang));
    sources.push(this.calculationService.getCalculations(this.id, this.translateService.currentLang));
    sources.push(this.languageService.getAuctionClusterLanguages(this.id));

    forkJoin(...sources).subscribe(result => {

      this.newLot = <Lot>result[0];
      this.lot = JSON.parse(JSON.stringify(this.newLot));

      this.product = <Product>result[1];
      this.languages = <Array<Language>>result[2];
      this.lookupTables = <Array<LookupTable>>result[3];
      this.calculations = <Array<Calculation>>result[4];
      this.clusterLanguages = <Array<Language>>result[5];

      if (this.clusterLanguages.length > 0) {
        this.defaultClusterLanguage = this.clusterLanguages.find(l => l.isDefault);

        if (!this.defaultClusterLanguage) {
          this.defaultClusterLanguage = this.languages.find(l => l.isDefault);
        }
      }

      if (this.product.enableDesktopScreenLayout && this.product.enableTouchScreenLayout) {
        this.isMultiEditorModeEnabled = true;
      } else {
        this.isMultiEditorModeEnabled = false;
      }

      this.parseLotProperties();
      this.getPresetCookieProperty();
      this.setDropdownValues();

      this.getMasterDataLists().then(result => {
        // Select first focusable property after master data has been loaded
        this.focusNextElement();
      });

      if (this.lotId) {
        this.edit(this.lotId);
      }

      /*setTimeout(() => {
        this.removeFocusableFromDropdowns();
        this.removeFocusableFromCheckbox();
        this.setFocus();
      }, TIMEOUT);*/

      this.spinner.hide();

      //this.dataService.getMasterDetails(this.id, this.catalog.catalogId, this.isForecastCatalog)
      //.subscribe(res => {
      //  this.masterDetailsItems = res.rows;
      //  this.parseColumns(res.columnTitles);
      //  this.parseObject(res.rows);
      //},
      //error => {
      //  this.errorService.show(error);
      //});
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
  // tslint:enable:no-magic-numbers

  getMasterDataLists(): Promise<any> {
    return new Promise((resolve, reject) => {
      const masterDataListIds: Array<number> = [];
      // this.product.productProperties.forEach(property => {
      //   if (property.masterDataListId) {
      //     if (masterDataListIds.indexOf(property.masterDataListId) === -1) {
      //       masterDataListIds.push(property.masterDataListId);
      //     }
      //   }
      // });

      let editorSelection = this.product.supplyDataTouchEditorProperties;
      let gridSelection = this.product.supplyDataEntryGridProperties;

      if (this.isForecastCatalog) {
        editorSelection = this.product.supplyForecastDataEditorProperties;
        gridSelection = this.product.supplyForecastDataEntryGridProperties;
      }

      editorSelection.forEach(p => {
        const property = this.product.productProperties.find(pp => pp.productPropertyId === p.productPropertyId);
        if (property && property.masterDataListId && masterDataListIds.indexOf(property.masterDataListId) === -1) {
          masterDataListIds.push(property.masterDataListId);
        }
      });

      gridSelection.forEach(p => {
        const property = this.product.productProperties.find(pp => pp.productPropertyId === p.productPropertyId);
        if (property && property.masterDataListId && masterDataListIds.indexOf(property.masterDataListId) === -1) {
          masterDataListIds.push(property.masterDataListId);
        }
      });

      this.spinner.show();
      this.masterDataService.getMasterDataListByIdsTouchOnly(masterDataListIds, this.id)
        .subscribe(res => {
          res.forEach((result: MasterData) => {
            this.masterDatas.push(result);
            const catalogMasterDataIds: Array<number> = [];
            this.masterDatas.forEach(masterData => {
              masterData.fields.forEach(masterDataListField => {
                if (masterDataListField.useFieldInCatalog) {
                  catalogMasterDataIds.push(masterDataListField.masterDataListFieldId);
                }
              });
            });
          });

          this.getPresetCookieProperty();

          this.parseMasterDataDropdown();

          // added for ticket #7487 - get masks and others only when initial master datas are ready
          // if (isFirstLoad) {
          //   this.applyOnchangeToAll();
          // }
          this.spinner.hide();

          resolve(null);
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();

            reject();
          });
    });
  }

  getPresetCookieProperty() {
    let deviceId = this.cookies.get(Cookies.COMPUTER_COOKIE);
    let masterDataIdField = null;
    let deviceProductProperty = this.product.productProperties.find(p => p.systemProductPropertyType === SystemProductPropertyTypesEnum.Computer);
    if (!this.deviceMasterDataListRowId && deviceId != undefined && deviceProductProperty) {
      const parsed = parseInt(deviceId);
      if (!isNaN(parsed)) {

        const masterData = this.masterDatas
          .find(md => md.masterDataListId === deviceProductProperty.masterDataListId);

        if (masterData) {
          masterDataIdField = masterData.fields.find(f => f.fieldType === MasterDataFieldTypeEnum.Id);

          masterData.rows.forEach(r =>
            r.values.forEach(v => {
              if (v.masterDataListFieldId === masterDataIdField.masterDataListFieldId) {
                if (v.intValue === parsed) {
                  this.deviceMasterDataListRowId = r.masterDataListRowId;
                }
              }
            }));
        }
      }
    }

    if (deviceProductProperty) {
      this.lot.lotPropertyGroups.forEach(g => {
        g.lotPropertyGroupRows.forEach(r => {
          r.lotProperties.forEach(lotProp => {
            if (lotProp.productPropertyId === deviceProductProperty.productPropertyId) {
              lotProp.masterDataListRowId = this.deviceMasterDataListRowId;

              // Manually triger lot update if depending property exists
              if (!this.lot.inEditMode)
                this.onPropertyChanged(lotProp);
            }
          });
        });
      });
    }
  }

  isDateInvalid(date: any, required: boolean) {
    if (!required) {
      return false;
    }
    let input = null;
    if (date && date.dateInput && date.dateInput.nativeElement) {
      input = date.dateInput.nativeElement;
      const classList = input.classList;
      let touched = false;
      let dirty = false;
      let invalid = false;
      classList.forEach(c => {
        if (c === 'ng-touched') {
          touched = true;
        }
        if (c === 'ng-dirty') {
          dirty = true;
        }
        if (c === 'ng-invalid') {
          invalid = true;
        }
      });
      return (touched || dirty) && invalid;
    }
    return false;
  }

  parseMasterDataDropdown() {
    this.lot.lotPropertyGroups.forEach(g => {
      if (!g.isHidden) {
        g.lotPropertyGroupRows.forEach(r => {
          r.lotProperties.forEach(lotProp => {
            const dropdownValues: Array<MasterDataDropdown> = [];
            if (lotProp.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
              const masterData = this.masterDatas
                .find(md => md.masterDataListId === this.product.productProperties
                  .find(pp => pp.productPropertyId === lotProp.productPropertyId).masterDataListId);

              const useInCatalogFields = masterData.fields.filter(f => f.useFieldInCatalog);

              if (useInCatalogFields.length === 0) {
                useInCatalogFields.push(masterData.fields[0]);
              }

              useInCatalogFields.forEach(field => {
                masterData.rows.forEach(row => {
                  row.values.forEach(rowValue => {
                    if (rowValue.masterDataListFieldId === field.masterDataListFieldId) {
                      switch (field.propertyTypeId) {
                        case ProductPropertyTypeEnum.NUMBER:
                          if (rowValue.intValue !== null) {
                            dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.intValue).toString() });
                          }
                          break;
                        case ProductPropertyTypeEnum.TEXT:
                          if (rowValue.stringValue && rowValue.stringValue !== null) {
                            let text = rowValue.stringValue.replace(/<[^>]*>/g, '');
                            if (field.translatable) {
                              text = this.getTranslation(text);
                            }
                            if (text != null) {
                              dropdownValues.push({ value: row.masterDataListRowId, label: text });
                            }
                          }
                          break;
                        case ProductPropertyTypeEnum.DECIMAL:
                          if (rowValue.decimalValue !== null) {
                            dropdownValues.push({ value: row.masterDataListRowId, label: (rowValue.decimalValue).toString() });
                          }
                          break;
                        case ProductPropertyTypeEnum.DATE:
                          if (rowValue.dateTimeValue !== null) {
                            const dateString = this.dateTimeService.getDateStringByFormatAnyUtc(rowValue.dateTimeValue, field.propertyTypeFormatId);
                            dropdownValues.push({ value: row.masterDataListRowId, label: dateString });
                          }
                          break;
                        case ProductPropertyTypeEnum.IMAGE:
                          if (rowValue.imageValue !== null) {
                            dropdownValues.push({ value: row.masterDataListRowId, label: '', img: rowValue.imageValue });
                          }
                          break;
                        case ProductPropertyTypeEnum.BOOLEAN:
                          if (rowValue.booleanValue !== null) {
                            dropdownValues.push({
                              value: row.masterDataListRowId,
                              label: `${rowValue.booleanValue ? 'true' : 'false'}`
                            });
                          }
                          break;
                        default: break;
                      }
                    }
                  });
                });
              });
            }

            for (let i = 0; i < dropdownValues.length; i++) {
              for (let j = i + 1; j < dropdownValues.length; j++) {
                if (dropdownValues[i].value === dropdownValues[j].value) {
                  if (dropdownValues[j].label !== '') {
                    dropdownValues[i].label = dropdownValues[i].label + ' - ' + dropdownValues[j].label;
                  }
                  dropdownValues.splice(j, 1);
                }
              }
            }
            dropdownValues.sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }

              return 0;
            });
            this.masterDataDropdowns.push({ productPropertyId: lotProp.productPropertyId, values: dropdownValues });
          });
        });
      }
    });

    // set initial dropdowns from new ednpoint
    this.masterDataDropdownsFiltered = JSON.parse(JSON.stringify(this.masterDataDropdowns));
    this.setDropdownValues();
  }

  setDropdownValues() {
    this.dropdownsData.length = 0;

    this.suggestedValuesDropdowns = [];
    this.product.productProperties.filter(pp => pp.propertyTypeId !== ProductPropertyTypeEnum.MASTER_DATA).forEach(pp => {
      const lookupTableId = this.isProductWithLookupTable(pp.productPropertyId);
      if (lookupTableId > 0) {
        const lookup = this.lot.lookupTables.find(l => l.lookupTableId === lookupTableId);

        if (lookup && lookup.lookupTableRows.length > 0) {
          const values: Array<any> = [];
          lookup.lookupTableRows.forEach(ltr => {
            switch (pp.propertyTypeId) {
              case ProductPropertyTypeEnum.NUMBER:
                values.push(ltr.resultIntValue);
                break;
              case ProductPropertyTypeEnum.DECIMAL:
                values.push(ltr.resultDecimalValue);
                break;
              case ProductPropertyTypeEnum.TEXT:
                values.push(ltr.resultStringValue);
                break;
              case ProductPropertyTypeEnum.DATE:
                values.push(ltr.resultDateTimeValue);
                break;
              default:
                break;
            }
          });

          this.suggestedValuesDropdowns.push({ productPropertyId: pp.productPropertyId, values: values });
        }
      }
    });

    this.masterDataDropdowns.forEach((md, i) => {
      const newValues = [];
      // apply lookups
      const lookupTableId = this.isProductWithLookupTable(md.productPropertyId);

      if (lookupTableId > 0) {
        const lookup = this.lot.lookupTables.find(l => l.lookupTableId === lookupTableId);

        if (lookup && lookup.lookupTableType == 0) {
          this.masterDataDropdownsFiltered[i].filtered = false;
          md.values.forEach(v => {
            lookup.lookupTableRows.forEach(row => {
              if (row.resultMasterDataListRowId === v.value) {
                newValues.push(v);
              }
              else {
                row.lookupTableValues.forEach(value => {
                  if (value.masterDataListRowId === v.value) {
                    newValues.push(v);
                  }
                });
              }
            });
          });
        }
      }

      // apply masks
      const mask = this.lot.maskResults[md.productPropertyId];

      if (mask /*&& mask.length > 0*/) {
        this.masterDataDropdownsFiltered[i].filtered = false;
        newValues.length = 0;

        md.values.forEach(v => {
          const valueInMask = mask.find(m => m.masterDataListRowId === v.value);
          if (valueInMask) {
            newValues.push(v);
          }
        });
      }

      if (newValues.length > 0) {
        this.dropdownsData.push({ productPropertyId: md.productPropertyId, values: newValues });
        this.masterDataDropdownsFiltered[i] = { productPropertyId: md.productPropertyId, values: newValues, filtered: true }
        this.lot.lotPropertyGroups.forEach(group => {
          if (!group.isHidden) {
            group.lotPropertyGroupRows.forEach(row => {
              const currentLotProperty = row.lotProperties.find(lotProperty => lotProperty.productPropertyId === md.productPropertyId);
              if (currentLotProperty && currentLotProperty.masterDataListRowId) {
                var foundValue = newValues.find(v => v.value === currentLotProperty.masterDataListRowId);
                if (!foundValue) {
                  currentLotProperty.masterDataListRowId = null;
                }
              }
            });
          }
        });
      } else if (this.masterDataDropdownsFiltered[i].filtered) {
        // set all filtered
        this.dropdownsData.push(this.masterDataDropdownsFiltered[i]);
      } else {
        // set all
        this.dropdownsData.push(md);
      }
    });
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.product.productProperties.length; i += 1) {
      if (this.product.productProperties[i].productPropertyId === productPropertyId
        && this.product.productProperties[i].lookupTableId) {
        return this.product.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  findLotProperty(productPropertyId: number) {
    let lp = null;
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          row.lotProperties.forEach(lotProperty => {
            if (lotProperty.productPropertyId === productPropertyId) {
              lp = lotProperty;
            }
          });
        });
      }
    });

    return lp;
  }

  resetDropdowns() {
    // clear values first
    this.dropdownsData.length = 0;
    for (let i = 0; i < this.masterDataDropdowns.length; i += 1) {
      this.dropdownsData.push(this.masterDataDropdowns[i]);
    }
  }

  fillDropdown(lotProperty: LotProperty): Array<MasterDataDropdown> {
    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
    if (masterDataDropdown) {
      return masterDataDropdown.values;
      // values.sort((a, b) => {
      //   if (a.label < b.label) {
      //     return -1;
      //   }
      //   if (a.label > b.label) {
      //     return 1;
      //   }

      //   return 0;
      // });

      // if (values.length === 1) {
      //   const isSame = this.setLotPropertyValue(lotProperty, values[0].value);
      //   if (!isSame) {
      //     this.onPropertyChanged(lotProperty);
      //   }
      // }

      // return values;
    }

    return [];
  }

  getTranslatableValue(lotProperty: LotProperty) {
    const tr = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
    if (tr) {
      return tr.data;
    }

    return null;
  }

  findPropertyValue(lotProperty: LotProperty, calculationField: CalculationsField = null, returnFullTranslatable = false): any {
    if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.DATE) {
      const dateFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return this.dateTimeService.getDateStringByFormatAnyUtc(lotProperty.dateTimeValue, dateFormatId);
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.DECIMAL) {
      const decimalFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return lotProperty.decimalValue != null ? this.format(lotProperty.decimalValue, lotProperty.propertyTypeId, decimalFormatId) : '';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.NUMBER) {
      return lotProperty.intValue != null ? this.format(lotProperty.intValue, lotProperty.propertyTypeId) : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      if (lotProperty.translatable) {
        return this.getTranslation(lotProperty.stringValue);
      }
      return lotProperty.stringValue;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return lotProperty.booleanValue ? 'true' : 'false';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
      return this.getMasterDataValue(lotProperty, calculationField, returnFullTranslatable);
    }

    return '';
  }

  findPropertyNumberValue(lotProperty: LotProperty, calculationField: CalculationsField = null): any {
    if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.DECIMAL) {
      const decimalFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return lotProperty.decimalValue != null ? this.format(lotProperty.decimalValue, lotProperty.propertyTypeId, decimalFormatId) : '';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.NUMBER) {
      return lotProperty.intValue != null ? this.format(lotProperty.intValue, lotProperty.propertyTypeId) : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return lotProperty.booleanValue ? 1 : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
      return this.getMasterDataNumberValue(lotProperty, calculationField);
    }

    return 0;
  }

  findMasterDataValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField, returnFullTranslatable = false): any {
    if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.DATE) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(masterDataValue.dateTimeValue, masterDataListField.propertyTypeFormatId);
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.DECIMAL) {
      return masterDataValue.decimalValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.NUMBER) {
      return masterDataValue.intValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      if (returnFullTranslatable) {
        return masterDataValue.stringValue;
      }
      if (masterDataListField.translatable) {
        return this.getTranslation(masterDataValue.stringValue);
      }
      return masterDataValue.stringValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.IMAGE) {
      return `<img style="max-width: 50px; max-height: 50px;" src=${masterDataValue.imageValue} />`;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return masterDataValue.booleanValue ? 'true' : 'false';
    }
  }

  findMasterDataNumberValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField): any {
    if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.DECIMAL) {
      return masterDataValue.decimalValue != null ? masterDataValue.decimalValue : 0;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.NUMBER) {
      return masterDataValue.intValue != null ? masterDataValue.intValue : 0;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN) {
      return masterDataValue.booleanValue ? 1 : 0;
    }
  }

  getMasterDataNumberValue(lotProperty: LotProperty, calculationField: CalculationsField) {
    const masterDataListRowId = lotProperty.masterDataListRowId;
    let value = masterDataListRowId ? masterDataListRowId.toString() : '';
    this.masterDatas.forEach(md => {
      md.rows.forEach(r => {
        if (r.masterDataListRowId === masterDataListRowId) {
          r.values.forEach(v => {
            if (calculationField != null) {
              if (calculationField.masterDataListFieldId === v.masterDataListFieldId) {
                value = this.findMasterDataNumberValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
              }
            }
          });
        }
      });
    });
    return value;
  }

  getMasterDataValue(lotProperty: LotProperty, calculationField: CalculationsField = null, returnFullTranslatable = false) {
    const masterDataListRowId = lotProperty.masterDataListRowId;
    let value = masterDataListRowId ? masterDataListRowId.toString() : '';
    this.masterDatas.forEach(md => {
      md.rows.forEach(r => {
        if (r.masterDataListRowId === masterDataListRowId) {
          r.values.forEach(v => {
            if (calculationField != null) {
              if (calculationField.masterDataListFieldId === v.masterDataListFieldId) {
                value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId), returnFullTranslatable);
              }
            } else if (this.isForecastCatalog) {
              this.product.supplyForecastDataEntryGridProperties.forEach(prop => {
                if (prop.masterDataListFieldId === v.masterDataListFieldId &&
                  (lotProperty.orderNumber == null || prop.orderNumber === lotProperty.orderNumber)) {
                  value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
                }
              });
            } else {
              this.product.supplyDataEntryGridProperties.forEach(prop => {
                if (prop.masterDataListFieldId === v.masterDataListFieldId &&
                  (lotProperty.orderNumber == null || prop.orderNumber === lotProperty.orderNumber)) {
                  value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
                }
              });
            }
          });
        }
      });
    });
    return value;
  }

  getMasterDataFieldName(masterDataListFieldId: number) {
    for (let i = 0; i < this.masterDatas.length; i += 1) {
      for (let j = 0; j < this.masterDatas[i].fields.length; j += 1) {
        if (this.masterDatas[i].fields[j].masterDataListFieldId === masterDataListFieldId) {
          return this.getTranslation(this.masterDatas[i].fields[j].name);
        }
      }
    }
  }

  parseLotProperties(isFirstLoad: boolean = false, isEdit: boolean = false) {
    this.masterDataErrors = [];
    this.lotProperties = [];

    let editorProperties = this.product.supplyDataTouchEditorProperties;
    if (this.isForecastCatalog) {
      editorProperties = this.product.supplyForecastDataEditorProperties;
    }
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          row.lotProperties.forEach(lotProperty => {
            const productProperty = this.product.productProperties.find(p => {
              return p.productPropertyId === lotProperty.productPropertyId;
            });

            if (productProperty) {
              lotProperty.name = productProperty.name;
              lotProperty.productPropertyId = productProperty.productPropertyId;
              lotProperty.propertyTypeId = productProperty.propertyTypeId;
              lotProperty.name = productProperty.name;
              lotProperty.minValue = (productProperty.minValue !== undefined && productProperty.minValue !== null) ? productProperty.minValue : undefined;
              lotProperty.maxValue = (productProperty.maxValue !== undefined && productProperty.maxValue !== null) ? productProperty.maxValue : undefined;
              lotProperty.maxLength = (productProperty.maxLength !== undefined && productProperty.maxLength !== null) ? productProperty.maxLength : undefined;
              lotProperty.translatable = productProperty.translatable;
              lotProperty.propertyTypeFormatId = productProperty.propertyTypeFormatId;
              lotProperty.manuallyDisabled = false;
              lotProperty.systemProductPropertyType = productProperty.systemProductPropertyType;
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN && !lotProperty.booleanValue) {
              lotProperty.booleanValue = false;
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.DATE) {
              if (!lotProperty.dateTimeValue &&
                (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_TIME
                  || lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_TIME)) {
                //var today = moment.utc().locale(this.lang).local();
                var today = moment.utc('1971-01-01').locale(this.lang);
                today = today.hour(0);
                today = today.minute(0);
                today = today.second(0);
                lotProperty.dateTimeValue = today;
              }
              else if (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_TIME
                || lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_TIME) {
                lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue).locale(this.lang);
              }
              else {
                if (lotProperty.dateTimeValue) {
                  let serverReturnedTime = moment.utc(lotProperty.dateTimeValue);
                  if (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_DATE ||
                    lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_DATE) {
                    // Reset time part (HH:mm:ss) to 12PM
                    serverReturnedTime = serverReturnedTime.hour(12);
                    serverReturnedTime = serverReturnedTime.minute(0);
                    serverReturnedTime = serverReturnedTime.second(0);
                  }
                  lotProperty.dateTimeValue = moment.utc(serverReturnedTime).locale(this.lang).local();
                }
              }
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.TEXT && lotProperty.translatable) {
              // Create empty JSON object for translation fields
              const emptyTranslation = {};
              this.clusterLanguages.forEach(lang => {
                emptyTranslation[lang.code] = '';
              });

              lotProperty.stringValue = lotProperty.stringValue ? lotProperty.stringValue : JSON.stringify(emptyTranslation);
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.URL && lotProperty.urlValue) {
              // Set source of thumbnail and image if they exist
              lotProperty.displayUrlImageValue = lotProperty.urlValue + '-thumbnail';
              lotProperty.displayUrlTextValue = lotProperty.urlValue;
            }

            const editorProperty = editorProperties.find(p => {
              return p.productPropertyId === lotProperty.productPropertyId;
            });

            if (editorProperty) {
              lotProperty.width = editorProperty.width;
              lotProperty.productPropertyEditorSelectionDetails = editorProperty.productPropertyEditorSelectionDetails;
              if (editorProperty.name) {
                const nameTranslatable = JSON.parse(editorProperty.name);
                if (nameTranslatable) {
                  this.clusterLanguages.forEach(lang => {
                    if (nameTranslatable[lang.code]) {
                      lotProperty.name = editorProperty.name;
                    }
                  });
                }
              }

              if (lotProperty.productPropertyEditorSelectionDetails
                && lotProperty.productPropertyEditorSelectionDetails.allowMultipleValuesWithinFieldDependency
                === AllowMultilpleValuesWithingFieldDependency.NO_SET_READ_ONLY
                && this.getPropValue(lotProperty) !== null && this.getPropValue(lotProperty) !== undefined) {
                lotProperty.readOnlyOnceSelected = true;
              }

              if (!group.name) {
                const productGroup = this.product.productPropertyGroups.find(g => {
                  return g.productPropertyGroupId === editorProperty.productPropertyGroupId;
                });

                if (productGroup) {
                  group.name = this.getTranslation(productGroup.name);
                  group.collapse = productGroup.collapse;
                }
              }

              if (editorProperty.productPropertyEditorSelectionDetails.useWeighingScale) {
                this.addWeighingScaleConnection(editorProperty.productPropertyEditorSelectionDetails.weighingScaleUrl, editorProperty.productPropertyEditorSelectionDetails.useWeighingScaleSimulator, editorProperty.productPropertyId);
              }
            }

            this.lotProperties.push(lotProperty);
          });
        });
      }
    });


    // commented out for ticket 7487 - dropdowns needed to be updated on every load because previus set of values has no value of next selected lot
    // if (!isEdit) {
    //   this.applyOnchangeToAll();
    // }
    // if (!isFirstLoad && !isEdit) {
    //   this.applyOnchangeToAll();
    // }
  }

  edit(lotId: number) {

    //this.resetDropdowns();

    this.dataService.getLot(this.catalog.auctionId, lotId, true).subscribe(result => {

      this.lot.catalogId = result.catalogId;
      this.lot.lotId = result.lotId;
      this.lot.lotPropertyGroups = result.lotPropertyGroups;
      this.lot.isPublished = result.isPublished;
      this.lot.lookupTables = result.lookupTables;
      this.lot.maskResults = result.maskResults;
      this.lot = result;

      this.parseLotProperties(false, true);
      this.setDropdownValues();

      this.updateAllNumberOfPropsProcessed = 0;
      this.updateAllAppliedToAll = false;

      this.lot.inEditMode = true;
    });
  }

  resetEdit() {
    this.lot = JSON.parse(JSON.stringify(this.newLot));
    this.parseLotProperties();
    this.setDropdownValues();

    this.selectedLotProperty = null;
    this.focusNextElement();
    //this.lot = JSON.parse(JSON.stringify(this.latestNewLot));
    //this.lotForm.reset();
  }

  delete(itemId: number, event: Event) {
    event.stopPropagation();
    this.showActionsMessage = false;

    this.dataService.checkLotActions(this.catalog.auctionId, itemId).subscribe(res => {
      if (res) {
        this.itemIdToDelete = itemId;
        this.showActionsMessage = true;
        this.confirmation.opened = true;
      } else {
        this.itemIdToDelete = itemId;
        this.showActionsMessage = false;
        this.confirmation.opened = true;
      }
    }, error => {
      this.itemIdToDelete = itemId;
      this.showActionsMessage = false;
      this.confirmation.opened = true;
    });
  }

  printSupply(itemId: number, event: Event) {
    event.stopPropagation();
    this.showActionsMessage = false;

    const ids = [];
    ids.push(itemId);

    this.dataService.printSupply(this.catalog.auctionClusterId, this.catalogId, ids, false)
      .subscribe(r => {
      }, error => {
        this.errorService.show(error);
      });
  }

  deleteSelected() { }

  validateTranslatable() {
    this.translatableFields.forEach(item => {
      if (!item.valid) {
        this.translatableValid = false;
      } else {
        this.translatableValid = true;
      }
    });
  }

  getTabIndex(p: LotProperty) {
    const d = p.productPropertyEditorSelectionDetails;
    if (d.focusType === FocusTypeEnum.NEVER) {
      return -1;
    } else if (d.focusType === FocusTypeEnum.ALWAYS) {
      return 0;
    }

    if (d.isVisible && !d.isReadOnly
      && !p.intValue
      && !p.decimalValue
      && !p.stringValue
      && !p.dateTimeValue
      && !p.booleanValue
      && !p.masterDataListRowId) {
      return 0;
    } else {
      return -1;
    }
  }


  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.product.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getDependentCalculations(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.calculations.length; i += 1) {
      for (let j = 0; j < this.calculations[i].calculationFields.length; j += 1) {
        if (this.calculations[i].calculationFields[j].productPropertyId === productPropertyId
          && dependentPropIds.indexOf(this.calculations[i]) === -1) {
          dependentPropIds = [...dependentPropIds, this.calculations[i]];
        }
      }
    }

    return dependentPropIds;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    this.product.productProperties.forEach(pp => {
      if (pp.dateFilterProductPropertyId === productProperty.productPropertyId) {
        dependentPropIds = [...dependentPropIds, pp.productPropertyId];
      }
    });
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }


  getMultiplier(propertyTypeFormatId: number) {
    switch (propertyTypeFormatId) {
      case DecimalTypeEnum.DECIMAL_0digit:
        return 1;
      case DecimalTypeEnum.DECIMAL_1digit:
        return 10;
      case DecimalTypeEnum.DECIMAL_2digit:
        return 100;
      case DecimalTypeEnum.DECIMAL_3digit:
        return 1000;
      case DecimalTypeEnum.DECIMAL_4digit:
        return 10000;
      case DecimalTypeEnum.DECIMAL_5digit:
        return 100000;
      default:
        return null;
    }
  }

  private isJsonString(str: string) {
    try {
      const o = JSON.parse(str);
      if (o && typeof o === 'object') {
        return true;
      }

    } catch (e) {
      //
    }
    return false;
  }

  getTranslatableTextValue(jsonString, language) {
    const json = JSON.parse(jsonString);

    if (json[language.code]) {
      return json[language.code];
    } else {
      return json[this.defaultClusterLanguage.code].toString();
    }
  }

  joinTranslatables(values, separator) {
    const languagesJson = {};

    this.languages.forEach(l => {
      const stringValues = [];
      values.forEach(v => {
        if (this.isJsonString(v)) {
          const value = this.getTranslatableTextValue(v, l);
          stringValues.push(value);
        } else {
          stringValues.push(v);
        }
      });

      languagesJson[l.code] = stringValues.join(separator);
    });

    return JSON.stringify(languagesJson);
  }

  getDependentProperties(productPropertyId) {
    let dependentPropIds = [];

    this.product.supplyDataTouchEditorProperties.forEach(prop => {
      if (prop.productPropertyEditorSelectionDetails.dependencyProperties) {
        if (prop.productPropertyEditorSelectionDetails.dependencyProperties.includes(productPropertyId)) {
          dependentPropIds.push(prop.productPropertyId)
        }
      }

    });

    return dependentPropIds;
  }

  minimalPriceChange(changedProperty: LotProperty) {

    if (changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop4 || changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop3
      || changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop2 || changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop1) {
      return true;
    }

    return false;
  }

  onPropertyChanged(changedProperty: LotProperty, isApplyingToAll: boolean = false) {
    // if not master data check if really changed
    if (changedProperty.propertyTypeId !== ProductPropertyTypeEnum.MASTER_DATA && !changedProperty.changed) {
      return;
    }
    changedProperty.changed = false;

    const dependentLookupPropIds = this.getLookupDependentPropIds(changedProperty.productPropertyId);
    const dependentCalculations = this.getDependentCalculations(changedProperty.productPropertyId);
    const dependentProperties = this.getDependentProperties(changedProperty.productPropertyId);
    const dependentMinimalPriceProperties = this.minimalPriceChange(changedProperty);
    if (dependentLookupPropIds.length > 0 || dependentCalculations.length > 0 || dependentProperties.length > 0) {

      this.spinner.show();
      this.resetDropdowns();

      this.parseDatesBeforeSave();
      this.isFilling = true;
      this.dataService.fillProperties(this.catalog.auctionId, changedProperty.productPropertyId, this.masterDetailsLotId, this.lot, true).subscribe(result => {
        console.log(result);
        result.lotPropertyGroups.forEach(g => {
          g.lotPropertyGroupRows.forEach(r => {
            r.lotProperties.forEach(lotProperty => {
              const lotProp = this.findLotProperty(lotProperty.productPropertyId);
              if (lotProp) {
                switch (lotProp.propertyTypeId) {
                  case ProductPropertyTypeEnum.MASTER_DATA:
                    this.setLotPropertyValue(lotProp, lotProperty.masterDataListRowId);
                    break;
                  case ProductPropertyTypeEnum.NUMBER:
                    this.setLotPropertyValue(lotProp, lotProperty.intValue);
                    break;
                  case ProductPropertyTypeEnum.DECIMAL:
                    this.setLotPropertyValue(lotProp, lotProperty.decimalValue);
                    break;
                  case ProductPropertyTypeEnum.DATE:
                    this.setLotPropertyValue(lotProp, lotProperty.dateTimeValue);
                    break;
                  case ProductPropertyTypeEnum.BOOLEAN:
                    this.setLotPropertyValue(lotProp, lotProperty.booleanValue);
                    break;
                  case ProductPropertyTypeEnum.URL:
                    this.setLotPropertyValue(lotProp, lotProperty.urlValue);
                    if (lotProperty.urlValue) {
                      lotProp.displayUrlTextValue = lotProperty.urlValue;
                      lotProp.displayUrlImageValue = lotProperty.urlValue + '-thumbnail';
                    } else {
                      lotProp.displayUrlTextValue = null;
                      lotProp.displayUrlImageValue = this.emptyPictureSource;
                    }
                    break;
                  default:
                    this.setLotPropertyValue(lotProp, lotProperty.stringValue);
                    break;
                }
              }
            });
          });
        });

        result.lookupTables.forEach(l => {
          var existingLookup = this.lot.lookupTables.find(lookup => lookup.lookupTableId === l.lookupTableId);
          if (existingLookup) {
            existingLookup.lookupTableRows = l.lookupTableRows;
          }
          else {
            this.lot.lookupTables.push(l);
          }
        });

        Object.keys(result.maskResults).forEach((key, index) => {
          // key: the name of the object key
          // index: the ordinal position of the key within the object
          this.lot.maskResults[key] = result.maskResults[key];
        });


        //this.lot.lookupTables = result.lookupTables;
        //this.lot.maskResults = result.maskResults;

        this.setDropdownValues();

        // Force refresh of selected property
        //this.selectProperty(this.selectedLotProperty);
        this.focusNextElement();

        this.isFilling = false;

        if (this.savePending && this.lotForm.form.valid) {
          this.savePending = false;
          this.onSubmit();
        }

        //if the form is invalid after fetching data, cancel the save procedure
        if (!this.lotForm.form.valid) {
          this.savePending = false;
        }

        this.checkRequiredData();
        this.spinner.hide();
      }, error => {
        this.isFilling = false;
        this.savePending = false;
        this.spinner.hide();
      });

    } else {
      this.focusNextElement();
    }

    // this.lot.lotPropertyGroups.forEach(g => {
    //   g.lotPropertyGroupRows.forEach(r => {
    //     r.lotProperties.forEach(lotProperty => {
    //       // this lotProperty is dependent on changedProperty
    //       if (lotProperty.productPropertyEditorSelectionDetails
    //           && lotProperty.productPropertyEditorSelectionDetails.dependencyProperties.indexOf(changedProperty.productPropertyId) > -1) {
    //         this.processPropertyDependencies(lotProperty, changedProperty);
    //       }
    //     });
    //   });
    // });
  }

  clearLotProperty(lotProperty: LotProperty) {
    lotProperty.intValue = null;
    lotProperty.decimalValue = null;
    lotProperty.stringValue = null;
    lotProperty.dateTimeValue = null;
    lotProperty.masterDataListRowId = null;
    lotProperty.booleanValue = null;
    lotProperty.urlValue = null;
  }

  setLotPropertyValue(lotProperty: LotProperty, value: any) {
    let isSame = false;
    switch (lotProperty.propertyTypeId) {
      case ProductPropertyTypeEnum.MASTER_DATA:
        if (lotProperty.masterDataListRowId === value) {
          isSame = true;
        }
        lotProperty.masterDataListRowId = value;
        break;
      case ProductPropertyTypeEnum.NUMBER:
        if (lotProperty.intValue === value) {
          isSame = true;
        }
        lotProperty.intValue = value;
        break;
      case ProductPropertyTypeEnum.DECIMAL:
        if (lotProperty.decimalValue === value) {
          isSame = true;
        }
        lotProperty.decimalValue = value;
        break;
      case ProductPropertyTypeEnum.TEXT:
        if (lotProperty.stringValue === value) {
          isSame = true;
        }
        lotProperty.stringValue = value;
        break;
      case ProductPropertyTypeEnum.DATE:
        if (lotProperty.dateTimeValue === value) {
          isSame = true;
        }
        if (!value &&
          (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_TIME
            || lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_TIME)) {
          //var today = moment.utc().locale(this.lang).local();
          var today = moment.utc("1971-01-01").locale(this.lang);
          today = today.hour(0);
          today = today.minute(0);
          today = today.second(0);
          lotProperty.dateTimeValue = today;
          break;
        }
        else if (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_TIME
          || lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_TIME) {
          lotProperty.dateTimeValue = moment.utc(value).locale(this.lang);
          break;
        }
        const serverReturnedTime = moment.utc(value);
        lotProperty.dateTimeValue = moment.utc(serverReturnedTime).locale(this.lang).local();
        // lotProperty.dateTimeValue = value;
        break;
      case ProductPropertyTypeEnum.BOOLEAN:
        if (lotProperty.booleanValue === value) {
          isSame = true;
        }
        lotProperty.booleanValue = value;
        break;
      case ProductPropertyTypeEnum.URL:
        if (lotProperty.urlValue === value) {
          isSame = true;
        }
        lotProperty.urlValue = value;
        break;
      default:
        break;
    }

    return isSame;
  }

  focusFirstInvalidElement(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).some((control, index) => {
      if (!control.valid) {
        const el = this.document.getElementsByName(Object.keys(formGroup.controls)[index])[0];
        if (el) {
          (<HTMLElement>el).focus();
          return true;
        }
      }
    });
  }

  markDateInputsTouched() {
    const inputs = Array.prototype.slice.call(this.document.querySelectorAll('.dt-input input'));

    inputs.forEach(i => {
      i.classList.remove('ng-untouched');
      i.classList.add('ng-touched');
    });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });

    this.markDateInputsTouched();
  }

  setFocus() {
    const elements = this.document.querySelectorAll('.focusable');
    for (let i = 0; i < elements.length; i += 1) {
      if (elements[i] && (<HTMLElement>elements[i]).tabIndex !== -1 && !elements[i].classList.contains('dx-datebox')) {
        (<HTMLElement>elements[i]).focus();
        return true;
      }
    }
  }

  getPropValue(prop: LotProperty) {
    switch (prop.propertyTypeId) {
      case ProductPropertyTypeEnum.NUMBER:
        return prop.intValue;
      case ProductPropertyTypeEnum.TEXT:
        return prop.stringValue;
      case ProductPropertyTypeEnum.DECIMAL:
        return prop.decimalValue;
      case ProductPropertyTypeEnum.DATE:
        return this.dateTimeService.getDateStringByFormatAnyUtc(prop.dateTimeValue, prop.propertyTypeFormatId);
      case ProductPropertyTypeEnum.BOOLEAN:
        return prop.booleanValue;
      case ProductPropertyTypeEnum.URL:
        return prop.urlValue;
      default:
        return prop.masterDataListRowId;
    }
  }

  datesValid() {
    for (let g = 0; g < this.lot.lotPropertyGroups.length; g += 1) {
      if (!this.lot.lotPropertyGroups[g].isHidden) {
        for (let r = 0; r < this.lot.lotPropertyGroups[g].lotPropertyGroupRows.length; r += 1) {
          for (let l = 0; l < this.lot.lotPropertyGroups[g].lotPropertyGroupRows[r].lotProperties.length; l += 1) {
            const lotProp = this.lot.lotPropertyGroups[g].lotPropertyGroupRows[r].lotProperties[l];
            if (lotProp.propertyTypeId === ProductPropertyTypeEnum.DATE && lotProp.productPropertyEditorSelectionDetails.required) {
              if (!moment(lotProp.dateTimeValue).isValid()) {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }

  async onSubmit() {

    if (!this.checkRequiredData()) {
      this.errorService.show(this.errorService.translations.TOUCH_EDITOR_INVALID_DATA);
      return;
    }

    if (this.isFilling) {
      this.savePending = true;
      return;
    }

    this.lot.catalogId = this.catalogId;

    // if (!this.enforceRuleSet() || !this.lotForm.valid) {
    if (!this.lotForm.valid || !this.datesValid()) {
      this.markFormGroupTouched(this.lotForm.form);
      this.focusFirstInvalidElement(this.lotForm.form);
      return;
    }

    this.addLot();
  }

  dateChanged(property: LotProperty, e) {
    if (!e.event)
      return;

    if (moment(e.value).isSame(moment(e.previousValue))) {
      return;
    }

    this.selectProperty(property);

    property.dateTimeValue = moment(e.value).toDate();
    property.changed = true;
    this.onPropertyChanged(property);
  }

  parseDatesBeforeSave() {
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          row.lotProperties.forEach(lotProperty => {
            if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.DATE && lotProperty.dateTimeValue) {
              // if (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_TIME || lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_TIME) {
              //   lotProperty.dateTimeValue = moment(lotProperty.dateTimeValue);
              // }
              if (lotProperty.propertyTypeFormatId === DateTimeTypeEnum.LONG_DATE || lotProperty.propertyTypeFormatId === DateTimeTypeEnum.SHORT_DATE) {

                var date = new Date(moment(lotProperty.dateTimeValue).toDate().getFullYear(), moment(lotProperty.dateTimeValue).toDate().getMonth(), moment(lotProperty.dateTimeValue).toDate().getDate(), 12, 0);
                lotProperty.dateTimeValue = moment.utc(date);
              }
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypeEnum.TEXT && lotProperty.translatable) {
              const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
              if (field) {
                lotProperty.stringValue = field.data;
              }
            }
          });
        });
      }
    });
  }

  addLot(): boolean {
    if (!this.lotAddingInProgress) {
      this.lotAddingInProgress = true;
      this.spinner.show();

      this.parseDatesBeforeSave();

      this.selectedLotProperty = null;

      if (this.lot.inEditMode && !this.addNew) {
        this.dataService.edit(this.catalog.auctionId, this.lot, true).subscribe(res => {
          this.lot = JSON.parse(JSON.stringify(res));
          res.lotId = 0;
          this.newLot = JSON.parse(JSON.stringify(res));

          this.lotAddingInProgress = false;
          this.masterDetailsLotId = this.lotId;
          this.spinner.hide();
          this.lotForm.reset();
          this.updateAllNumberOfPropsProcessed = 0;
          this.updateAllAppliedToAll = false;

          this.parseLotProperties();
          this.getPresetCookieProperty();
          this.setDropdownValues();

          this.focusNextElement();
        }, error => {
          this.errorService.show(error);
          this.lotAddingInProgress = false;
          this.spinner.hide();
          this.focusNextElement();
        });
        return true;
      } else {
        this.lot.lotId = 0;
        this.dataService.save(this.catalog.auctionId, this.lot, true).subscribe(res => {
          this.lot = JSON.parse(JSON.stringify(res));
          res.lotId = 0;
          this.newLot = JSON.parse(JSON.stringify(res));

          this.lotAddingInProgress = false;
          this.masterDetailsLotId = this.lot.lotId;
          this.spinner.hide();
          this.lotForm.reset();
          this.updateAllNumberOfPropsProcessed = 0;
          this.updateAllAppliedToAll = false;

          this.parseLotProperties();
          this.getPresetCookieProperty();
          this.setDropdownValues();

          this.focusNextElement();
        }, error => {
          this.errorService.show(error);
          this.lotAddingInProgress = false;
          this.spinner.hide();

          this.focusNextElement();
        });
        return true;
      }
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  // private getFocusableFields() {
  //   this.fields = document.getElementsByClassName('focusable');
  // }

  // elementFocus(elementIndex: number) {
  //   this.fieldIndex = elementIndex;
  // }

  elementBlur(lotProperty: LotProperty, element: NgForm) {
    // this.enforceRuleSet();
    // this.validateInputs();
    //this.validate(lotProperty, element);
  }

  // tslint:disable:no-magic-numbers
  validate(prop: LotProperty) {
    let numericValue: number;
    let textValue: string;
    switch (prop.propertyTypeId) {
      case ProductPropertyTypeEnum.NUMBER: {
        numericValue = prop.intValue;
        break;
      }
      case ProductPropertyTypeEnum.DECIMAL: {
        let multiplier = this.getMultiplier(prop.propertyTypeFormatId);

        if (multiplier != null) {
          prop.decimalValue = Math.round(prop.decimalValue * multiplier) / multiplier;
        }
        numericValue = prop.decimalValue;
        break;
      }
      case ProductPropertyTypeEnum.TEXT: {
        textValue = prop.stringValue;
        break;
      }
      default: {
        break;
      }
    }
    // tslint:enable:no-magic-numbers

    //const elementName = element.name;
    //if (!isNaN(numericValue)) {
    //  if ((prop.minValue != null && numericValue < prop.minValue) || (prop.maxValue != null && numericValue > prop.maxValue)) {
    //    this.lotForm.controls[elementName].setErrors({ 'valid': false });
    //  }
    //} else if (textValue) {
    //  if (prop.maxLength && textValue.length > prop.maxLength) {
    //    this.lotForm.controls[elementName].setErrors({ 'valid': false });
    //  }
    //}
  }

  startsWithSearchFn(term: string, item: any) {
    let label = item["label"].toLocaleLowerCase();
    return label.toLocaleLowerCase().indexOf(term) == 0;
  }

  clear() {
    this.propertyValue = null;
    this.masterDataFilter = '';
    this.filterMasterData();
    this.filterSuggestedValues();

    // Force clearing property value
    switch (this.selectedLotProperty.propertyTypeId) {
      case ProductPropertyTypeEnum.NUMBER:
        this.selectedLotProperty.intValue = null;
        break;
      case ProductPropertyTypeEnum.DECIMAL:
        this.selectedLotProperty.decimalValue = null;
        break;
      case ProductPropertyTypeEnum.TEXT:
        this.selectedLotProperty.stringValue = null;
        break;
      case ProductPropertyTypeEnum.DATE:
        this.selectedLotProperty.dateTimeValue = null;
        break;
      case ProductPropertyTypeEnum.URL:
        this.selectedLotProperty.urlValue = null;
        break;
      case ProductPropertyTypeEnum.MASTER_DATA:
        {
          this.selectedLotProperty.masterDataListRowId = null;
          this.masterDataFilter = this.getMasterDataLabelForLotProperty(this.selectedLotProperty);
        }
        break;
      default:
        break;
    }

    this.selectedLotProperty.changed = true;

    let el = <HTMLInputElement>this.document.getElementById('input-filter');
    if (el) {
      el.focus();
      el.select();
    }
  }

  back() {
    // Return back to supply editor or supply forecast editor
    if (this.isForecastCatalog) {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId],
        { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
    }
    else {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId],
        { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
    }
  }

  masterDataValues: Array<any> = [];
  selectedProductPropertyId: number = 0;
  masterDataFilter: string = '';
  propertyValue: any = null;

  getMasterDataLabelForLotProperty(lotProperty: LotProperty) {
    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === lotProperty.productPropertyId);
    if (masterDataDropdown) {
      const value = masterDataDropdown.values.find(_ => _.value === lotProperty.masterDataListRowId);
      if (value) {
        return value.label;
      }
      else
        return '';
    }

    return '';
  }

  selectProperty(property: LotProperty, event: Event = null) {
    if (event)
      event.stopPropagation();

    if (!property || property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled || property.isWeighingScaleOn)
      return;

    this.masterDataFilter = '';
    this.propertyValue = null;

    this.selectedLotProperty = property;
    this.selectedProductPropertyId = property.productPropertyId;
    this.masterDataFilter = '';

    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === property.productPropertyId);
    if (masterDataDropdown) {
      this.masterDataValues = masterDataDropdown.values;
    }
    else {
      this.masterDataValues = [];
    }

    const suggestedValuesDropdown = this.suggestedValuesDropdowns.find(sv => sv.productPropertyId === property.productPropertyId);
    if (suggestedValuesDropdown) {
      this.suggestedValues = suggestedValuesDropdown.values;
      this.suggestedValues.sort((v1, v2) => v1 > v2 ? 1 : v1 < v2 ? -1 : 0);
    }
    else {
      this.suggestedValues = [];
    }

    switch (property.propertyTypeId) {
      case ProductPropertyTypeEnum.NUMBER:
        this.propertyValue = property.intValue;
        break;
      case ProductPropertyTypeEnum.DECIMAL:
        this.propertyValue = property.decimalValue;
        break;
      case ProductPropertyTypeEnum.TEXT:
        {
          if (!property.translatable)
            this.propertyValue = property.stringValue;
          else
            this.propertyValue = this.getTranslation(property.stringValue);
        }
        break;
      case ProductPropertyTypeEnum.MASTER_DATA:
        {
          this.propertyValue = property.masterDataListRowId;
          this.masterDataFilter = this.getMasterDataLabelForLotProperty(property);
        }
        break;
      case ProductPropertyTypeEnum.DATE:
        this.openDateBox(property);
        break;
      case ProductPropertyTypeEnum.URL:
        this.propertyValue = property.urlValue;
        break;
      default:
        break;
    }

    if (property.productPropertyEditorSelectionDetails.focusEditBox) {
      setTimeout(() => {
        let el = <HTMLInputElement>this.document.getElementById('input-filter');
        if (el) {
          el.focus();
          el.select();
        }
      }, 70);
    }
  }

  openDateBox(property: LotProperty) {
    if (this.dxDateBoxComponents != null) {
      let propertyDateBoxesForProperty = this.dxDateBoxComponents.filter((dateBox: DxDateBoxComponent) => {
        let element = <ElementRef>(<any>dateBox).element;
        let name = element.nativeElement.attributes["name"];
        return name.value == property.productPropertyId;
      });
      if (propertyDateBoxesForProperty != null && propertyDateBoxesForProperty.length > 0) {
        propertyDateBoxesForProperty[0].instance.open();
      }
    }
  }

  checkForOSVersion() {
    if (navigator.appVersion.toLowerCase().indexOf('win') !== -1) {
      this.isWindowsOS = true;
    }
  }

  async openCamera() {
    if (!this.isWindowsOS) {
      return;
    }
    this.spinner.show();
    this.isCaptured = false;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: 'environment'
          }
        });
        this.camera.nativeElement.srcObject = stream;
        this.camera.nativeElement.play();
        this.videoError = null;
      } catch (e) {
        this.videoError = e;
      }
    }
  }

  onVideoLoad(e) {
    this.spinner.hide();
    this.popUp.instance.show();
    this.popUpShowed = true;
  }

  closePopUp() {
    this.popUpShowed = false;
  }

  takePhoto() {
    this.imageTaken.nativeElement.width = this.camera.nativeElement.videoWidth;
    this.imageTaken.nativeElement.height = this.camera.nativeElement.videoHeight;
    this.imageTaken.nativeElement.getContext('2d').drawImage(this.camera.nativeElement, 0, 0);
    this.isCaptured = true;

    let dataURI = this.imageTaken.nativeElement.toDataURL();
    let blob = this.dataURItoBlob(dataURI);

    setTimeout(() => {
      this.uploadBlobToAzure(blob);
      this.closePopUp();
    }, TIMEOUT);
  }

  onImageChanged(e) {
    const file = <File>e.srcElement.files[0];
    this.uploadBlobToAzure(file);
  }

  uploadBlobToAzure(file: any) {
    this.spinner.show();

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      this.dataService.addLotImage(this.catalog.auctionId, formData).subscribe((imgSrc: any) => {
        if (imgSrc.length !== 0) {
          this.spinner.hide();
        }

        this.selectedLotProperty.displayUrlImageValue = imgSrc + '-thumbnail';
        this.selectedLotProperty.displayUrlTextValue = this.selectedLotProperty.urlValue = imgSrc;

        this.selectedLotProperty.valueChanged = true;
        this.selectedLotProperty.changed = true;
        this.onPropertyChanged(this.selectedLotProperty);
      });
    } else {
      this.spinner.hide();
    }
  }

  dataURItoBlob(dataURI) {

    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  setMasterDataValue(value: any) {
    this.selectedLotProperty.masterDataListRowId = value;
    this.masterDataFilter = this.getMasterDataLabelForLotProperty(this.selectedLotProperty);

    this.selectedLotProperty.changed = true;

    this.validate(this.selectedLotProperty);
    this.onPropertyChanged(this.selectedLotProperty);

    //this.focusNextElement();
  }

  filterMasterData() {
    this.propertyValue = null;

    const masterDataDropdown = this.dropdownsData.find(dd => dd.productPropertyId === this.selectedLotProperty.productPropertyId);
    if (masterDataDropdown) {
      if (this.selectedLotProperty.productPropertyEditorSelectionDetails.searchByStartsWith) {
        this.masterDataValues = masterDataDropdown.values.filter(_ => _.label.toLowerCase().startsWith(this.masterDataFilter ? this.masterDataFilter.trim().toLowerCase() : ''));
      }
      else {
        this.masterDataValues = masterDataDropdown.values.filter(_ => _.label.toLowerCase().includes(this.masterDataFilter ? this.masterDataFilter.trim().toLowerCase() : ''));
      }
    }
    else {
      this.masterDataValues = [];
    }
  }

  setSuggestedValue(value: any) {
    switch (this.selectedLotProperty.propertyTypeId) {
      case ProductPropertyTypeEnum.NUMBER:
        this.selectedLotProperty.intValue = value;
        break;
      case ProductPropertyTypeEnum.DECIMAL:
        this.selectedLotProperty.decimalValue = value;
        break;
      case ProductPropertyTypeEnum.TEXT:
        this.selectedLotProperty.stringValue = value;
        break;
      case ProductPropertyTypeEnum.DATE:
        this.selectedLotProperty.dateTimeValue = value;
        break;
      default:
        break;
    }

    this.selectedLotProperty.changed = true;
    this.propertyValue = value;

    this.validate(this.selectedLotProperty);
    this.onPropertyChanged(this.selectedLotProperty);

    //this.focusNextElement();
  }

  filterSuggestedValues() {
    const suggestedValuesDropdown = this.suggestedValuesDropdowns.find(sv => sv.productPropertyId === this.selectedLotProperty.productPropertyId);
    if (suggestedValuesDropdown) {
      this.suggestedValues = suggestedValuesDropdown.values.filter(_ => _.toString().toLowerCase().startsWith(this.propertyValue ? this.propertyValue.toString().trim().toLowerCase() : ''));
      this.suggestedValues.sort((v1, v2) => v1 > v2 ? 1 : v1 < v2 ? -1 : 0);
    }
    else {
      this.suggestedValues = [];
    }
  }

  @HostListener('window:keydown', ['$event'])
  private changeFocus(event: any) { // tslint:disable-line:no-unused-variable
    if (event.keyCode === ENTER_KEY) {
      event.preventDefault();

      if (event.target.id === 'input-filter') {
        this.confirmSetValue();
      }
    }
  }

  focusNextElement() {
    let index = -1;

    let properties = this.lotProperties.filter(_ => _.productPropertyEditorSelectionDetails.isVisible &&
      !_.productPropertyEditorSelectionDetails.isReadOnly &&
      !_.readOnlyOnceSelected &&
      !_.manuallyDisabled);

    if (this.selectedLotProperty) {
      let prop = properties.find(_ => _.productPropertyId === this.selectedLotProperty.productPropertyId);

      if (prop)
        index = properties.indexOf(prop);
      else
        index = -1;
    }

    if (index === -1 || index === properties.length - 1) {
      index = 0;
    } else {
      index++;
    }

    for (var i = index; i < properties.length; i++) {
      let nextProperty = properties[i];
      if (nextProperty) {
        let tabIndex = this.getTabIndex(nextProperty);
        if (tabIndex === 0) {
          this.selectProperty(nextProperty);
          break;
        }
      }
    }
  }

  confirmSetValue() {
    if (!this.propertyValue && this.selectedLotProperty.propertyTypeId !== ProductPropertyTypeEnum.MASTER_DATA) {
      this.focusNextElement();
      return;
    }

    switch (this.selectedLotProperty.propertyTypeId) {
      case ProductPropertyTypeEnum.NUMBER:
        this.selectedLotProperty.intValue = this.propertyValue;
        break;
      case ProductPropertyTypeEnum.DECIMAL:
        this.selectedLotProperty.decimalValue = this.propertyValue;
        break;
      case ProductPropertyTypeEnum.TEXT:
        if (!this.selectedLotProperty.translatable)
          this.selectedLotProperty.stringValue = this.propertyValue;
        else {
          // TODO implement translatable
          //this.selectedLotProperty.stringValue = this.propertyValue;
        }
        break;
      case ProductPropertyTypeEnum.MASTER_DATA: // MasterData value
        {
          if (this.propertyValue) {
            this.selectedLotProperty.masterDataListRowId = this.propertyValue;
          }
          else {
            // Set first available master data value
            let md = this.masterDataValues[0];
            if (md) {
              this.propertyValue = md.value;
              this.selectedLotProperty.masterDataListRowId = md.value;
              this.masterDataFilter = this.getMasterDataLabelForLotProperty(this.selectedLotProperty);
            }
            else
              return;
          }
        }
        break;
      case ProductPropertyTypeEnum.URL:
        this.selectedLotProperty.urlValue = this.propertyValue;
        break;
      default:
        return;
    }

    this.selectedLotProperty.changed = true;

    this.validate(this.selectedLotProperty);
    this.onPropertyChanged(this.selectedLotProperty);


    //this.focusNextElement();
  }

  checkRequiredData() {
    let isValid = true;
    let properties = this.lotProperties.filter(_ => _.productPropertyEditorSelectionDetails.isVisible &&
      !_.productPropertyEditorSelectionDetails.isReadOnly &&
      !_.readOnlyOnceSelected &&
      !_.manuallyDisabled);

    properties.forEach(property => {
      // Check data if property is required
      if (property.productPropertyEditorSelectionDetails.required) {
        switch (property.propertyTypeId) {
          case ProductPropertyTypeEnum.NUMBER:
            {
              if (property.intValue === null || property.intValue === undefined) {
                property.displayRequiredWarning = true;
                isValid = false;
              }
              else
                property.displayRequiredWarning = false;
            }
            break;
          case ProductPropertyTypeEnum.DECIMAL:
            {
              if (property.decimalValue === null || property.decimalValue === undefined) {
                property.displayRequiredWarning = true;
                isValid = false;
              }
              else
                property.displayRequiredWarning = false;
            }
            break;
          case ProductPropertyTypeEnum.DATE:
            {
              if (property.dateTimeValue === null || property.dateTimeValue === undefined || !moment(property.dateTimeValue).isValid()) {
                property.displayRequiredWarning = true;
                isValid = false;
              }
              else
                property.displayRequiredWarning = false;
            }
            break;
          case ProductPropertyTypeEnum.TEXT:
            {
              if (!this.selectedLotProperty.translatable) {
                if (property.stringValue === null || property.stringValue === undefined || property.stringValue === '') {
                  property.displayRequiredWarning = true;
                  isValid = false;
                }
                else
                  property.displayRequiredWarning = false;
              }
              else {
                // TODO implement translatable
                //this.selectedLotProperty.stringValue = this.propertyValue;
              }

            }
            break;
          case ProductPropertyTypeEnum.MASTER_DATA: // MasterData value
            {
              if (property.masterDataListRowId === 0 || property.masterDataListRowId === null || property.masterDataListRowId === undefined) {
                property.displayRequiredWarning = true;
                isValid = false;
              }
              else
                property.displayRequiredWarning = false;
            }
            break;
          default:
            return;
        }
      }
    });

    return isValid;
  }

  clearImageProperty() {
    this.selectedLotProperty.displayUrlTextValue = null;
    this.selectedLotProperty.displayUrlImageValue = null;

    this.selectedLotProperty.valueChanged = true;
    this.selectedLotProperty.changed = true;
    this.onPropertyChanged(this.selectedLotProperty);
  }

  switchEditorMode() {
    if (this.lot.lotId !== 0) {
      if (this.isForecastCatalog) {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId], { queryParams: { masterDetailsLotId: this.masterDetailsLotId, lotId: this.lot.lotId } });
      } else {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId], { queryParams: { masterDetailsLotId: this.masterDetailsLotId, lotId: this.lot.lotId } });
      }
    } else {
      if (this.isForecastCatalog) {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
      } else {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
      }
    }
    this.cookies.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
    this.isTouchMode = false;
  }

  changeWeighingScaleState(productProperty: any, ev: any) {
    if (ev.event) {
      if (ev.value) {
        let weighingScaleConnection = this.weighingScaleConnections.find(_ => _.productProperties.includes(productProperty.productPropertyId));
        if (weighingScaleConnection && weighingScaleConnection.weight)
          productProperty.decimalValue = weighingScaleConnection.weight;

        let weighingScaleProperties = this.lotProperties.filter(_ => _.productPropertyEditorSelectionDetails.useWeighingScale);
        weighingScaleProperties.forEach(prop => {
          if (prop.productPropertyId === productProperty.productPropertyId) {
            prop.isWeighingScaleOn = true;
            if (!this.lotId || this.addNew)
              this.cookies.set(Cookies.WEIGHING_SCALE_PROPERTY_ID, productProperty.productPropertyId);
            if (weighingScaleConnection.stable) {
              this.onPropertyChanged(prop)
            }
          } else {
            prop.isWeighingScaleOn = false;
          }
        })
      } else {
        productProperty.isWeighingScaleOn = false;
        if (!this.lotId || this.addNew)
          this.cookies.set(Cookies.WEIGHING_SCALE_PROPERTY_ID, '0');
      }
    }
  }

  setWeighingScaleState(productProperty: any) {
    if (!this.lotId || this.addNew) {
      let weighingScalePropertyId = this.cookies.get(Cookies.WEIGHING_SCALE_PROPERTY_ID);
      if (weighingScalePropertyId === "") {
        weighingScalePropertyId = productProperty.productPropertyId;
        this.cookies.set(Cookies.WEIGHING_SCALE_PROPERTY_ID, productProperty.productPropertyId);
      }
      if (weighingScalePropertyId && parseInt(weighingScalePropertyId) === productProperty.productPropertyId)
        productProperty.isWeighingScaleOn = true;
    } else {
      productProperty.isWeighingScaleOn = false;
    }
  }

  add() { }
}
