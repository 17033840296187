<div class="product-functionkeys-action-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
    dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
    <dx-popup #addProductFunctionkeyAction [width]="600" [showTitle]="true" title="{{ modalTitle }}"
        [dragEnabled]="false" [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
        (onShown)="onFieldDataChanged($event)">
        <dx-scroll-view width="100%" height="100%">
            <div class="au-dx-popup-padder">
                <dx-form #productFunctionkeysActionForm id="productFunctionkeysActionForm"
                    validationGroup="productFunctionkeysActionData" [showValidationSummary]="false"
                    (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model"
                    [minColWidth]="500">

                    <dxi-item itemType="group">
                        <dxi-item dataField="name">
                            <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required"
                                message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="action" editorType="dxSelectBox"
                            [editorOptions]="{ items: productFunctionKeysActionEnum, valueExpr:'value', displayExpr:translateSelectBoxes }">
                            <dxo-label text="{{ 'PRODUCT.FUNCTIONKEYS_ACTION.ACTION' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required" message="{{ 'PRODUCT.FUNCTIONKEYS_ACTION.ACTION' | translate }}">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="expression" editorType="dxTextArea">
                            <dxo-label text="{{ 'PRODUCT.FUNCTIONKEYS_ACTION.EXPRESSION' | translate }}"></dxo-label>
                            <dxi-validation-rule type="required"
                                message="{{ 'PRODUCT.FUNCTIONKEYS_ACTION.EXPRESSION' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="usableDuringClockRun" editorType="dxCheckBox">
                            <dxo-label
                                text="{{ 'PRODUCT.FUNCTIONKEYS_ACTION.USABLE_DURING_CLOCK_RUN' | translate }}"></dxo-label>
                        </dxi-item>
                    </dxi-item>
                </dx-form>
                <button type="submit" class="btn btn-primary" (click)="onSubmit();"
                    name="btnProductFunctionkeysActionSubmit" disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
                <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
            </div>
        </dx-scroll-view>
    </dx-popup>
</div>