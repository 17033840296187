import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../models/application-settings';
import { Clock, CoinCodeMode } from '../models/clock';

// services
import { WebApiService } from '../services/web-api.service';

@Injectable()
export class ClockService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  // For fetching clocks on buyer level
  getClocks(): Observable<Array<Clock>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'clock');
  }

  getAllClusterClocks(auctionClusterId: number): Observable<Array<Clock>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/clock');
  }

  getClocksWithoutMasterData(auctionClusterId: number): Observable<Array<Clock>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/auction/clockwithoutmasterdata');
  }

  getAuctionClocks(auctionId: number): Observable<Array<Clock>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionId + '/clock');
  }

  getClock(auctionId: number, clockId: number): Observable<Clock> {
    return this.webApiService.get(this.apiPath + '/auction/' + auctionId + '/clock', clockId);
  }

  getCoinCodes(auctionId: number):  Observable<Array<CoinCodeMode>> {
    return this.webApiService.getList(this.apiPath + '/auction/' + auctionId + '/clock/coincodes');
  }

  save(auctionId: number, clock: Clock): Observable<any> {
    return this.webApiService.save<Clock>(this.apiPath + '/auction/' + auctionId + '/clock', clock);
  }

  demo(auctionId: number, clockId: number): Observable<any> {
    return this.webApiService.save<Clock>(this.apiPath + '/auction/' + auctionId + '/clock/' + clockId + '/demo', null);
  }

  edit(auctionId: number, clock: Clock): Observable<any> {
    return this.webApiService.edit<Clock>(this.apiPath + '/auction/' + auctionId + '/clock', clock.clockId, clock);
  }

  delete(auctionId: number, clockId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/auction/' + auctionId + '/clock', clockId);
  }

  exportClock(auctionClusterId: number, clockId: number): Observable<any> {
    return this.webApiService.downloadPost(this.apiPath + '/' + auctionClusterId + '/auction/clock/' + clockId + '/export', null);
  }

  exportClockResults(auctionClusterId: number, clockId: number): Observable<any> {
    return this.webApiService.downloadPost(this.apiPath + '/' + auctionClusterId + '/auction/clock/' + clockId + '/export-results', null);
  }
}
