<div class="item-list-component prebid-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #prebitOnLotList
            width="98%"
            [showTitle]="true"
            title="{{ 'PREBID_ON_PRODUCT.TITLE' | translate }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="visible">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <div class="card">
          <div class="card-block">
            <br /><br />
                  <dx-form #buyerPrebidOnProductForm
                           id="buyerPrebidOnProductForm"
                           [showValidationSummary]="false"
                           [(formData)]="model"
                           colCount="auto"
                           [width]="500"
                           (onFieldDataChanged)="checkIsSecondRestrictionLotPropertyFilled()">
                    <div *dxTemplate="let data of 'translatableField'">
                      <translatable-field [isDevExpress]="true" [isLabelHidden]="true" [caption]="''" [(data)]="data.component.option('formData')[data.dataField]"></translatable-field>
                    </div>
                    <div *dxTemplate="let data of 'fullDateTemplate'">
                      <full-date-time-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></full-date-time-component>
                    </div>
                    <div *dxTemplate="let data of 'longDateTemplate'">
                      <long-date-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></long-date-component>
                    </div>
                    <div *dxTemplate="let data of 'longTimeTemplate'">
                      <long-time-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></long-time-component>
                    </div>
                    <div *dxTemplate="let data of 'shortDateTemplate'">
                      <short-date-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></short-date-component>
                    </div>
                    <div *dxTemplate="let data of 'shortTimeTemplate'">
                      <short-time-component [data]="dateValue(data)" (modelChanged)="dateChanged(data, $event)"></short-time-component>
                    </div>
                    <div *dxTemplate="let data of 'masterdataTemplate'">
                      <masterdata-dropdown [auctionClusterId]="auctionClusterId"
                                           [listId]="data.name"
                                           [(selectedRowId)]="data.component.option('formData')[data.dataField]"
                                           [filteredKeys]="masterDataDropdownsFiltered[data.name]"
                                           (onValueChanged)="onMasterDataValueChanged($event, data, field)">
                      </masterdata-dropdown>
                    </div>
                  </dx-form>
            <br /><br />
          </div>
        </div>

        <div class="grid-buttons">
          <button [disabled]="!secondRestrictionLotPropertyFilled" type="button" class="btn btn-primary" (click)="save();">{{ 'SHARED.SAVE' | translate }}</button>
          <dx-button hint="{{ 'SHARED.ADD' | translate }}"
                     icon="add"
                     (onClick)="initModelOnNew()"></dx-button>
        </div>
        <br /><br />
        <dx-data-grid #buyerPrebidOnProductGrid
                      id="printSection"
                      [rtlEnabled]="rtlEnabled"
                      [showBorders]="true"
                      (onInitialized)="initColumn($event)"
                      [allowColumnReordering]="true"
                      [hoverStateEnabled]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true">
          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-filter-panel [visible]="true"></dxo-filter-panel>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-pager [visible]="true"
                     [showPageSizeSelector]="true"
                     [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                     [showNavigationButtons]="true"
                     [showInfo]="true"
                     infoText="{2} {{ 'PREBID_ON_PRODUCT.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
          <dxi-column type="buttons" [width]="120" cellTemplate='buttonsTemplate'>
          </dxi-column>
          <div *dxTemplate="let d of 'buttonsTemplate'">
            <a (click)="edit(d.data.__item__.lotId, $event)" class="dx-link"><i title="{{ 'SHARED.EDIT' | translate }}" class="dx-icon-edit"></i></a>
            <a (click)="delete(d.data.__item__.lotId, $event)" class="dx-link"><i title="{{ 'SHARED.DELETE' | translate }}" class="dx-icon-trash"></i></a>
          </div>
        </dx-data-grid>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<confirmation-component #confirmation title="{{ 'PREBID_ON_PRODUCT.DELETE_PREBID_ON_PRODUCT_LOT' | translate }}"
                        message="{{ 'PREBID_ON_PRODUCT.DELETE_PREBID_ON_PRODUCT_LOT_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
