import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import { ICatalogService } from '../../../shared/interfaces/catalog';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Catalog } from '../../../shared/models/catalog';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class CatalogService implements ICatalogService {

  private apiPath: string;
  private buyerId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'buyerlevel';
  }

  setId(id: number){
    this.buyerId = id;
  }

  getAllSupplyCatalogs(): Observable<Array<Catalog>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/catalog`);
  }

  //ICatalogService

  getAllSupplyAndForecastCatalogsForReports(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(`${this.apiPath}/${this.buyerId}/auctioncluster/${auctionClusterId}/catalog/all`);
  }  
}
