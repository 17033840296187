<div class="buyer-supply-matrix-lots-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #matrixLotsList
            width="98%"
            height="500"
            [showTitle]="false"
            title=""
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-data-grid #buyerSupplierMatrixLotsGrid
                      id="printSection"
                      [rtlEnabled]="rtlEnabled"
                      [showBorders]="true"
                      (onInitialized)="initColumn($event)"
                      [allowColumnReordering]="true"
                      [hoverStateEnabled]="true"
                      [columnAutoWidth]="true"
                      (onCellPrepared)="editCellBackground($event)"
                      [rowAlternationEnabled]="true">
          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-filter-panel [visible]="true"></dxo-filter-panel>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-pager [visible]="true"
                     [showPageSizeSelector]="true"
                     [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                     [showNavigationButtons]="true"
                     [showInfo]="true"
                   infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
          <dxi-column type="buttons"
                      [width]="120"
                      cellTemplate='buttonsTemplate'
                      [fixed]="true"
                      fixedPosition="right">
          </dxi-column>
          <div *dxTemplate="let d of 'buttonsTemplate'">
          <a (click)="markLot(d.data.__item__, true, $event)" class="dx-link" *ngIf="!d.data.__item__.hasMark"><i title="{{ 'SUPPLY_MONITOR.MARK_LOT' | translate }}" class="dx-icon-unpin"></i></a>
          <a (click)="markLot(d.data.__item__, false, $event)" class="dx-link" *ngIf="d.data.__item__.hasMark"><i title="{{ 'SUPPLY_MONITOR.UNMARK_LOT' | translate }}" class="dx-icon-pin"></i></a>
          <a (click)="edit(d.data.__item__.id, $event)" class="dx-link" *ngIf="!d.data.__item__.prebid && !d.data.__item__.blockedBySecondRestriction"><i title="{{ 'SUPPLY_MONITOR.OPEN_PREBID' | translate }}" class="dx-icon-detailslayout"></i></a>
          <a (click)="edit(d.data.__item__.id, $event)" class="dx-link" *ngIf="d.data.__item__.prebid && !d.data.__item__.blockedBySecondRestriction"><i title="{{ 'SUPPLY_MONITOR.OPEN_PREBID' | translate }}" class="dx-icon-detailslayout solidIcon"></i></a>

          </div>
          <div *dxTemplate="let cell of 'cellTemplate'">
            <span [innerHtml]="cell.text | format | safeHtml"></span>
          </div>
          <div *dxTemplate="let data of 'imageCellTemplate'" class="image-column">
            <img *ngIf="doesImageCalculationExist(data.value)" class="thumbnail-image" [src]="getImage(data.value)" (click)="showImageCarrousel(data.value);" />
          </div>
        </dx-data-grid>
      <button type="button" class="btn btn-outline" (click)="onClose();">{{ 'SHARED.CLOSE' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>

<dx-popup #imageGallery [width]="'auto'" [height]="'auto'" [showTitle]="true"
title="{{ 'SUPPLY_MONITOR.SHOW_CARROUSEL' | translate }}" [dragEnabled]="false" [showCloseButton]="true"
          [(visible)]="popUpShowed" (onHiding)="closePopUp();">
  <div class="au-dx-popup-padder">
    <dx-gallery [dataSource]="carrouselData" [showIndicator]="carrouselData.length > 1" [showNavButtons]="true" [width]="'auto'" [height]="'auto'">
      <div *dxTemplate="let galleryItem of 'item'">
        <img (load)="openPopUp();" [src]="galleryItem.field_0" />
        <div>{{ getTranslation(galleryItem.field_1) }}</div>
      </div>
    </dx-gallery>
    </div>
</dx-popup>

<prebid-on-lot-component #prebid
                         (onClosed)="onPrebidClosed()"></prebid-on-lot-component>
<!-- <confirmation-component #confirmation title="{{ 'SUPPLY_MONITOR.DELETE_PREBID' | translate }}" message="{{ 'SUPPLY_MONITOR.DELETE_PREBID_MESSAGE' | translate }}" buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component> -->
