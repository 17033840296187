<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.ADD' | translate }}"
               icon="add"
               (onClick)="add()"></dx-button>
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}"
               icon="print"
               (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #ticketPrinterGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [dataSource]="items"
                [showBorders]="true"
                (onRowClick)="edit($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}"
                [customizeText]="translateField">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="routingSubKey" caption="{{ 'TICKET_PRINTER.ROUTINGSUBKEY' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="ticketType" caption="{{ 'SHARED.TYPE' | translate }}">
      <dxo-lookup [dataSource]="ticketTypes" displayExpr="name" valueExpr="value">
      </dxo-lookup>
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="enabled" caption="{{ 'SHARED.ENABLED' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="printerStatus" dataField="status" caption="{{ 'TICKET_PRINTER.STATUS' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" [width]="120">
      <dxi-button hint="{{ 'SHARED.EDIT' | translate }}"
                  icon="edit"
                  [onClick]="edit"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}"
                  icon="trash"
                  [onClick]="deleteItem"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'printerStatus'">
      <dx-button stylingMode="text" icon="isnotblank" *ngIf="isDisconnected(data.value)" id="isDisconnectedIcon" hint="{{translateStatus(data.value)}}"></dx-button>
      <dx-button stylingMode="text" icon="isnotblank" *ngIf="isConnected(data.value)" id="isConnectedIcon" hint="{{translateStatus(data.value)}}"></dx-button>
      <dx-button stylingMode="text" icon="isnotblank" *ngIf="isError(data.value)" id="isErrorIcon" hint="{{translateStatus(data.value)}}"></dx-button>
    </div>
  </dx-data-grid>
</div>

<ticket-printer-component #details
                          (onClosed)="onDetailsComponentClosed($event)"></ticket-printer-component>
<confirmation-component #confirmation title="{{ 'TICKET_PRINTER.DELETE_TICKET_PRINTER' | translate }}"
                        message="{{ 'TICKET_PRINTER.DELETE_TICKET_PRINTER_MESSAGE' | translate }}"
                        buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
