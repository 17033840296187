import { Component, OnInit, OnDestroy, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// components
import { DxPopupComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData } from '../../shared/models/master-data';
import { ProductPropertyType } from '../../shared/models/product';

import { LookupTable, LookupTableRow, LookupTableValue } from '../shared/models/lookup-table';

// services
import { LanguageService } from '../../shared/services/language.service';

import { LookupTableService } from '../shared/services/lookup-table.service';


enum ValidatoinErrorsCode {
  VALID = 0,
  DUPLICATE_ROW = 1,
  INVALID_PERIOD = 2
}

@Component({
  selector: 'lookup-table-row-editor-component',
  templateUrl: 'lookup-table-row-editor.component.html',
  styleUrls: ['./lookup-table-row-editor.component.scss']
})
export class LookupTableRowEditorComponent extends ItemDetailsComponent<LookupTableRow> implements OnInit, OnDestroy {
  auctionClusterId: number;
  lookupTableRowId: number;
  lookupTableItem: LookupTable;
  languages: Array<Language> = [];
  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDataLists: Array<MasterData> = [];
  isDefaultType = false;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild(DxPopupComponent) popup: DxPopupComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: LookupTableService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.initializeEmptyModel();
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.setTranslations('LOOKUP_TABLE');
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  handleValueChange(e: any) {
    let btn = <HTMLInputElement>document.getElementById("btnLookupTableRowSubmit");
    let disabled = false;
    for (let i = 0; i < this.model.lookupTableValues.length; i++) {
      if (!this.model.lookupTableValues[i].masterDataListRowId) {
        disabled = true;
        break;
      }
    }

    if (this.lookupTableItem.resultPropertyTypeId === 1 && !this.model.resultIntValue) {
      if (this.model.resultIntValue === 0)
        disabled = false;
      else
        disabled = true;
    } else if (this.lookupTableItem.resultPropertyTypeId === 2 && !this.model.resultDecimalValue) {
      if (this.model.resultDecimalValue === 0)
        disabled = false;
      else
        disabled = true;
    } else if (this.lookupTableItem.resultPropertyTypeId === 3 && !this.model.resultStringValue) {
      disabled = true;
    } else if (this.lookupTableItem.resultPropertyTypeId === 4 && !this.model.resultDateTimeValue) {
      disabled = true;
    }

    btn.disabled = disabled;
  }


  initializeEmptyModel() {
    this.model = new LookupTableRow();
    this.model.isDefault = false;
    this.model.displayUrlImageValue = '../../../assets/images/image_placeholder.jpg';
    this.model.displayUrlTextValue = null;

    if (this.lookupTableItem) {
      this.lookupTableItem.lookupTableFields.forEach(f => {
        const newValue = new LookupTableValue();
        newValue.lookupTableFieldId = f.lookupTableFieldId;
        newValue.masterDataListId = f.masterDataListId;
        this.model.lookupTableValues.push(newValue);
      });
    }
  }

  open(isEdit: boolean, lookupTable: LookupTable, lookupTableRowId: number,
    masterDataLists: Array<MasterData>, productPropertyTypes: Array<ProductPropertyType>, languages: Array<Language>) {
    this.lookupTableItem = lookupTable;
    this.languages = languages;
    this.masterDataLists = masterDataLists;
    this.productPropertyTypes = productPropertyTypes;

    this.isDefaultType = lookupTable.resultPropertyTypeId != null || lookupTable.resultMasterDataListId != null;

    // EDIT
    if (isEdit) {
      this.dataService.getRow(this.auctionClusterId, lookupTableRowId)
        .subscribe(res => {
          this.model = res;
          this.model.lookupTableValues = JSON.parse(JSON.stringify(res.lookupTableValues));
          this.model.lookupTableValues.map(lt => lt.masterDataListId = lt.masterDataListRow.masterDataListId);
          this.model.lookupTableId = lookupTable.lookupTableId;

          if (this.model.resultUrlValue) {
            this.model.displayUrlImageValue = this.model.displayUrlTextValue = this.model.resultUrlValue;
          } else {
            this.model.displayUrlImageValue = '../../../assets/images/image_placeholder.jpg';
            this.model.displayUrlTextValue = null;
          }
          this.isEditMode = true;

          this.isOpened = true;
        });
      // NEW
    } else {
      this.initializeEmptyModel();
      this.model.lookupTableId = lookupTable.lookupTableId;
      this.isEditMode = false;

      this.isOpened = true;
    }
  }

  getResultProperyName(propertyId: number) {
    let propertyName = '';

    this.productPropertyTypes.forEach(p => {
      if (p.propertyTypeId === propertyId) {
        propertyName = p.name;
      }
    });

    return propertyName;
  }

  getFieldName(value: LookupTableValue) {
    let fieldName = '';

    const fields = this.lookupTableItem.lookupTableFields.filter(f => {
      return f.lookupTableFieldId === value.lookupTableFieldId;
    });

    if (fields.length > 0) {
      const field = fields[0];

      this.masterDataLists.forEach(md => {
        if (md.masterDataListId === field.masterDataListId) {
          fieldName = md.name;
        }
      });
    }
    return this.getTranslation(fieldName);
  }

  getDefaultFieldName() {
    let fieldName = this.translations.ROWS.RESULT_COLUMN;

    if (this.lookupTableItem.resultMasterDataListId) {
      const md = this.masterDataLists.find(m => m.masterDataListId === this.lookupTableItem.resultMasterDataListId);
      if (md) {
        fieldName = `${this.getTranslation(md.name)} (${fieldName})`;
      }
    } else {
      this.productPropertyTypes.forEach(p => {
        if (p.propertyTypeId === this.lookupTableItem.resultPropertyTypeId
          || this.lookupTableItem.resultMasterDataListId && p.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
          fieldName = `${p.name} (${fieldName})`;
        }
      });
    }


    return fieldName;
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getPlainText(value: string) {
    return value.replace(/<[^>]*>/g, '');
  }

  validateModel() {
    let allSame = false;

    if (this.lookupTableItem.hasPeriodFilter &&
      (!this.model.periodFrom && !this.model.periodTill) || this.model.periodFrom > this.model.periodTill) {
      return ValidatoinErrorsCode.INVALID_PERIOD;
    }

    if (this.lookupTableItem.hasPeriodFilter) {
      return ValidatoinErrorsCode.VALID;
    }

    for (let i = 0; i < this.lookupTableItem.lookupTableRows.length; i += 1) {
      if (this.lookupTableItem.lookupTableRows[i].lookupTableRowId !== this.model.lookupTableRowId) {
        allSame = true;

        for (let j = 0; j < this.model.lookupTableValues.length; j += 1) {
          const rowValues = this.lookupTableItem.lookupTableRows[i].lookupTableValues.filter(rv => {
            return rv.lookupTableFieldId === this.model.lookupTableValues[j].lookupTableFieldId;
          });

          if (rowValues.length > 0) {
            const rowValue = rowValues[0];

            if (rowValue.lookupTableFilterValueId &&
              rowValue.masterDataListRowId !== this.model.lookupTableValues[j].masterDataListRowId
            ) {
              allSame = false;
              break;
            }
          }
        }

        //all masterdata the same, check results
        if (allSame) {
          //allow filter types with all same filters, but different results
          if (this.lookupTableItem.lookupTableType === 0 && (
            this.model.resultIntValue == this.lookupTableItem.lookupTableRows[i].resultIntValue &&
            this.model.resultDecimalValue == this.lookupTableItem.lookupTableRows[i].resultDecimalValue &&
            this.model.resultDateTimeValue == this.lookupTableItem.lookupTableRows[i].resultDateTimeValue &&
            this.model.resultBooleanValue == this.lookupTableItem.lookupTableRows[i].resultBooleanValue &&
            this.model.resultStringValue == this.lookupTableItem.lookupTableRows[i].resultStringValue &&
            this.model.resultMasterDataListRowId == this.lookupTableItem.lookupTableRows[i].resultMasterDataListRowId &&
            this.model.resultUrlValue == this.lookupTableItem.lookupTableRows[i].resultUrlValue)) {
            return ValidatoinErrorsCode.DUPLICATE_ROW;
          }
          else if (this.lookupTableItem.lookupTableType === 1) {
            return ValidatoinErrorsCode.DUPLICATE_ROW;
          }
        }
      }

      /*if (allSame) {
        return ValidatoinErrorsCode.DUPLICATE_ROW;
      }*/
    }

    return ValidatoinErrorsCode.VALID;
  }

  onImageChanged(event: any) {
    this.spinner.show();
    const file = <File>event.srcElement.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      this.dataService.addLookUpImage(this.auctionClusterId, formData).subscribe((imgSrc: any) => {

        if (imgSrc.length !== 0) {
          this.spinner.hide();
        }
        this.model.resultUrlValue = this.model.displayUrlTextValue = imgSrc;
        this.model.displayUrlImageValue = imgSrc + '-thumbnail';
      });
    } else {
      this.spinner.hide();
    }
  }

  clearImage() {
    this.model.resultUrlValue = null;
    this.model.displayUrlImageValue = '../../../assets/images/image_placeholder.jpg';
    this.model.displayUrlTextValue = null;
  }

  save() {
    this.errorMessage = null;
    if (this.validateModel() === ValidatoinErrorsCode.DUPLICATE_ROW) {
      this.errorService.show(this.errorService.translations.DUPLICATE_ROW_ERROR);
      return;
    } else if (this.validateModel() === ValidatoinErrorsCode.INVALID_PERIOD) {
      this.errorService.show(this.errorService.translations.INVALID_DATES_ERROR);
      return;
    }

    if (this.isEditMode) {
      this.dataService.editRow(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.initializeEmptyModel();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
        },
          error => {
            this.errorService.show(this.errorMessage);
            this.spinner.hide();
          });
    } else {
      this.dataService.saveRow(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.initializeEmptyModel();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
        },
          error => {
            this.errorService.show(this.errorMessage);
            this.spinner.hide();
          });
    }
  }
}
