<div class="zones-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <div class="row">
      <div class="filter-column">
        <label>{{ 'ZONES.SHOW_ZONES_FOR_LOCATION' | translate }}</label>
        <dx-select-box [items]="locations"
                       displayExpr="name"
                       valueExpr="locationId"
                       [(value)]="selectedLocation"
                       [rtlEnabled]="rtlEnabled"
                       [searchEnabled]="true"
                       [showClearButton]="true"
                       (onValueChanged)="getSelectedZones()"></dx-select-box>
      </div>
    </div>
    <div class="grid-buttons">
      <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
      <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
    </div>
    <br />
    <dx-data-grid #zonesGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items" (onRowClick)="edit($event)"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isActive" caption="{{ 'SHARED.ACTIVE' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="locationName" caption="{{ 'SHARED.LOCATION' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="typeName" caption="{{ 'SHARED.TYPE' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="buyerName" caption="{{ 'SHARED.BUYER' | translate }}">
      </dxi-column>

      <dxi-column type="buttons" [width]="120">
        <dxi-button [visible]="calculateDisabledDisplayValue" hint="{{ 'ZONES.DISABLE' | translate }}" icon="remove"
                    [onClick]="enableDisableZone"></dxi-button>
        <dxi-button [visible]="calculateEnabledDisplayValue" hint="{{ 'ZONES.ENABLE' | translate }}" icon="check"
                    [onClick]="enableDisableZone"></dxi-button>
        <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
        <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
      </dxi-column>
    </dx-data-grid>
  </div>

  <zone-component #details
                  (onClosed)="onDetailsComponentClosed($event)"></zone-component>
  <confirmation-component #confirmation title="{{ 'ZONES.DELETE' | translate }}"
                          message="{{ 'ZONES.DELETE_MESSAGE' | translate }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
</div>
