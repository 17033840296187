import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';

// services
import { LanguageService } from '../../../shared/services/language.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'cleanup-confirmation-component',
  templateUrl: 'cleanup-confirmation.component.html',
  providers: []
})
export class CleanupConfirmationComponent implements OnDestroy {

  @Input('title') title: string;
  @Input('message') message: string;
  @Input('buttonCloseText') buttonCloseText = 'CLOSE';
  @Input('cancellable') cancellable: boolean;
  @Input('date') date: Date;
  @Input('errorMessage') errorMessage: string;

  @Output() close = new EventEmitter<boolean>();
  @Output() dateChanged = new EventEmitter<Date>();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    private languageService: LanguageService
  ) {
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  dateValueChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
    this.dateChanged.emit(this.date);
  }

  opened: boolean;

  disabledButton() {
    if (this.date === null) {
      return true;
    } else {
      return false;
    }
  }
}
