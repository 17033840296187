import { Component, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

// services
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'save-confirmation-component',
  templateUrl: 'save-confirmation.component.html',
  providers: []
})
export class SaveConfirmationComponent implements OnDestroy {

  @Input('title') title: string;
  @Input('message') message: string;
  @Input('buttonCloseText') buttonCloseText = 'CLOSE';
  @Input('cancellable') cancellable: boolean;

  @Output() close = new EventEmitter<boolean>();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  opened: boolean;

  pageState = new Subject<boolean>();

  constructor(
    private languageService: LanguageService,
  ) {
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  leavePage(value: boolean) {
    if (value) {
      this.close.emit(true);
    }
    this.opened = false;
    this.pageState.next(value);
  }
}
