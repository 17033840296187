import { LotProperty } from './lot';
import { SystemProductProperty } from './system-product-property';
import { MasterDataListField } from './master-data';

export class Product {
  productId: number;
  auctionClusterId: number;
  name: string;
  sysName: string;
  description: string;
  priceUnitAmount: number;
  priceUnitId: number;
  priceUnitName: string;
  allowSellIndividualAutomatic: boolean;
  allowSellIndividualManual: boolean;
  startPriceBehaviour: number;
  startPriceLookupId: number;
  lotGroupMinimumPriceBehaviour: number;
  propertyIdToConcatenateInsideClock: number;
  propertyToConcatenateGroupingSelectionId: number;
  propertyToConcatenateManualGroupingSelectionId: number;
  propertyUsedForRestFieldId: number;
  propertyUsedForTotalFieldId: number;
  propertyUsedForTransactionInfoFieldId: number;
  supplyEditorLayout: number;
  supplyEditorLayoutWidth: number;
  supplyForecastEditorLayout: number;
  supplyForecastEditorLayoutWidth: number;
  systemProductProperties: Array<SystemProductProperty> = [];
  saleUnits: Array<SaleUnit> = [];
  shoppingListAmountPropertyFormatId: number;
  shoppingListPricePropertyFormatId: number;
  productPropertyGroups: Array<ProductPropertyGroup> = [];
  productProperties: Array<ProductProperty> = [];
  supplyDataEntryGridProperties: Array<GridProperty> = [];
  supplyForecastDataEntryGridProperties: Array<GridProperty> = [];
  supplyForecastPublicGridProperties: Array<GridProperty> = [];
  supplyDataEditorProperties: Array<LotEditorProperty> = [];
  supplyDataTouchEditorProperties: Array<LotEditorProperty> = [];
  supplyForecastDataEditorProperties: Array<LotEditorProperty> = [];
  currentLotAuctioneerProperties: Array<LotEditorProperty> = [];
  nextLotAuctioneerProperties: Array<GridProperty> = [];
  transactionInfoAuctioneerProperties: Array<TransactionProperty> = [];
  currentLotBuyerProperties: Array<LotEditorProperty> = [];
  nextLotBuyerProperties: Array<GridProperty> = [];
  transactionInfoBuyerProperties: Array<TransactionProperty> = [];
  currentLotAppBuyerProperties: Array<LotEditorProperty> = [];
  nextLotAppBuyerProperties: Array<GridProperty> = [];
  transactionInfoAppBuyerProperties: Array<TransactionProperty> = [];
  reportProperties: Array<GridProperty> = [];
  priceOverviewGridBuyer: Array<GridProperty> = [];
  shoppingListProductDefinitionBuyer: Array<GridProperty> = [];
  prebidOnProductBuyer: Array<GridProperty> = [];
  supplyMatrixAxisOne: Array<GridProperty> = [];
  supplyMatrixAxisTwo: Array<GridProperty> = [];
  supplyMonitorProductDefinitionBuyer: Array<GridProperty> = [];
  automaticProductPropertyGroupingSelections: Array<GroupingSelection> = [];
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyGroupingSelections: Array<GroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  moveLotsToCatalog: Array<GridProperty> = [];
  manualProductPropertyFilterSelections: Array<GridProperty> = [];
  transactionMonitor: Array<TransactionProperty> = [];
  clockUIStyle: ClockUIStyle = new ClockUIStyle();
  hasSupplyMasterDetails: true;
  hasForecastMasterDetails: true;
  priceOverviewShowTopPrice: false;
  priceOverviewShowAveragePrice: false;
  priceOverviewShowMinPrice: false;
  priceOverviewShowLastPurchasedAmount: false;
  priceOverviewShowLastPaidPrice: false;
  priceOverviewShowTotalSoldAmount: false;
  priceOverviewShowSummary: false;
  priceOverviewShowAvailableAmount: false;
  priceOverviewShowTotalAmount: false;
  enableTouchScreenLayout: false;
  enableDesktopScreenLayout: false;
  clockFaceAuctioneerProperties: Array<LotEditorProperty> = [];
  clockFaceBuyerProperties: Array<LotEditorProperty> = [];
  appClockFaceBuyerProperties: Array<LotEditorProperty> = [];
  lotInPreparationProperties: Array<LotEditorProperty> = [];
  packageTypeAuctioneerProperties: Array<LotEditorProperty> = [];
  selectLotAuctioneerProperties: Array<LotEditorProperty> = [];
  packageTypeBuyerProperties: Array<LotEditorProperty> = [];
  imagesWidgetProperties: Array<GridProperty> = [];
  productBuyButtons: Array<ProductBuyButton> = [];
  productFunctionKeysExpressions: Array<ProductFunctionKeysExpression> = [];
  productFunctionKeysActions: Array<ProductFunctionKeysAction> = [];
  selectLotSearchBehaviour: number;
  transactionRegistrationFilterProperties: Array<GridProperty> = [];
  transactionRegistrationGridProperties: Array<TransactionProperty> = [];
  widgets: Array<WidgetConfig> = [];
  enableVideoStreaming: false;
  allowEditTransactionPrice: boolean;
  allowMarkForConfirmation: boolean;
}

export class SaleUnit {
  name: string;
  amount: number;
  productId: number;

  // displayable fields
  saleUnitNameText: string;
}

export class GroupingSelection {
  orderNumber: number;
  productPropertyGroupingSelectionId: number;
  productId: number;
  productPropertyId: number;
  selected: boolean;

  // displayable fields
  productPropertyTypeName: string;
  productPropertyName: string;
}

export class NonGroupingSelection {
  masterDataListFieldId: number;
  actionOnGrouping: number;
  fixedTextEntry: string;
  aggregateType: number;
  concatenationSeparator: string;
  productPropertyGroupingSelectionId: number;
  productId: number;
  productPropertyId: number;

  // displayable fields
  productPropertyTypeName: string;
  productPropertyName: string;
  masterDataFieldName: string;
  actionLabel: string;
}

export class ProductPropertyGroup {
  productPropertyGroupId: number;
  name: string;
  collapse: boolean;
  orderNumber: number;
  selected: boolean;

  // displayable fields
  propertyGroupName: string;

  // FE custom field
  rows: Array<ProductPropertyGroupRow> = [];
}

export class ProductPropertyGroupRow {
  rowNumber: number;
  columns: Array<ProductPropertyGroupColumn> = [];
}

export class ProductPropertyEditorSelectionDetails {
  isVisible = true;
  isReadOnly: boolean;
  readOnlyOnUpdate: boolean;
  required: boolean;
  dependentOnProductPropertyId: number;
  actionOnNewLotOrChangedDepedencyProperty: number;
  clearAfterLastValueFromList: boolean;
  retrieveFromMask: boolean;
  maskId: number;
  effectOnMaskData: number;
  effectOnSecondMaskData: number;
  retrieveDefaultValue: boolean;
  clearIfNone: boolean;
  allowMultipleValuesWithinFieldDependency: number;
  confirmation: number;
  confirmationText: string;
  focusType: number;
  dependencyProperties: Array<number> = [];
  activeInMultiEdit = false;
  searchByStartsWith = false;
  focusEditBox: boolean;
  shortkeyLabel: string;
  shortkey: string;
  nextFocusedFieldId: number;
  useWeighingScale: boolean;
  weighingScaleUrl: string;
  useWeighingScaleSimulator: boolean;
}

export class ProductPropertyGroupColumn {
  columnNumber: number;
  property: LotProperty;
  productPropertyEditorSelectionDetails: ProductPropertyEditorSelectionDetails = new ProductPropertyEditorSelectionDetails();
}

export class ProductProperty {
  productPropertyId: number;
  productId: number;
  name: string;
  propertyTypeId: number;
  minValue: number;
  maxValue: number;
  maxLength: number;
  translatable: boolean;
  masterDataListId: number;
  lookupTableId: number;
  calculationId: number;
  systemProductPropertyType: number;
  dateFilterProductPropertyId: number;
  propertyTypeFormatId: number;
  sysName: string;
  imageCaptionId: number;
  masterDataListFields: Array<MasterDataListField>;

  // displayable fields
  productPropertyNameText: string;
  productPropertyTypeText: string;
  systemFieldTypeText: string;
}

export class ProductPropertyType {
  propertyTypeId: number;
  name: string;
  isMasterData: boolean;
}

export class WidgetConfig {
  widgetTypeId: number;
  productId: number;
  auctionClusterId: number;
  auctionUserTypeId: number
  isAvailable: boolean;
  isObligatory: boolean;
}

export class GenericProperty {
  productPropertyId: number;
  productPropertySelectionId: number;
  displayInfo: number;

  // displayable fields
  productPropertyName: string;
  productPropertyTypeName: string;
  masterDataFieldName: string;
}

export class GridProperty extends GenericProperty {
  automaticProductPropertyGroupingSelectionId: number;
  manualProductPropertyGroupingSelectionId: number;
  productPropertyGroupingSelectionId: number;
  masterDataListFieldId: number;
  masterDataListId: number;
  orderNumber: number;
  isComparable: boolean;
  isDeletable: boolean;
  propertyTypeId: number;
  propertyTypeFormatId: number;
  translatable: boolean;
  reportDesignId: number;
  isResetProperty: boolean;
  fieldName: string;
  name: string;
  filterOnCurrentLot: boolean;
  showLastInFullScreen: boolean;
  includeInSummary: boolean;
  valueToShowInSummary: string;
  selected: boolean;
  defaultMasterDataListRowId: number;
  defaultFromDate: Date;
  defaultTillDate: Date;
  dateType: any;
  width: number;
  showTodayDateSelection: boolean;
  showYesterdayDateSelection: boolean;
  showLastWeekDateSelection: boolean;
  showRangeDateSelection: boolean;
  showTomorrowDateSelection: boolean;
  showDateDateSelection: boolean;
  filterStoredProcedure: string;
  filterRequired: boolean;
  multiSelect: boolean;
  filterByDisplayValue: boolean;

  // helpers
  label: string;
  options: Array<any>;
  groupFilterSelections: Array<number> = [];
  masterDataRowIdSelection: Array<number> = [];
  intValueSelection: number;
  intValueToSelection: number;
}

export class LotEditorProperty extends GenericProperty {
  automaticProductPropertyGroupingSelectionId: number;
  manualProductPropertyGroupingSelectionId: number;
  productPropertyGroupId: number;
  masterDataListFieldId: number;
  columnNumber: number;
  rowNumber: number;
  productPropertyEditorSelectionDetails: ProductPropertyEditorSelectionDetails = new ProductPropertyEditorSelectionDetails();
  name: string;
  width: number;
  field: string;
  height: number;
  hideIfEmpty: boolean;
  isEditable: boolean;
  labelFor: string;
  productPropertyStylingId: number;
  systemClockProperty: string;
  orderNumber: number;
  useWeighingScale: boolean;
  weighingScaleUrl: string;
  useWeighingScaleSimulator: boolean;

   // displayable fields
  productPropertySelectionName: string;
  productPropertySelectionId: number;
}

export class TransactionProperty extends GenericProperty {
  masterDataListFieldId: number;
  orderNumber: number;
  name: string;
  selected: boolean;
  width: number;
}

export class ClockUIStyle {
  clockUIStyleId: number;
  name: string;
  sysName: string;

  clockBottomMargin: number;
  clockLeftMargin: number;
  clockRightMargin: number;
  clockTopMargin: number;

  isRectangle: boolean;

  cssContent: string;
  dotActiveColor: string;
  dotBorderColor: string;
  dotInactiveColor: string;
  dotPriceIndicationLineColor: string;
  dotPriceIndicationFontColor: string;
  dotTenfoldColor: string;
  waitTimeFillColor: string;
  waitTimeStrokeColor: string;
}

export class ProductBuyButton {
  productBuyButtonId: number;
  value: number;
  type: number;
  productId: number;
  orderNumber: number;
  typeDisplayName: string;
}

export class ProductFunctionKeysExpression {
  productFunctionKeysExpressionId: number;
  name: string;
  expression: string;
  productId: number;
  productPropertyId: number;
  usableDuringClockRun: boolean;
  // displayable fields
  productPropertyName: string;
}

export class ProductFunctionKeysAction {
  productFunctionKeysActionId: number;
  name: string;
  expression: string;
  action: number;
  productId: number;
  usableDuringClockRun: boolean;
}


export enum ProductBuyButtonTypes {
  'BUY' = 0,
  'ALL' = 1,
  'REST' = 2,
  'AMOUNT' = 3,
}

export enum ActionOnNewLotEnum {
  'RETAIN_CURRENT_VALUE' = 1,
  'CLEAR' = 2,
  'SELECT_NEXT_FROM_LIST' = 3,
  'USE_DATA_SOURCE' = 4
}

export enum EffectOfMaskOnDataEnum {
  'MASK_IS_FILTER' = 1,
  'MASK_USED_FOR_DEFAULT' = 2,
  'MASK_USED_FOR_DEFAULT_ALL_VALUES' = 3
}

export enum AllowMultilpleValuesWithingFieldDependency {
  'YES' = 1,
  'NO_SET_READ_ONLY' = 2,
  'NO_UPDATE_ALL_LOTS' = 3
}

export enum FocusTypeEnum {
  'ALWAYS' = 1,
  'NEVER' = 2,
  'ONLY_WHEN_REQUIRED' = 3
}

export enum SystemProductPropertyTypesEnum
{
    'MinPriceStop4' = 1,
    'MinPriceStop3' = 2,
    'StartPrice' = 3,
    'PriceUnit' = 5,
    'SalesUnit' = 6,
    'SalesUnitContent' = 7,
    'SalesUnitCount' = 8,
    'RemainderPriceUnitCount' = 9,
    'PriceUnitCount' = 10,
    'Supplier' = 11,
    'SalesDate' = 12,
    'MinPriceStop2' = 13,
    'MinPriceStop1' = 14,
    'BuyerMinPriceSupplier' = 15,
    'BuyerMinPriceAuction' = 16,
    'Buyer' = 17,
    'SubBuyer' = 18,
    'Price' = 19,
    'AmountBoughtPriceUnits' = 20,
    'AmountBoughtSaleUnits' = 21,
    'BuyerMinPriceTransaction' = 22,
    'MinPriceClockStopLowest' = 23,
    'BuyerMinPriceLowest' = 24,
    'Amount' = 25,
    'Location' = 26,
    'Clock' = 27,
    'SoldDateTime' = 28,
    'LotNumber' = 29,
    'SubNumber' = 30,
    'SubnumberBuyerNumber' = 31,
    'RestBox' = 32,
    'BoxContent' = 33,
    'TotalWeight' = 34,
    'ActiveUserCount' = 35,
    'BidCount' = 36,
    'BidCountSameprice' = 37,
    'OriginalAmount' = 38,
    'RestWeight' = 39,
    'Printer' = 40,
    'Computer' = 41,
    'SystemUnitDescription' = 47,
    'PackageContent' = 48,
    'LotId' = 49,
    'BuybackThreshold' = 56,
    'UserDisplayName' = 57,
    'MinAmount' = 58,
    'MaxAmount' = 59,
    'BoxSize' = 60,
    'OriginalTransactionAmount' = 61,
    'Multitude' = 62
}

export enum MasterDataFieldTypeEnum
{
  'None' = 0,
  'OrderBy' = 1,
  'Name' = 2,
  'Number' = 3,
  'Address1' = 4,
  'Address2' = 5,
  'Zip' = 6,
  'City' = 7,
  'Country' = 8,
  'Id' = 9,
  'Subnumber' = 10
}

export enum StartPriceBehaviourEnum {
  'NONE' = 0,
  'WEIGHTED_AVERAGE_PRICE' = 1,
  'MAX_PRICE' = 2,
  'CUSTOM' = 3,
  'LAST_TRANSACTION_PRICE' = 4
}

export enum LotGroupMinimumPriceBehaviourEnum {
  'HIGHEST_MINIMUM_PRICE' = 1,
  'LOWEST_MINIMUM_PRICE' = 2
}


export enum ProductFunctionKeysActionEnum {
  'JUMP_TO' = 1
}

