import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

// components
import { ContentComponent } from '../../shared/content/content.component';

// services
import { ServiceFactory } from '../../shared/factory/service-factory';
import { AuctionClusterService } from '../../buyer/shared/services/auction-cluster.service';
import { WebApiService } from '../../shared/services/web-api.service';

// models
import { AccessLevel, AdditionalMenuItem } from '../../shared/models/additional-menu-item';
import { ApplicationSettings } from '../../shared/models/application-settings';

@Component({
  selector: 'buyer-content.component',
  templateUrl: './buyer-content.component.html',
  styleUrls: ['./buyer-content.component.scss']
})
export class BuyerContentComponent extends ContentComponent implements OnInit, OnDestroy {

  auctionClusterService: AuctionClusterService;

  sidebarLogo = null;
  myRoute: any;
  navigationSubscription: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  menuItems: Array<AdditionalMenuItem> = [];
  subMenuItems: Array<AdditionalMenuItem> = [];

  constructor(
    injector: Injector,
    protected appSettings: ApplicationSettings,
    route: ActivatedRoute,
    protected webApiService: WebApiService,
    private titleService: Title,
  ) {
    super(injector);
    this.getServices(route, appSettings, webApiService);

    this.myRoute = route;
    //this.navigationSubscription = this.router.events.subscribe((e: any) => {
    //  // If it is a NavigationEnd event re-initalise the component
    //  if (e instanceof NavigationEnd) {
    //    this.initialise();
    //  }
    //});

    this._subscription = this.languageService.direction.subscribe(res => {
      this.rtlEnabled = res;
    });
  }

  initialise() {
    this.auctionClusterService.getAuctionClustersPerLevel().subscribe(acs => {
      this.sidebarLogo = null;
      if (acs && acs.length > 0) {
        localStorage.setItem('AUCXIS_SIDEBAR_LOGO', acs[0].image);
        if (acs[0].pageTitle) {
          this.titleService.setTitle(acs[0].pageTitle);
        }
        if (acs[0].favicon){
          const favicon = document.getElementById('faviconchangable') as HTMLLinkElement;
          if (favicon) {
            favicon.href = acs[0].favicon;
          }
        }
      } else {
        localStorage.removeItem('AUCXIS_SIDEBAR_LOGO')
      }

      const acImage = localStorage.getItem('AUCXIS_SIDEBAR_LOGO');

      if (acImage) {
        this.sidebarLogo = acImage;
      }
    });
  }

  ngOnInit() {
    this.initialise();

    super.ngOnInit();

    this.additionalMenuItemsService.getPublicMenuItems().subscribe(result => {

      if (result !== undefined) {
        var additionalMenuItemList = result.filter(f => f.accessLevels.length > 0 && f.accessLevels.filter(al => al === AccessLevel.BUYER));

        additionalMenuItemList.forEach(item => {
          if (item.submenuId !== null) {
            this.subMenuItems.push(item);
          } else
            this.menuItems.push(item);
        });
        
        this.menuItems.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));        
        this.subMenuItems.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
      }
    },
      error => {
        this.errorService.show(error);
      });
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialise()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }

    this._subscription.unsubscribe();
  }

  getServices(route, appSettings, webApiService) {
    this.auctionClusterService = ServiceFactory.getAuctionClusterService(document.location.hash, route, appSettings, webApiService) as AuctionClusterService;
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }
}
