import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { AuctionClusterSupplier, SupplierSimple } from '../models/auction-cluster-supplier';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class AuctionClusterSupplierService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getSuppliers(auctionClusterId: number): Observable<Array<AuctionClusterSupplier>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/supplier');
  }

  getSuppliersSimple(auctionClusterId: number): Observable<Array<SupplierSimple>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/supplier/simple');
  }

  getSuppliersPaged(auctionClusterId: number, loadOptions: any)
  {
    const url = this.apiPath + '/' + auctionClusterId + '/supplier/paged';
    let params: HttpParams = new HttpParams();
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "requireGroupCount",
                    "sort",
                    "filter",
                    "totalSummary",
                    "group",
                    "groupSummary"
                ].forEach(function(i) {
                    if (i in loadOptions && loadOptions[i] != undefined)
                        params = params.set(i, JSON.stringify(loadOptions[i]));
                });
      let result = this.webApiService.getWithParams(url, params);
      return result;
  }


  getSuppliersForCatalog(auctionClusterId: number, catalogId: number): Observable<Array<AuctionClusterSupplier>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/supplier/catalog/' + catalogId);
  }

  getSuppliersForCatalogPublished(auctionClusterId: number, catalogId: number): Observable<Array<AuctionClusterSupplier>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/supplier/catalog/' + catalogId + '/published');
  }

  getSupplier(auctionClusterId: number, supplierId: number): Observable<AuctionClusterSupplier> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/supplier', supplierId);
  }

  getByVat(auctionClusterId: number, vat: string): Observable<Array<AuctionClusterSupplier>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/supplier/vat/' + vat);
  }

  edit(auctionClusterId: number, supplier: AuctionClusterSupplier): Observable<any> {
    return this.webApiService.edit<AuctionClusterSupplier>(this.apiPath + '/' + auctionClusterId + '/supplier', supplier.supplierId, supplier); // tslint:disable-line:max-line-length
  }

  save(auctionClusterId: number, supplier: AuctionClusterSupplier): Observable<AuctionClusterSupplier> {
    return this.webApiService.save<AuctionClusterSupplier>(this.apiPath + '/' + auctionClusterId + '/supplier', supplier); // tslint:disable-line:max-line-length
  }

  delete(auctionClusterId: number, supplierId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/supplier', supplierId);
  }

  enableDisable(auctionClusterId: number, supplierId: AuctionClusterSupplier): Observable<AuctionClusterSupplier> {
    return this.webApiService.editSingle(this.apiPath + '/' + auctionClusterId + '/supplier/' + supplierId.supplierId + (supplierId.isActiveOnAuctionCluster ? '/disable' : '/enable'), null); // tslint:disable-line:max-line-length
  }

  checkVat(auctionClusterId: number, vat: string): Observable<boolean> {
    return this.webApiService.getSingle(this.apiPath + '/' + auctionClusterId + '/supplier/vat/' + vat + '/check'); // tslint:disable-line:max-line-length
  }
}
