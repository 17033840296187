import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import {
  DxColorBoxModule,
  DxDataGridModule,
  DxTemplateModule,
  DxBulletModule,
  DxButtonModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxTabsModule,
  DxTabPanelModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxFormModule,
  DxValidationGroupModule,
  DxPopupModule,
  DxToastModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxListModule,
  DxBoxModule,
  DxDateBoxModule,
  DxNumberBoxModule,
  DxRadioGroupModule,
  DxResponsiveBoxModule,
  DxGalleryModule, } from 'devextreme-angular';

import { AuctionRoutingModule } from './auction-routes.module';

// module components
import { AuctionContentComponent } from './content/auction-content.component';
import { AuctionHomeComponent } from './home/auction-home.component';
import { AuctionsComponent } from './auctions/auctions.component';
import { AuctionComponent } from './auctions/auction.component';
import { AuctionClusterUsersComponent } from './auction-cluster-users/auction-cluster-users.component';
import { AuctionClusterUserComponent } from './auction-cluster-users/auction-cluster-user.component';
import { AuctionClusterRolesComponent } from './auction-cluster-roles/auction-cluster-roles.component';
import { AuctionClusterRoleComponent } from './auction-cluster-roles/auction-cluster-role.component';
import { AuctionClusterReportingRolesComponent } from './auction-cluster-reporting-roles/auction-cluster-reporting-roles.component';
import { AuctionClusterReportingRoleComponent } from './auction-cluster-reporting-roles/auction-cluster-reporting-role.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationComponent } from './locations/location.component';
import { NameConfirmationComponent } from './auctions/name-confirmation.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './products/product.component';
import { ProductPropertiesComponent } from './products/product-properties.component';
import { ProductPropertyComponent } from './products/product-property.component';
import { SaleUnitsComponent } from './products/sale-units.component';
import { SaleUnitComponent } from './products/sale-unit.component';
import { SupplyCatalogsComponent } from './catalogs/supply-catalogs.component';
import { SupplyCatalogComponent } from './catalogs/supply-catalog.component';
import { ForecastCatalogsComponent } from './catalogs/forecast-catalogs.component';
import { ForecastCatalogComponent } from './catalogs/forecast-catalog.component';
import { SupplyLotsComponent } from './catalogs/supply-lots.component';
import { MasterDetailsComponent } from './catalogs/master-details.component';
import { MasterDetailsDetailComponent } from './catalogs/master-details-detail.component';
import { LotMultiEditComponent } from './catalogs/lot-multi-edit.component';
import { LotComponent } from './catalogs/lot.component';
import { GroupFilterComponent } from './catalogs/group-filter.component';
import { GroupFilterItemComponent } from './catalogs/group-filter-item.component';
import { MoveLotsComponent } from './catalogs/move-lots.component';
import { ClocksComponent } from './clocks/clocks.component';
import { ClockComponent } from './clocks/clock.component';
import { ZonesComponent } from './zones/zones.component';
import { ZoneComponent } from './zones/zone.component';
import { MasterDataListsComponent } from './master-data/master-data-lists.component';
import { MasterDataListComponent } from './master-data/master-data-list.component';
import { MasterDataFieldsComponent } from './master-data/master-data-fields.component';
import { MasterDataFieldComponent } from './master-data/master-data-field.component';
import { MasterDataListEditorComponent } from './master-data/master-data-list-editor.component';
import { LookupTablesComponent } from './lookup-table/lookup-tables.component';
import { LookupTableComponent } from './lookup-table/lookup-table.component';
import { LookupTableFieldsComponent } from './lookup-table/lookup-table-fields.component';
import { LookupTableFieldComponent } from './lookup-table/lookup-table-field.component';
import { LookupTableEditorComponent } from './lookup-table/lookup-table-editor.component';
import { LookupTableRowEditorComponent } from './lookup-table/lookup-table-row-editor.component';
import { MasksComponent } from './mask/masks.component';
import { MaskComponent } from './mask/mask.component';
import { MaskFieldComponent } from './mask/mask-field.component';
import { MaskFieldsComponent } from './mask/mask-fields.component';
import { CalculationsComponent } from './calculations/calculations.component';
import { CalculationComponent } from './calculations/calculation.component';
import { CalculationsFieldsComponent } from './calculations/calculations-fields.component';
import { CalculationsFieldComponent } from './calculations/calculations-field.component';
import { TicketPrintersComponent } from './ticket-printers/ticket-printers.component';
import { TicketPrinterComponent } from './ticket-printers/ticket-printer.component';
import { LineMonitorComponent } from './line-monitor/line-monitor.component';
import { BuyersComponent } from './buyers/buyers.component';
import { BuyerComponent } from './buyers/buyer.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierComponent } from './suppliers/supplier.component';
import { GenericProductPropertiesComponent } from './products/generic-product-properties.component';
import { GenericProductPropertyComponent } from './products/generic-product-property.component';
import { CurrentLotPropertiesComponent } from './products/current-lot-properties.component';
import { ProductBuyButtonsComponent } from './products/product-buy-buttons.component';
import { CurrentLotPropertyComponent } from './products/current-lot-property.component';
import { ProductBuyButtonComponent } from './products/product-buy-button.component';
import { ProductFunctionkeysExpressionsComponent } from './products/product-functionkeys-expressions/product-functionkeys-expressions.component';
import { ProductFunctionkeysExpressionComponent } from './products/product-functionkeys-expressions/product-functionkeys-expression.component';
import { ProductFunctionkeysActionsComponent } from './products/product-functionkeys-actions/product-functionkeys-actions.component';
import { ProductFunctionkeysActionComponent } from './products/product-functionkeys-actions/product-functionkeys-action.component';
import { TransactionPropertiesComponent } from './products/transaction-properties.component';
import { TransactionPropertyComponent } from './products/transaction-property.component';
import { PublishLotComponent } from './catalogs/publish-lot.component';
import { ProductPropertyGroupsComponent } from './products/product-property-groups.component';
import { ProductPropertyGroupComponent } from './products/product-property-group.component';
import { GroupingPropertiesComponent } from './products/grouping-properties.component';
import { GroupingPropertyComponent } from './products/grouping-property.component';
import { NonGroupingPropertyComponent } from './products/non-grouping-property.component';
import { ProductEditorComponent } from './products/product-editor.component';
import { SubBuyersComponent } from './buyers/sub-buyers.component';
import { SubBuyerComponent } from './buyers/sub-buyer.component';
import { MasterDataListRowEditorComponent } from './master-data/master-data-list-row-editor.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './reports/report.component';
import { ReportEditorComponent } from './reports/report-editor.component';
import { LotteryComponent } from './lottery/lottery.component';
import { AuctionSupplyMonitorComponent } from './supply-monitor/auction-supply-monitor.component';
import { AuctionTransactionMonitorComponent } from './transaction-monitor/auction-transaction-monitor.component';
import { AuctionTransactionDetailComponent } from './transaction-monitor/auction-transaction-detail.component';
import { CleanupConfirmationComponent } from './shared/components/cleanup-confirmation.component';
import { PauseMessageComponent } from './clocks/pause-message.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsAndConditionsAddComponent } from './terms-and-conditions/terms-and-conditions-add.component';
import { SupplyLotsTouchComponent } from './catalogs/supply-lots-touch.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GallerySeatComponent } from './gallery/gallery-seat.component';
import { BuybackOnLotComponent } from './shared/components/buyback-on-lot.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CoincodeEditorComponent } from './shared/components/coincode-editor.component';

// shared modules
import { SharedModule } from '../shared/shared.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DxReportViewerModule } from 'devexpress-reporting-angular';

// shared services
import { AuctionService } from './shared/services/auction.service';
import { AuctionClusterService } from './shared/services/auction-cluster.service';
import { AuctionClusterUserService } from './shared/services/auction-cluster-user.service';
import { AuctionClusterPermissionService } from './shared/services/auction-cluster-permission.service';
import { AuctionClusterLevelRoleService } from './shared/services/auction-cluster-level-role.service';
import { AuctionClusterReportingRoleService } from './shared/services/auction-cluster-reporting-role.service';
import { AuctionClusterAuctionService } from './shared/services/auction-cluster-auction.service';
import { CustomAdminService } from './shared/services/custom-admin.service';
import { ProductService } from './shared/services/product.service';
import { PriceUnitService } from './shared/services/price-unit.service';
import { CatalogService } from './shared/services/catalog.service';
import { LotteryService } from './shared/services/lottery.service';
import { LotService } from './shared/services/lot.service';
import { ProductPropertyTypeService } from './shared/services/product-property-type.service';
import { LocationService } from './shared/services/location.service';
import { ZoneService } from './shared/services/zone.service';
import { MasterDataService } from './shared/services/master-data.service';
import { LookupTableService } from './shared/services/lookup-table.service';
import { MaskService } from './shared/services/mask.service';
import { CalculationsService } from './shared/services/calculations.service';
import { TicketPrinterService } from './shared/services/ticket-printer.service';
import { LineMonitorService } from './shared/services/line-monitor.service';
import { AuctionClusterBuyerService } from './shared/services/auction-cluster-buyer.service';
import { AuctionClusterSupplierService } from './shared/services/auction-cluster.supplier.service';
import { LeavePageService } from './shared/services/leave-page.service';
import { AuctionClusterReportService } from './shared/services/report.service';
import { UserReportTemplateService } from './shared/services/user-report-template.service';
import { UserLoginsComponent } from '../shared/components/reporting/user-logins.component';
import { UserLineTimesComponent } from '../shared/components/reporting/user-line-times.component';
import { AuctionSupplyMonitorService } from './shared/services/auction-supply-monitor.service';
import { AuctionTransactionMonitorService } from './shared/services/auction-transaction-monitor.service';
import { ProductPropertyStylingService } from './shared/services/product-property-styling.service';
import { GalleryService } from './shared/services/gallery.service';
import { AuctionClusterNotificationService } from './shared/services/auction-cluster-notification.service'; 
import { NotificationComponent } from './notifications/notification.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserModule,
    AuctionRoutingModule,
    HttpClientModule,
    TranslateModule,
    SharedModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DxReportViewerModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTabPanelModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxFormModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxToastModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxListModule,
    DxBoxModule,
    DxRadioGroupModule,
    DxDateBoxModule,
    DxNumberBoxModule,
    DxResponsiveBoxModule,
    DxGalleryModule
  ],
  declarations: [
    AuctionContentComponent,
    AuctionHomeComponent,
    NameConfirmationComponent,
    AuctionClusterUsersComponent, AuctionClusterUserComponent,
    AuctionClusterRolesComponent, AuctionClusterRoleComponent,
    AuctionClusterReportingRolesComponent, AuctionClusterReportingRoleComponent,
    AuctionsComponent, AuctionComponent,
    LocationsComponent, LocationComponent,
    ProductsComponent, ProductComponent,
    SaleUnitsComponent, SaleUnitComponent,
    ProductPropertiesComponent, ProductPropertyComponent,
    SupplyCatalogsComponent, SupplyCatalogComponent,
    ForecastCatalogsComponent, ForecastCatalogComponent,
    GroupFilterComponent, GroupFilterItemComponent, MoveLotsComponent,
    ClocksComponent, ClockComponent,
    SupplyLotsComponent, LotComponent, LotMultiEditComponent, SupplyLotsTouchComponent,
    ZonesComponent, ZoneComponent,
    MasterDataListsComponent, MasterDataListComponent,
    MasterDataFieldsComponent, MasterDataFieldComponent,
    MasterDataListEditorComponent, LineMonitorComponent,
    LookupTablesComponent, LookupTableComponent,
    LookupTableFieldComponent, LookupTableFieldsComponent,
    LookupTableEditorComponent, LookupTableRowEditorComponent,
    MasksComponent, MaskComponent,
    MaskFieldComponent, MaskFieldsComponent,
    CalculationsComponent, CalculationComponent,
    CalculationsFieldsComponent, CalculationsFieldComponent,
    TicketPrintersComponent, TicketPrinterComponent,
    BuyersComponent, BuyerComponent,
    SuppliersComponent, SupplierComponent,
    GenericProductPropertiesComponent, GenericProductPropertyComponent,
    CurrentLotPropertiesComponent, CurrentLotPropertyComponent,
    TransactionPropertiesComponent, TransactionPropertyComponent,
    ProductPropertyGroupsComponent, ProductPropertyGroupComponent,
    GroupingPropertiesComponent, GroupingPropertyComponent, NonGroupingPropertyComponent,
    PublishLotComponent, ProductEditorComponent,
    SubBuyersComponent, SubBuyerComponent,
    MasterDataListRowEditorComponent,
    LotteryComponent,
    UserLoginsComponent,
    UserLineTimesComponent,
    ReportsComponent, ReportComponent, ReportEditorComponent,
    AuctionSupplyMonitorComponent, AuctionTransactionMonitorComponent, AuctionTransactionDetailComponent,
    CleanupConfirmationComponent, MasterDetailsComponent, MasterDetailsDetailComponent,
    PauseMessageComponent, TermsAndConditionsComponent,TermsAndConditionsAddComponent,
    GalleryComponent, GallerySeatComponent, BuybackOnLotComponent, NotificationsComponent, NotificationComponent,
    ProductBuyButtonsComponent, ProductBuyButtonComponent, CoincodeEditorComponent,
    ProductFunctionkeysExpressionsComponent, ProductFunctionkeysExpressionComponent,
    ProductFunctionkeysActionsComponent, ProductFunctionkeysActionComponent
  ],
  providers: [
    AuctionService,
    AuctionClusterService,
    AuctionClusterUserService,
    AuctionClusterPermissionService,
    AuctionClusterLevelRoleService,
    AuctionClusterReportingRoleService,
    AuctionClusterAuctionService,
    CustomAdminService,
    LocationService,
    ProductService,
    PriceUnitService,
    CatalogService,
    LotteryService,
    LotService,
    ProductPropertyTypeService,
    ZoneService,
    MasterDataService,
    LookupTableService,
    MaskService,
    CalculationsService,
    TicketPrinterService,
    LineMonitorService,
    AuctionClusterBuyerService,
    AuctionClusterSupplierService,
    LeavePageService,
    AuctionClusterReportService,
    UserReportTemplateService,
    AuctionSupplyMonitorService,
    AuctionTransactionMonitorService,
    ProductPropertyStylingService,
    GalleryService,
    AuctionClusterNotificationService
  ],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuctionModule { }
