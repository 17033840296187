import { Component, OnInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { ZoneComponent } from '../zones/zone.component';

// models
import { Buyer } from '../../shared/models/buyer';
import { Location } from '../shared/models/location';
import { Zone } from '../shared/models/zone';

// services
import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';
import { LocationService } from '../shared/services/location.service';
import { ZoneService } from '../shared/services/zone.service';


@Component({
  selector: 'zones-component',
  templateUrl: 'zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent extends FullListComponent<Zone, ZoneComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ZoneComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  buyers: Array<Buyer> = [];
  locations: Array<Location> = [];
  selectedLocation: number = -1;
  zoneToDisable: Zone;

  constructor(
    protected injector: Injector,
    private dataService: ZoneService,
    private buyerService: AuctionClusterBuyerService,
    private locationService: LocationService
  ) {
    super(injector, Zone);
    this.title.set('ZONES.TITLE');
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations("ZONES");
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.dataService.getZones(this.id),
      this.buyerService.getBuyers(this.id),
      this.locationService.getLocations(this.id)
    ).subscribe(result => {
      this.items = result[0];
      this.buyers = result[1];
      this.locations = result[2];

      this.matchLocationNames();
      this.matchTypeNames();
      this.matchBuyerNames();
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  edit = (e: any) => {
    // this.locations.shift();
    const zoneId = e.row !== undefined ? e.row.data.zoneId : e.data.zoneId;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, zoneId, this.locations, this.buyers, this.id);
  }

  add() {
    // this.locations.shift();
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null, this.locations, this.buyers, this.id);
  }

  matchLocationNames() {
    this.items.forEach(item => {
      let location = this.locations.find(f => f.locationId === item.locationId);
      if (location)
        item.locationName = location.name;
    });
  }

  matchTypeNames() {
    this.items.forEach(item => {
      item.typeName = item.type === 1 ? 'Dedicated' : 'Shared';
    });
  }

  enableDisableZone = (e: any) => {
    const zone: Zone = e.row.data;
    this.zoneToDisable = zone;

    this.dataService.enableDisable(this.id, this.zoneToDisable).subscribe(() => {
      this.getData();
    },
      error => {
        this.errorService.show(error);
      });
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.locationId;
    this.confirmation.opened = true;
  }

  calculateDisabledDisplayValue = (e: any) => {
    if (e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  calculateEnabledDisplayValue = (e: any) => {
    if (!e.row.data.isActive) {
      return true;
    } else {
      return false;
    }
  }

  matchBuyerNames() {
    this.items.forEach(item => {
      item.buyers.forEach(buyer => {
        item.buyerName = this.buyers.find(f => f.buyerId === buyer) ? this.buyers.find(f => f.buyerId === buyer).name : '';
      });
    });
  }

  getSelectedZones() {
    if (!this.selectedLocation) {
      this.getData();
    } else {
      this.dataService.getLocationZones(this.id, this.selectedLocation).subscribe(zones => {
        this.items = zones;
        this.matchLocationNames();
        this.matchTypeNames();
        this.matchBuyerNames();
      });
    }
  }

  deleteSelected() {
    this.spinner.show();
    this.dataService.delete(this.id, this.itemIdToDelete)
      .subscribe((zones: Array<Zone>) => {
        this.getData();
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }
}