import { Component, ViewChild, OnInit, Injector, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { SimpleListComponent } from '../../shared/components/simple-list/simple-list.component';
import { TransactionPropertyComponent } from './transaction-property.component';

// models
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataListField } from '../../shared/models/master-data';
import { ProductProperty, ProductPropertyType, TransactionProperty, NonGroupingSelection, Product } from '../../shared/models/product';

// services
import { LanguageService } from '../../shared/services/language.service';
import { MasterDataService } from '../shared/services/master-data.service';


@Component({
  selector: 'transaction-properties-component',
  templateUrl: 'transaction-properties.component.html'
})
export class TransactionPropertiesComponent extends SimpleListComponent implements OnInit {

  items: Array<TransactionProperty> = [];
  product: Product;
  productProperties: Array<ProductProperty> = [];
  propertyTypes: Array<ProductPropertyType> = [];
  editingIndex: number;
  masterData: Array<MasterData> = [];
  pageSize = 50;
  auctionClusterId: number;
  languages: Array<Language> = [];
  propertyGroupingShowing = false;
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  showWidth = false;
  showColRow = false;

  @Input('propertyGroupingShowing')
  set propertyGroupingShowingSetter(value: boolean) {
    this.propertyGroupingShowing = value;
  }

  @Input('showColRow')
  set showColRowSetter(value: boolean) {
    this.showColRow = value;
  }

  @Input('automaticProductPropertyNonGroupingSelections')
  set automaticProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.automaticProductPropertyNonGroupingSelections = value;
  }

  @Input('manualProductPropertyNonGroupingSelections')
  set manualProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.manualProductPropertyNonGroupingSelections = value;
  }

  @Input('languages')
  set langs(value: Array<Language>) {
    this.languages = value;
  }

  @Input('showWidth')
  set showWidthSetter(value: boolean) {
    this.showWidth = value;
  }

  @Input('productConfig')
  set productConfig(value: Product) {
    this.product = value;
  }

  @Input('data')
  set data(value: Array<TransactionProperty>) {
    this.items = value;
    this.items.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('productProperties')
  set productProperty(value: Array<ProductProperty>) {
    this.productProperties = value;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    if(this.masterData)
    this.matchMasterDataFieldNames();
  }

  @Input('productPropertyTypes')
  set propertyType(value: Array<ProductPropertyType>) {
    this.propertyTypes = value;
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
  }

  @Input('masterData')
  set masterDataList(value: Array<MasterData>) {
    this.masterData = value;
    this.matchTypeNames();
    if(this.productProperties)
    this.matchMasterDataFieldNames();
  }

  @Output() dataChanged = new EventEmitter<boolean>();
  @Output() transactionMonitorSettingsChanged = new EventEmitter<boolean>();

  @ViewChild(TransactionPropertyComponent) detailsComponent: TransactionPropertyComponent;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
  }

  ngOnInit() { // tslint:disable-line:no-empty
    this.setTranslations('PRODUCT');
  }

  edit(property: TransactionProperty, event: Event) {
    this.editingIndex = this.items.indexOf(property);
    event.stopPropagation();
    this.detailsComponent.modalTitle = this.translations.PROPERTY.EDIT;
    const clone = { ...property };
    this.detailsComponent.open(this.items, clone, this.productProperties, this.propertyTypes, this.masterData, this.languages);
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.PROPERTY.ADD_NEW;
    this.detailsComponent.open(this.items, new TransactionProperty(), this.productProperties, this.propertyTypes, this.masterData, this.languages); // tslint:disable-line:max-line-length
  }

  onProductPropertyAdded(property: TransactionProperty) {
    this.items.push(property);
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    this.dataChanged.emit();
  }

  onProductPropertyUpdated(property: TransactionProperty) {
    this.items[this.editingIndex] = property;
    this.matchProductPropertiesNames();
    this.translateProperties();
    this.matchTypeNames();
    this.matchMasterDataFieldNames();
    this.dataChanged.emit();
  }

  moveUp(event: Event) {
    this.moveUpItems(event, this.items);
  }

  moveDown(event: Event) {
    this.moveDownItems(event, this.items);
  }

  delete(property: TransactionProperty) {
    this.items.splice(this.items.indexOf(property), 1);
    this.dataChanged.emit();
  }

  matchProductPropertiesNames() {
    this.items.forEach(property => {
      const propName = this.productProperties.find(f => f.productPropertyId === property.productPropertyId).name;
      if (propName) {
        property.productPropertyName = propName;
      }
    });
  }

  translateProperties() {
    this.items.forEach(property => {
      property.productPropertyName = this.languageService.getTranslatableText(property.productPropertyName);
    });
  }

  matchTypeNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(f => f.productPropertyId === item.productPropertyId);
      if (prop) {
        const ppt = this.propertyTypes.find(f => f.propertyTypeId === prop.propertyTypeId);
        if (ppt) {
          item.productPropertyTypeName = ppt.name;
        }
      }
    });
  }

  matchMasterDataFieldNames() {
    this.items.forEach(item => {
      const prop = this.productProperties.find(pp => pp.productPropertyId === item.productPropertyId);
      if (prop && prop.masterDataListId) {
        let masterDataListFields: Array<MasterDataListField> = [];
        let currentMasterData = this.masterData.find(m => m.masterDataListId == prop.masterDataListId)
        if(currentMasterData)
        {
          masterDataListFields = currentMasterData.fields;
          const mdf = masterDataListFields.find(f => f.masterDataListFieldId === item.masterDataListFieldId);
          if (mdf) {
            item.masterDataFieldName = mdf.name;
          }
        } 
      } else {
        item.masterDataFieldName = ' - ';
      }
    });
  }
}
