<div class="item-list-component lots-component" [ngClass]="{'vertical': isVertical, 'master-details-showing': isMasterDetailsShowing, 'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="editor" *ngIf="!enableTouchScreenLayout" [style.width]="isVertical ? getLayoutWidht() : '100%'" [style.padding]="isVertical ? '0px 10px' : '0px'">
    <div class="card">
      <div class="card-header">
        {{ 'SHARED.PROPERTIES' | translate }}
      </div>
      <div class="card-block" (keydown)="changeFocus($event)">
        <form #lotForm="ngForm" *ngIf="lot">
          <dx-validation-group #validationFormGroup>
            <div *ngFor="let group of lot.lotPropertyGroups; let groupIndex = index" [ngClass]="{ 'card-block product-properties': !isGroupHiddenOrEmpty(group) }">
              <div *ngIf="!isGroupHiddenOrEmpty(group)">
                <div>
                  <label class="group-label">{{ group.name }}</label>
                </div>
                <div class="lot-row" *ngFor="let row of group.lotPropertyGroupRows; let rowIndex = index">

                  <div class="lot-column" *ngFor="let property of row.lotProperties; let i = index"
                       [style.width]="getPropertyWidth(property)" [style.marginRight]="getPropertyMargin(property)">
                    <button type="button" tabindex="-1" class="collapse-btn" *ngIf="(group.collapse && !(rowIndex > 0 || (rowIndex === 0 && i > 0))) && (group.lotPropertyGroupRows.length > 1 || row.lotProperties.length > 1)" (click)="group.collapsed = !group.collapsed">
                      <i class="dx-icon-plus" *ngIf="group.collapsed"></i>
                      <i class="dx-icon-minus" *ngIf="!group.collapsed"></i>
                    </button>
                    <div [ngClass]="{'collapsed': (group.collapse && (rowIndex > 0 || (rowIndex === 0 && i > 0))) && group.collapsed}">
                      <div *ngIf="property.propertyTypeId === 1">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="numberValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': (numberValueProp.touched || numberValueProp.dirty) && numberValueProp.invalid}">
                            <dx-number-box name="{{'numberValueProp' + groupIndex + rowIndex + i}}"
                                           [(value)]="property.intValue"
                                           [showSpinButtons]="true"
                                           format="#0"
                                           [step]="1"
                                           [min]="property.minValue"
                                           [max]="property.maxValue"
                                           class="focusable"
                                           [tabIndex]="getTabIndex(property)"
                                           [rtlEnabled]="rtlEnabled"
                                           valueChangeEvent="keyup"
                                           #propertyComponent
                                           #numberValueProp
                                           (onInitialized)="initNumericValues(property);initializeComponent(propertyComponent, property);"
                                           (onFocusOut)="elementBlur(property, numberValueProp); onPropertyChanged(property); "
                                           (onFocusIn)="focusProperty(propertyComponent, property, $event)"
                                           (onValueChanged)="markValueChanged(property); validate(property, numberValueProp); property.changed = true; validateForm(); onPropertyKeyStroke(property);"
                                           [readOnly]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                              <dx-validator *ngIf="property.productPropertyEditorSelectionDetails.required">
                                <dxi-validation-rule type="required" message=""></dxi-validation-rule>
                              </dx-validator>
                            </dx-number-box>
                            <!--<input type="number"
                                   name="{{'numberValueProp' + groupIndex + rowIndex + i}}"
                                   class="focusable"
                                   [attr.name]="'numberValueProp' + groupIndex + rowIndex + i"
                                   [(ngModel)]=""
                                   (ngModelChange)="property.changed = true"
                                   [attr.tabindex]="getTabIndex(property)"
                                   (blur)="elementBlur(property, numberValueProp); onPropertyChanged(property);"
                                   (change)="validate(property, numberValueProp);"
                                   #numberValueProp="ngModel"
                                   [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                   [readonly]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled" />-->
                            <span class="tooltip-content" *ngIf="numberErrorMessage!==''">
                              {{numberErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="numberErrorMessage===''">
                              <!-- Required text -->
                              <ng-container *ngIf="property.productPropertyEditorSelectionDetails.required">
                                {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                              </ng-container>
                              <!-- Min/max validation text -->
                              <ng-container *ngIf="property.minValue !== undefined && property.maxValue !== undefined">
                                {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MIN_MAX' | translate: { minValue: property.minValue, maxValue: property.maxValue } }}
                              </ng-container>
                              <!-- Min validation text -->
                              <ng-container *ngIf="property.minValue !== undefined && property.maxValue === undefined">
                                {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MIN' | translate: { minValue: property.minValue } }}
                              </ng-container>
                              <!-- Max validation text -->
                              <ng-container *ngIf="property.minValue === undefined && property.maxValue !== undefined">
                                {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MAX' | translate: { maxValue: property.maxValue } }}
                              </ng-container>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="property.propertyTypeId === 7">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <dx-check-box [(value)]="property.booleanValue"
                                        name="{{'booleanValueProp' + groupIndex + rowIndex + i}}"
                                        [rtlEnabled]="rtlEnabled"
                                        [tabIndex]="getTabIndex(property)"
                                        #propertyComponent
                                        (onInitialized)="initializeComponent(propertyComponent, property)"
                                        valueChangeEvent="keyup"
                                        (onValueChanged)="focusProperty(propertyComponent, property, $event); markValueChanged(property); property.changed = true; validate(property, booleanValueProp); validateForm(); onPropertyKeyStroke(property); onPropertyChanged(property);"
                                        [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                        class="focusable">
                          </dx-check-box>
                          <!--<clr-checkbox name="{{'booleanValueProp' + groupIndex + rowIndex + i}}"
                                        [attr.name]="'booleanValueProp' + groupIndex + rowIndex + i"
                                        class="focusable"
                                        #booleanValueProp="ngModel"
                                        [(ngModel)]="property.booleanValue"
                                        (ngModelChange)="property.changed = true"
                                        [attr.tabindex]="-1"
                                        [attr.tabindexForChildren]="getTabIndex(property)"
                                        (blur)="elementBlur(property, booleanValueProp);"
                                        (click)="onPropertyChanged(property);"
                                        (change)="validate(property, booleanValueProp);"
                                        [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                        [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                          </clr-checkbox>-->
                        </div>
                      </div>
                      <div *ngIf="property.propertyTypeId === 2">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="decimalValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': (decimalValueProp.touched || decimalValueProp.dirty) && decimalValueProp.invalid}">
                            <dx-number-box name="{{'decimalValueProp' + groupIndex + rowIndex + i}}"
                                           [ngClass]="{'weighing-scale-field-stable': (property.isWeighingScaleOn && weightingScaleConnectionStatus === 1),
                                                        'weighing-scale-field-unstable': (property.isWeighingScaleOn && weightingScaleConnectionStatus === 2),
                                                        'weighing-scale-field-error': (property.isWeighingScaleOn && weightingScaleConnectionStatus === 3)}"
                                           [(value)]="property.decimalValue"
                                           [showSpinButtons]="true"
                                           [format]="getDecimalFormat(property)"
                                           [step]="getDecimalStep(property)"
                                           [min]="property.minValue"
                                           [max]="property.maxValue"
                                           class="focusable"
                                           [rtlEnabled]="rtlEnabled"
                                           [tabIndex]="getTabIndex(property)"
                                           valueChangeEvent="keyup"
                                           #propertyComponent
                                           #decimalValueProp
                                           (onInitialized)="initNumericValues(property);initializeComponent(propertyComponent, property);"
                                           (onFocusOut)="elementBlur(property, decimalValueProp); onPropertyChanged(property);"
                                           (onFocusIn)="focusProperty(propertyComponent, property, $event)"
                                           (onValueChanged)="markValueChanged(property); validate(property, decimalValueProp); property.changed = true; validateForm(); onPropertyKeyStroke(property);"
                                           [readOnly]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled"
                                           [disabled]="property.isWeighingScaleOn">
                              <dx-validator *ngIf="property.productPropertyEditorSelectionDetails.required">
                                <dxi-validation-rule type="required" message=""></dxi-validation-rule>
                              </dx-validator>
                            </dx-number-box>
                            <!--<input type="number"
                                   name="{{'decimalValueProp' + groupIndex + rowIndex + i}}"
                                   class="focusable"
                                   [attr.name]="'decimalValueProp' + groupIndex + rowIndex + i"
                                   [(ngModel)]="property.decimalValue"
                                   (ngModelChange)="property.changed = true"
                                   [attr.tabindex]="getTabIndex(property)"
                                   (blur)="elementBlur(property, decimalValueProp); onPropertyChanged(property);"
                                   (change)="validate(property, decimalValueProp);"
                                   (input)="onPropertyKeyStroke(property);"
                                   #decimalValueProp="ngModel"
                                   [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                   [readonly]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled" />-->
                            <span class="tooltip-content" *ngIf="decimalErrorMessage!==''">
                              {{decimalErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="decimalErrorMessage===''">
                              <!-- Required text -->
                              <ng-container *ngIf="property.productPropertyEditorSelectionDetails.required">
                                {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                              </ng-container>
                              <!-- Min/max validation text -->
                              <ng-container *ngIf="property.minValue !== undefined && property.maxValue !== undefined">
                                {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MIN_MAX' | translate: { minValue: property.minValue, maxValue: property.maxValue } }}
                              </ng-container>
                              <!-- Min validation text -->
                              <ng-container *ngIf="property.minValue !== undefined && property.maxValue === undefined">
                                {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MIN' | translate: { minValue: property.minValue } }}
                              </ng-container>
                              <!-- Max validation text -->
                              <ng-container *ngIf="property.minValue === undefined && property.maxValue !== undefined">
                                {{ 'LOTS.NUMERIC_FIELD_VALIDATION_MAX' | translate: { maxValue: property.maxValue } }}
                              </ng-container>
                            </span>
                          </label>
                          <dx-switch *ngIf="property.productPropertyEditorSelectionDetails.useWeighingScale"
                                     class="switch-button"
                                     switchedOffText="Off"
                                     switchedOnText="On"
                                     (onInitialized)="setWeighingScaleState(property)"
                                     [value]="property.isWeighingScaleOn"
                                     (onValueChanged)="changeWeighingScaleState(property, $event)"
                                     [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly"
                                     hint="{{ 'LOTS.WEIGHING_SCALE' | translate }}">
                          </dx-switch>
                        </div>
                      </div>
                      <div *ngIf="property.propertyTypeId === 3 && !property.translatable">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="stringValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': (stringValueProp.touched || stringValueProp.dirty) && stringValueProp.invalid}">
                            <dx-text-box name="{{'stringValueProp' + groupIndex + rowIndex + i}}"
                                         [(value)]="property.stringValue"
                                         [maxLength]="property.maxLength ? property.maxLength : ''"
                                         class="focusable"
                                         [rtlEnabled]="rtlEnabled"
                                         [tabIndex]="getTabIndex(property)"
                                         valueChangeEvent="keyup"
                                         #propertyComponent
                                         #stringValueProp
                                         (onFocusOut)="elementBlur(property, stringValueProp, $event); onPropertyChanged(property);"
                                         (onFocusIn)="focusProperty(propertyComponent, property, $event)"
                                         (onInitialized)="initializeComponent(propertyComponent, property)"
                                         (onValueChanged)="markValueChanged(property); validate(property, stringValueProp); property.changed = true; validateForm(); onPropertyKeyStroke(property);"
                                         [readOnly]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                              <dx-validator *ngIf="property.productPropertyEditorSelectionDetails.required">
                                <dxi-validation-rule type="required" message=""></dxi-validation-rule>
                              </dx-validator>
                            </dx-text-box>
                            <!--<input type="text"
                                   name="{{'stringValueProp' + groupIndex + rowIndex + i}}"
                                   class="focusable"
                                   [attr.name]="'stringValueProp' + groupIndex + rowIndex + i"
                                   [(ngModel)]="property.stringValue"
                                   (ngModelChange)="property.changed = true"
                                   [attr.tabindex]="getTabIndex(property)"
                                   (blur)="elementBlur(property, stringValueProp); onPropertyChanged(property);"
                                   (change)="validate(property, stringValueProp);"
                                   #stringValueProp="ngModel"
                                   [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                   [readonly]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled" />-->
                            <span class="tooltip-content" *ngIf="textErrorMessage!==''">
                              {{textErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="textErrorMessage===''">
                              <!-- Required text -->
                              <ng-container *ngIf="property.productPropertyEditorSelectionDetails.required">
                                {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                              </ng-container>
                              <!-- Length validation text -->
                              <ng-container *ngIf="property.maxLength !== undefined">
                                {{ 'LOTS.TEXT_FIELD_VALIDATION_LENGTH' | translate: { maxLength: property.maxLength } }}
                              </ng-container>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="property.propertyTypeId === 3 && property.translatable" class="form-group translatable-wrapper" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                        <translatable-field [(data)]="property.stringValue"
                                            [attr.tabindex]="getTabIndex(property)"
                                            [caption]="getTranslation(property.name)"
                                            [componentIndex]="property.productPropertyId"
                                            [name]="'translatable' + groupIndex + rowIndex + i"
                                            class="focusable"
                                            [isFocusable]="property.productPropertyEditorSelectionDetails.isVisible"
                                            #propertyComponent
                                            (onFocus)="focusProperty(propertyComponent, property)"
                                            (onFocusOut)="onPropertyChanged(property);"
                                            (onInitialized)="initializeComponent(propertyComponent, property)"
                                            [insideLotEditor]="true"
                                            [clusterLanguages]="languages"
                                            (change)="property.changed = true; validateTranslatable(); markValueChanged(property);">
                        </translatable-field>
                      </div>

                      <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 11">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="dateValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required, 'dateValueProp' + groupIndex + rowIndex + i, lotForm)}">
                            <date-time-component ngDefaultControl
                                                 class="dt-input focusable"
                                                 [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                                 [data]="property.dateTimeValue"
                                                 (modelChanged)="dateChanged(property, $event); validateForm();"
                                                 [tabIndex]="getTabIndex(property)"
                                                 [attr.tabindex]="-1"
                                                 #propertyComponent
                                                 #dateValueProp
                                                 (onFocus)="focusProperty(propertyComponent, property)"
                                                 (onInitialized)="initializeComponent(propertyComponent, property)"
                                                 [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                                 [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                            </date-time-component>
                            <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                              {{dateErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                              {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 12">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="dateValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required, 'dateValueProp' + groupIndex + rowIndex + i, lotForm)}">
                            <date-component ngDefaultControl
                                            class="dt-input focusable"
                                            [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                            [data]="property.dateTimeValue"
                                            (modelChanged)="dateChanged(property, $event); validateForm();"
                                            [tabIndex]="getTabIndex(property)"
                                            [attr.tabindex]="-1"
                                            #propertyComponent
                                            #dateValueProp
                                            (onFocus)="focusProperty(propertyComponent, property)"
                                            (onInitialized)="initializeComponent(propertyComponent, property)"
                                            [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                            [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                            </date-component>
                            <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                              {{dateErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                              {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 13">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="dateValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required, 'dateValueProp' + groupIndex + rowIndex + i, lotForm)}">
                            <time-component ngDefaultControl
                                            class="dt-input focusable"
                                            [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                            [data]="property.dateTimeValue"
                                            (modelChanged)="dateChanged(property, $event); validateForm();"
                                            [tabIndex]="getTabIndex(property)"
                                            [attr.tabindex]="-1"
                                            #propertyComponent
                                            #dateValueProp
                                            (onFocus)="focusProperty(propertyComponent, property)"
                                            (onInitialized)="initializeComponent(propertyComponent, property)"
                                            [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                            [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                            </time-component>
                            <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                              {{dateErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                              {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 14">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="dateValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required, 'dateValueProp' + groupIndex + rowIndex + i, lotForm)}">
                            <date-component ngDefaultControl
                                            class="dt-input focusable"
                                            [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                            [data]="property.dateTimeValue"
                                            (modelChanged)="dateChanged(property, $event); validateForm();"
                                            [tabIndex]="getTabIndex(property)"
                                            [attr.tabindex]="-1"
                                            #propertyComponent
                                            #dateValueProp
                                            (onFocus)="focusProperty(propertyComponent, property)"
                                            (onInitialized)="initializeComponent(propertyComponent, property)"
                                            [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                            [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                            </date-component>
                            <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                              {{dateErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                              {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                            </span>
                          </label>
                        </div>
                      </div>

                      <div *ngIf="property.propertyTypeId === 4 && property.propertyTypeFormatId === 15">
                        <div class="form-group" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                          <label for="dateValueProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm" [ngClass]="{'invalid': isDateInvalid(dateValueProp, property.productPropertyEditorSelectionDetails.required, 'dateValueProp' + groupIndex + rowIndex + i, lotForm)}">
                            <time-component ngDefaultControl
                                            class="dt-input focusable"
                                            [name]="'dateValueProp' + groupIndex + rowIndex + i"
                                            [data]="property.dateTimeValue"
                                            (modelChanged)="dateChanged(property, $event); validateForm();"
                                            [tabIndex]="getTabIndex(property)"
                                            [attr.tabindex]="-1"
                                            #propertyComponent
                                            #dateValueProp
                                            (onFocus)="focusProperty(propertyComponent, property)"
                                            (onInitialized)="initializeComponent(propertyComponent, property)"
                                            [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                            [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled">
                            </time-component>
                            <span class="tooltip-content" *ngIf="dateErrorMessage!==''">
                              {{dateErrorMessage}}
                            </span>
                            <span class="tooltip-content" *ngIf="dateErrorMessage===''">
                              {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="image-property" *ngIf="property.propertyTypeId === 8 && property.propertyTypeFormatId === 17">
                        <div class="form-group" id="image-form-group"
                             [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                          <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">
                            {{
                            getTranslation(property.name)
                            }}
                          </label>
                          <label for="imageValueProp" aria-haspopup="true" role="tooltip"
                                 class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
                            <dx-text-box name="{{'imageValueProp'}}" [value]="property.displayUrlTextValue"
                                         [maxLength]="property.maxLength ? property.maxLength : ''" class="focusable"
                                         [rtlEnabled]="rtlEnabled" [tabIndex]="getTabIndex(property)" #propertyComponent
                                         #imageValueProp
                                         (onFocusOut)="elementBlur(property, imageValueProp, $event);"
                                         (onFocusIn)="focusProperty(propertyComponent, property, $event)"
                                         (onInitialized)="initializeComponent(propertyComponent, property)"
                                         [readOnly]="true">
                            </dx-text-box>
                            <span class="tooltip-content" *ngIf="textErrorMessage===''">
                              <!-- Required text -->
                              <ng-container *ngIf="property.productPropertyEditorSelectionDetails.required">
                                {{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}
                              </ng-container>
                              <!-- Length validation text -->
                              <ng-container *ngIf="property.maxLength !== undefined">
                                {{ 'LOTS.TEXT_FIELD_VALIDATION_LENGTH' | translate: {maxLength: property.maxLength } }}
                              </ng-container>
                            </span>
                          </label>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                        <div class="photo-thumbnail">
                          <label for="thumbnail-input">
                            <img (click)="focusProperty(propertyComponent, property, $event);" (mouseover)="showLargerImage($event, property.displayUrlImageValue);" (mouseout)="hideLargerImage($event);" [src]="property.displayUrlImageValue" />
                          </label>
                          <input id="thumbnail-input" type="file" accept=".png, .jpg, .jpeg, image/*"
                                 (input)="onImageChanged($event);" />
                        </div>
                        </div>
                        <div class="form-group"
                             [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}">
                         <div class="photo-thumbnail">
                        <dx-button id="clearButton" icon="clear" (click)="focusProperty(propertyComponent, property, $event); clearImageProperty();"></dx-button>
                        </div>
                      </div>
                      
                        <div *ngIf="largerImageVisible && largeImageSrc !== emptyPictureSource" class="large-image">
                          <img [src]="largeImageSrc" />
                        </div>
                        
                        
                      </div>
                      <div class="form-group master-validation" [ngClass]="{'hidden': !property.productPropertyEditorSelectionDetails.isVisible}" *ngIf="property.propertyTypeId === 5">
                        <label [ngClass]="{'required': property.productPropertyEditorSelectionDetails.required}">{{ getTranslation(property.name) }}</label>
                        <label for="masterDataProp" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm dd-label">
                          <masterdata-dropdown name="{{ 'masterDataProp' + groupIndex + rowIndex + i }}"
                                               #propertyComponent
                                               #masterDataProp
                                               [auctionClusterId]="catalog.auctionClusterId"
                                               [listId]="property.masterDataListId"
                                               [filteredKeys]="filteredDropdownIds[property.productPropertyId]"
                                               [(selectedRowId)]="property.masterDataListRowId"
                                               [required]="property.productPropertyEditorSelectionDetails.required"
                                               [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected"
                                               (onValueChanged)="markValueChanged(property); validateForm(); onPropertyKeyStroke(property); onPropertyChanged(property, false, $event);"
                                               (onFocusOut)="elementBlur(property, masterDataProp, $event);"
                                               (onFocusIn)="focusProperty(propertyComponent, property, $event)"
                                               (onInitialized)="initializeComponent(propertyComponent, property)"
                                               [tabIndex]="getTabIndex(property)">

                          </masterdata-dropdown>
                          <!--<ng-select ngDefaultControl
                                     [searchable]="true"
                                     [searchFn]="property.productPropertyEditorSelectionDetails.searchByStartsWith ? startsWithSearchFn : null"
                                     (clear)="property.masterDataListRowId = null"
                                     (close)="focusNextElement()"
                                     #masterDataProp="ngModel"
                                     name="{{ 'masterDataProp' + groupIndex + rowIndex + i }}"
                                     [attr.name]="'masterDataProp' + groupIndex + rowIndex + i"
                                     [(ngModel)]="property.masterDataListRowId"
                                     (change)="onPropertyChanged(property);"
                                     (blur)="elementBlur(property, masterDataProp)"
                                     [attr.tabindex]="-1"
                                     [attr.tabindexForChildren]="getTabIndex(property)"
                                     [items]="fillDropdown(property)"
                                     [required]="property.productPropertyEditorSelectionDetails.required ? '' : null"
                                     [disabled]="property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected"
                                     [virtualScroll]="true"
                                     bindLabel="label"
                                     bindValue="value">
                            <ng-template ng-label-tmp let-item="item">
                              <img style="max-width: 30px; max-height: 30px;" *ngIf="item.img" [src]="item.img" />
                              {{item.label}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                              <img style="max-width: 30px; max-height: 30px;" *ngIf="item.img" [src]="item.img" />
                              {{item.label}}
                            </ng-template>
                          </ng-select>-->
                          <!--<span class="tooltip master-data-tooltip" *ngIf="(masterDataProp.touched || masterDataProp.dirty) && masterDataProp.invalid">
                            <clr-tooltip>
                              <clr-icon shape="exclamation-circle" class="is-error" size="22"></clr-icon>
                              <clr-tooltip-content class="tooltip-content" clrPosition="bottom-left" clrSize="md">
                                <span *ngIf="masterDataErrors[i]!==''">{{masterDataErrors[i]}}</span>
                                <span *ngIf="!masterDataErrors[i] || masterDataErrors[i]===''">{{ 'SHARED.REQUIRED_FIELD_VALIDATION' | translate }}</span>
                              </clr-tooltip-content>
                            </clr-tooltip>
                          </span>-->
                        </label>
                      </div>


                    </div>
                  </div>
                </div>
                <div *ngIf="group.collapsed">...</div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" #propertyComponent class="btn btn-primary btn-add" (click)="addNew = false; onSubmit()" *ngIf="lot.inEditMode" [disabled]="savePending || lotAddingInProgress || !lotForm.form.valid || (catalog && !catalog.enableUpdate) || !isFormValid">{{ 'LOTS.CHANGE_LOT' | translate }}</button>
              <button type="submit" #propertyComponent class="btn btn-primary btn-add" (click)="addNew = true; onSubmit()" [disabled]="savePending || lotAddingInProgress || !lotForm.form.valid || (catalog && !catalog.enableCreate) || !isFormValid">{{ 'LOTS.ADD' | translate }}</button>
              <button type="button" #propertyComponent class="btn btn-primary" *ngIf="lot.inEditMode" (click)="resetEdit(true)">{{ 'SHARED.CANCEL' | translate }}</button>
            </div>
          </dx-validation-group>
        </form>
      </div>
    </div>
  </div>
  <div class="grid" [style.width]="isVertical ? getGridWidth() : '100%'">
    <dx-popup width="90%"
              height="500"
              [showTitle]="true"
              title="{{ 'GROUP_FILTER.TITLE' | translate }}"
              [dragEnabled]="false"
              [closeOnOutsideClick]="false"
              [rtlEnabled]="rtlEnabled"
              [(visible)]="groupFiltersShowing">
      <dx-scroll-view width="100%" height="100%">
        <div class="au-dx-popup-padder">
          <group-filter-component #groupFilter (close)="hideGroupFilter()"></group-filter-component>
          </div>
      </dx-scroll-view>
    </dx-popup>

    <dx-popup width="600"
              height="auto"
              [showTitle]="true"
              title="{{ 'MOVE_LOTS.TITLE' | translate }}"
              [dragEnabled]="false"
              [closeOnOutsideClick]="false"
              [rtlEnabled]="rtlEnabled"
              [(visible)]="moveLotsShowing">
      <dx-scroll-view width="100%" height="100%">
        <move-lots-component #moveLots (close)="hideMoveLots($event)"></move-lots-component>
      </dx-scroll-view>
    </dx-popup>

    <button *ngIf="catalog && !isTouchMode && (catalog.typeOfGrouping === 2 || catalog.typeOfGrouping === 3)" type="button" class="btn btn-primary" (click)="showGroupFilters()">{{ 'GROUP_FILTER.TITLE' | translate }}</button>
    <button *ngIf="!isForecastCatalog && catalog && !catalog.viewOnlyMode" type="button" class="btn btn-primary" (click)="showMoveLots()">{{ 'MOVE_LOTS.TITLE' | translate }}</button>
    <button *ngIf="enableTouchScreenLayout && catalog && !catalog.viewOnlyMode" type="button" class="btn btn-primary" (click)="opetLotEditor($event)">{{ 'MOVE_LOTS.ADD_LOT' | translate }}</button>

    <div class="grid-buttons">
      <dx-switch *ngIf="isMultiEditorModeEnabled"
                 class="switch-button"
                 [value]="!isTouchMode"
                 switchedOffText="Touch"
                 switchedOnText="Desktop"
                 [width]="80"
                 (onValueChanged)="switchEditorMode()"
                 hint="{{ 'LOTS.CHANGE_SUPPLY_EDITOR_MODE' | translate }}"></dx-switch>
      <dx-button *ngIf="!isTouchMode" hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
    </div>
    <br />
    <br />
    <dx-data-grid #lotDetailsGrid
                  [dataSource]="customDataSource"
                  id="printSection"
                  [rtlEnabled]="rtlEnabled"
                  [showBorders]="true"
                  (onInitialized)="initColumn($event)"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onRowClick)="rowClick($event)"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="!isTouchMode"></dxo-export>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-remote-operations [paging]="true" [filtering]="true" [sorting]="true"></dxo-remote-operations>
      <dxo-state-storing [enabled]="true" type="localStorage" [storageKey]="'storage_supply_lots_'+ id"></dxo-state-storing>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
      <dxi-column dataField="lotId" [visible]="false"></dxi-column>
      <dxi-column type="buttons" [width]="120" [showInColumnChooser]="false">
        <dxi-button *ngIf="!isTouchMode" hint="{{ 'SHARED.MULTI_EDIT' | translate }}" icon="detailslayout" [onClick]="multiEditItem" [visible]="viewGridIcons"></dxi-button>
        <dxi-button *ngIf="!isTouchMode" hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editItem" [visible]="viewGridIcons"></dxi-button>
        <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem" [visible]="viewGridDeleteIcon"></dxi-button>
        <dxi-button *ngIf="!isForecastCatalog" hint="{{ 'SHARED.PRINT' | translate }}" icon="print" [onClick]="printItem"></dxi-button>
      </dxi-column>
      <dxi-column dataField="isPublished" caption="{{ 'LOTS.IS_PUBLISHED' | translate }}" [cellTemplate]="'iconTemplate'">
      </dxi-column>
      <div *dxTemplate="let data of 'iconTemplate'">
        <i *ngIf="data.value" class="dx-icon-check"></i>
        <i *ngIf="!data.value" class="dx-icon-close"></i>
      </div>
    </dx-data-grid>
    <button type="button" class="btn btn-primary" (click)="publishLots()" [disabled]="isPublishDisabled()" *ngIf="catalog && !catalog.viewOnlyMode">{{ 'LOTS.PUBLISH' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="goBack()">{{ 'SHARED.BACK' | translate }}</button>
    <button #lotAmountButton type="button" class="btn btn-primary" (click)="removeLotsConfirmation.opened = true;">{{ 'LOTS.DELETE_FILTERED_LOTS' | translate: {numberOfLots: itemsToRemove.length} }}</button>
    <button type="button" class="btn btn-primary" (click)="sendNotification($event)" [disabled]="isSendNotificationDisabled">{{ 'SHARED.SEND_NOTIFICATION' | translate }}</button>
    <span *ngIf="hasLastTimeSentNotification()" class="last-time-sent">{{ 'LOTS.LAST_SENT_NOTIFICATION' | translate }} {{lastTimeSentNotification}}</span>
    <div class="master-details-grid" [ngClass]="{'vertical': isVertical, 'master-details-showing': isMasterDetailsShowing}">
      <dx-data-grid #masterDetailsGrid
                    *ngIf="!isTouchMode"
                    id="masterDetailsGrid"
                    [rtlEnabled]="rtlEnabled"
                    [showBorders]="true"
                    (onInitialized)="initColumnMaster($event)"
                    [allowColumnReordering]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    [rowAlternationEnabled]="true"
                    (onRowClick)="rowClickMaster($event)">
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-pager [visible]="true"
                   [showPageSizeSelector]="true"
                   [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                   [showNavigationButtons]="true"
                   [showInfo]="true"
                   infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
        <dxi-column dataField="isPublished" caption="{{ 'LOTS.IS_PUBLISHED' | translate }}" [cellTemplate]="'iconTemplate'">
        </dxi-column>
        <div *dxTemplate="let data of 'iconTemplate'">
          <i *ngIf="data.value" class="dx-icon-check"></i>
          <i *ngIf="!data.value" class="dx-icon-close"></i>
        </div>
      </dx-data-grid>
    </div>

    <confirmation-component #confirmation title="{{ 'LOTS.DELETE_LOT' | translate }}"
                            message="{{ (showActionsMessage ? 'LOTS.DELETE_LOT_MESSAGE_WITH_ACTION' : 'LOTS.DELETE_LOT_MESSAGE') | translate }}"
                            buttonCloseText="DELETE" cancellable="true" (close)="deleteSelected();"></confirmation-component>
    <publish-lot-component #publishLotComponent
                           (publish)="manualRefresh(); getMasterDetails();"></publish-lot-component>
    <lot-multi-edit-component #multiEditComponent
                              (edited)="manualRefresh(); getMasterDetails();"></lot-multi-edit-component>
    <confirmation-component #removeLotsConfirmation title="{{ 'LOTS.DELETE_LOTS' | translate }}"
                            message="{{ (showActionsMessage ? 'LOTS.DELETE_LOTS_MESSAGE_WITH_ACTION' : 'LOTS.DELETE_LOTS_MESSAGE') | translate:{numberOfLots: itemsToRemove.length} }}"
                            buttonCloseText="DELETE" cancellable="true" (close)="removeFilteredLots();"></confirmation-component>
  </div>
</div>
