import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { FullListComponent } from '../../../shared/components/full-list/full-list.component';
import { ProductFunctionkeysActionComponent } from './product-functionkeys-action.component';

// models
import { ProductFunctionKeysAction, ProductFunctionKeysActionEnum } from '../../../shared/models/product';

@Component({
  selector: 'product-functionkeys-actions-component',
  templateUrl: './product-functionkeys-actions.component.html'
})
export class ProductFunctionkeysActionsComponent extends FullListComponent<ProductFunctionKeysAction, ProductFunctionkeysActionComponent> implements OnInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('details') detailsComponent: ProductFunctionkeysActionComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @Input('productFunctionkeysActions')
  set data(value: Array<ProductFunctionKeysAction>) {
    this.items = value;
  }

  @Output() dataChanged = new EventEmitter<boolean>();

  constructor(
    protected injector: Injector,
  ) {
    super(injector, ProductFunctionKeysAction);
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.setTranslations('PRODUCT.FUNCTIONKEYS_ACTION');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  add() {
    this.detailsComponent.modalTitle = this.translations.ADD_NEW;
    this.detailsComponent.open(this.items, null);
  }

  edit = (e: any) => {
    const id = e.row !== undefined ? e.row.data.id : e.data.id;
    this.detailsComponent.modalTitle = this.translations.EDIT;
    this.detailsComponent.open(this.items, id);
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = e.row.data.id;
    this.confirmation.opened = true;
  }

  deleteSelected() {
    this.items.splice(this.items.indexOf(this.items.filter(_ => _.productFunctionKeysActionId == this.itemIdToDelete)[0]), 1);
    this.dataChanged.emit();
  }

  onDetailsClosed(expression: ProductFunctionKeysAction) {
    if (this.items.some(_ => _.productFunctionKeysActionId === expression.productFunctionKeysActionId)) {
      this.items[this.items.indexOf(this.items.filter(_ => _.productFunctionKeysActionId == expression.productFunctionKeysActionId)[0])] = expression;
    }
    else {
      this.items.push(expression);
    }
    this.dataChanged.emit();
  }
  

  getActionName = (e: any) => {
    const sourceType = e.value;
    switch (sourceType) {
      case ProductFunctionKeysActionEnum.JUMP_TO:
        return this.translations.JUMP_TO;
      default:
        return this.translations.JUMP_TO;
    }
  }
}