import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import { IAuctionService } from '../interfaces/auction';
import { ICatalogService } from '../interfaces/catalog';
import { IProductService } from '../interfaces/product';
import { IReportService } from '../interfaces/report';

// models
import { GridProperty, ProductProperty } from '../models/product';
import { Report } from '../models/report';
import { ApplicationSettings } from '../models/application-settings';
import { MasterData } from '../models/master-data';
import { PublicAuction } from '../models/public-auction';
import { PublicAuctionCluster } from '../models/public-auction-cluster';
import { PublicCatalog } from '../models/public-catalog';
import { SupplyForecastGeneric } from '../models/supply-forecast-generic';
import { SupplyForecastPublicUrk } from '../models/supply-forecast-public-urk';

// services
import { WebApiService } from './web-api.service';


@Injectable()
export class PublicDataService implements IAuctionService, ICatalogService, IReportService, IProductService {

  private apiPath: string;

  constructor(
    private appSettings: ApplicationSettings,
    private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  getPublicAuctionClustersWithForecast(): Observable<Array<PublicAuctionCluster>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/public/forecast');
  }

  getPublicAuctionClusters(): Observable<Array<PublicAuctionCluster>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/public');
  }


  getPublicAuctionsForCluster(auctionClusterId: number): Observable<Array<PublicAuction>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/' + auctionClusterId + '/auction/public');
  }

  getPublicAuctions(): Observable<Array<PublicAuction>> {
    return this.webApiService.getList(this.apiPath + 'auction/public');
  }

  getPublicCatalogs(auctionId: number): Observable<Array<PublicCatalog>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/auction/' + auctionId + '/catalog/forecast/public');
  }

  getPublicCatalogsForCluster(auctionClusterId: number): Observable<Array<PublicCatalog>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/' + auctionClusterId + '/auction/catalog/supply/public');
  }

  getPublishedLot(auctionId: number, catalogId: number, timeZoneOffset: number, locale: string): Observable<SupplyForecastGeneric> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/auction/' + auctionId + '/catalog/' + catalogId + '/lot/published/' + timeZoneOffset + '/' + locale); // tslint:disable-line:max-line-length
  }

  getPublicSupplyForecast(date: string, auctionId: number = null): Observable<SupplyForecastPublicUrk> {
    return this.webApiService.getSingle(this.apiPath + 'publicforecast/date/' + date + '?auctionid=' + auctionId);
  }

  getPublicReports(): Observable<Array<Report>> {
    return this.webApiService.getList(this.apiPath + 'reports/public');
  }

  getPublicReport(reportId: number): Observable<Report> {
    return this.webApiService.getList(this.apiPath + 'reports/' + reportId + '/public');
  }

  getPublicMasterDataList(masterDataListId: number): Observable<MasterData> {
    return this.webApiService.getList(this.apiPath + 'masterdatalist/' + masterDataListId + '/public');
  }

  //IAuctionService

  getAuctionsForReports(auctionClusterId: number) {
    return this.webApiService.getList(this.apiPath + 'auction/public');
  }

  //ICatalogService
  getAllSupplyAndForecastCatalogsForReports(auctionClusterId: number) {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/' + auctionClusterId + '/auction/catalog/supply/public');
  }

  //IReportService

  getReport(reportId: number) {
    return this.webApiService.getList(this.apiPath + 'reports/' + reportId + '/public');
  }

  downloadExport(reportId: number, reportRequest: any) {
  }

  requestDownload(reportId: number, reportRequest: any) {
    const url = this.apiPath + 'reports/public/requestdownload/' + reportId;
    return this.webApiService.downloadWithPost(url, reportRequest);
  }

  editReport(report: any) {
  }

  saveReport(report: any) {
  }

  getStoredProcedureValuesForProductProperty(reportId: number, filter: any) {
  }

  getStoredProcedureValuesForNumberProductProperty(reportId: number, filter: any) {
  }

  getStoredProcedureValuesForStringProductProperty(reportId: number, filter: any) {
  }

  getStoredProcedureValuesForDateProductProperty(reportId: number, filter: any) {
  }

  //IProductService
  getProductForReports(auctionClusterId: number, productId: number) {
    return null;
  }

  getProductProperties(productId: number): Observable<Array<ProductProperty>> {
    return null;
  }

  getReportProperties(productId: number, reportDesignId: number): Observable<Array<GridProperty>> {
    return null;
  }
}
