import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interface
import { IProductService } from '../../../shared/interfaces/product';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { GridProperty, Product, ProductFunctionKeysExpression, ProductProperty } from '../../../shared/models/product';
import { SystemProductProperty } from '../../../shared/models/system-product-property';
import { Report } from '../../../shared/models/report';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class ProductService implements IProductService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getProducts(auctionClusterId: number, langCode: string): Observable<Array<Product>> {
    return this.webApiService.getList(`${this.apiPath}/${auctionClusterId}/product?langCode=${langCode}`);
  }

  getProduct(auctionClusterId: number, productId: number): Observable<Product> {
    return this.webApiService.get(`${this.apiPath}/${auctionClusterId}/product`, productId);
  }

  save(auctionClusterId: number, product: Product): Observable<any> {
    return this.webApiService.save<Product>(`${this.apiPath}/${auctionClusterId}/product`, product);
  }

  edit(auctionClusterId: number, product: Product): Observable<any> {
    return this.webApiService.edit<Product>(`${this.apiPath}/${auctionClusterId}/product`, product.productId, product);
  }

  delete(auctionClusterId: number, productId: number): Observable<any> {
    return this.webApiService.delete(`${this.apiPath}/${auctionClusterId}/product`, productId);
  }

  getSystemProductProperties(): Observable<Array<SystemProductProperty>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'systemproductproperty');
  }

  getReportTypes(): Observable<Array<Report>> {
    return this.webApiService.getList(this.appSettings.adminApi + 'report');
  }

  getNewProductModel(auctionClusterId: number): Observable<Product> {
    return this.webApiService.getSingle(`${this.apiPath}/${auctionClusterId}/product/new`);
  } 
  
  checkExpression(auctionClusterId: number, productId: number, expression: ProductFunctionKeysExpression): Observable<ProductFunctionKeysExpression> {
    return this.webApiService.save<ProductFunctionKeysExpression>(`${this.apiPath}/${auctionClusterId}/product/${productId}/checkExpression`, expression);
  }

  //IProductService

  getProductForReports(auctionClusterId: number, productId: number)
  {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/product', productId);
  }

  getProductProperties(productId: number, langCode: string): Observable<Array<ProductProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/productproperties?langCode=${langCode}`);
  }

  getReportProperties(productId: number, reportDesignId: number): Observable<Array<GridProperty>> {
    return this.webApiService.getSingle(`${this.appSettings.adminApi}product/${productId}/reportproperties/${reportDesignId}`);
  }
}
