<div class="buyer-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addBuyer [width]="850" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
            [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" *ngIf="isOpened" [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <div class="form" *ngIf="!isEditMode">
          <div class="dx-fieldset">
            <div class="dx-field">
            <div class="dx-field-label">{{ 'SHARED.VAT' | translate }}</div>
              <div class="dx-field-value">
                <dx-text-box [(value)]="vatValue" (onValueChanged)="searchByVat($event)"></dx-text-box>
              </div>
            </div>
            <div class="dx-field">
            <div class="dx-field-label">{{ 'SHARED.SEARCH' | translate }}</div>
              <div class="dx-field-value">
                <dx-button icon="search" (onClick)="searchByVat($event)"></dx-button>
              </div>
            </div>
          </div>
        </div>

        <div class="dx-fieldset" *ngIf="!isEditMode">
          <div class="dx-field">
            <div class="dx-field-value">
              <dx-select-box [items]="filteredBuyers" displayExpr="name" valueExpr="buyerId" [(value)]="model.buyerId"
                             [rtlEnabled]="rtlEnabled" (onOptionChanged)="buyersChange($event)"></dx-select-box>
            </div>
          </div>
        </div>

        <div class="form-group" *ngIf="isEditMode">
        <label class="buyer-name" for="buyerName">{{'SHARED.BUYER_NAME' | translate}}:&nbsp;{{model.name}}</label>
        </div>
      </div>
      <dx-form #supplierForm id="buyerForm" validationGroup="buyerData" [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">

            <dxi-item itemType="group">
              <dxi-item dataField="vat" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.VAT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.VAT' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="name" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="address1" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.ADDRESS1' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ADDRESS1' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="address2" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.ADDRESS2' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="zip" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.ZIP' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.ZIP' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="city" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.CITY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.CITY' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="country" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.COUNTRY' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.COUNTRY' | translate }}  {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="telNumber" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.TEL_NUMBER' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.TEL_NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="faxNumber" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.FAX_NUMBER' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="email" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.EMAIL' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
                <dxi-validation-rule type="email" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="url" [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.URL' | translate }}"></dxo-label>
              </dxi-item>

              <dxi-item dataField="isActiveOnAuctionCluster" editorType="dxCheckBox"
                        [editorOptions]="{disabled: model.usedOnOtherAuctionCluster}">
                <dxo-label text="{{ 'SHARED.IS_ACTIVE' | translate }}" location="right"></dxo-label>
              </dxi-item>

              <dxi-item dataField="userLicenceCount" editorType="dxNumberBox">
                <dxo-label text="{{ 'BUYER.USER_LICENCES' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'BUYER.USER_LICENCES' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item name="show-administrator" [visible]="!isEditMode">
                <dxo-label text="{{ 'AUCTIONS_CLUSTERS.BUYER_USER_LABEL' | translate }}"></dxo-label>
                <div *dxTemplate>
                  <dx-check-box [(value)]="administrator.visible">
                  </dx-check-box>
                </div>
              </dxi-item>

              <dxi-item #administrator name="administrator" dataField="administratorId" [visible]="!isEditMode"
                        editorType="dxSelectBox" [editorOptions]="{ items: users, valueExpr:'userId', displayExpr:'lastname'}"
                        [rtlEnabled]="rtlEnabled">
              </dxi-item>

            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.AUCTION_CLUSTER' | translate }}">
            <dxi-item itemType="group">
              <dxi-item dataField="buyerNumber">
                <dxo-label text="{{ 'SHARED.NUMBER' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'SHARED.NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item dataField="creditLimit" editorType="dxNumberBox">
                <dxo-label text="{{ 'BUYER.LIMIT' | translate }}"></dxo-label>
                <dxi-validation-rule type="required" message="{{ 'BUYER.LIMIT_VALIDATION' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-item>
              <dxi-item name="remainingLimit">
                <dxo-label text="{{ 'BUYER.REMAINING_LIMIT' | translate }}"></dxo-label>
                <div *dxTemplate>
                  <dx-text-box [(value)]="model.creditLimit - model.backOfficeCreditSpent" [disabled]="true">
                  </dx-text-box>
                </div>
              </dxi-item>
              <dxi-item dataField="blockedOnCreditLimit" editorType="dxCheckBox" [editorOptions]="{ onValueChanged: onBlockedOnCreditLimitChanged }">
                <dxo-label text="{{ 'BUYER.BLOCKED_FOR_BUYING' | translate }}" location="right"></dxo-label>
              </dxi-item>
              <dxi-item dataField="disableLimitCheck" editorType="dxCheckBox">
                <dxo-label text="{{ 'BUYER.DISABLE_LIMIT_CHECK' | translate }}" location="right"></dxo-label>
              </dxi-item>
              <dxi-item dataField="sendSmsTransactionInfo" editorType="dxCheckBox">
                <dxo-label text="{{ 'SHARED.SEND_SMS_TRANSACTION_INFO' | translate }}" location="right"></dxo-label>
              </dxi-item>

              <dxi-item dataField="currencyId" editorType="dxSelectBox" [editorOptions]="{ items: currencies, valueExpr:'currencyId',
                  displayExpr:'shortName'}">
                <dxo-label text="{{ 'SHARED.CURRENCY' | translate }}"></dxo-label>
              </dxi-item>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'BUYER.CLOCK_ACCESS' | translate }}">
            <dxi-item dataField="accessToAllAuctionsAndClocks" editorType="dxCheckBox">
              <dxo-label text="{{ 'BUYER.ACCESS_TO_ALL_AUCTIONS' | translate }}" location="right"></dxo-label>
            </dxi-item>
            <dxi-item *ngIf="!model.accessToAllAuctionsAndClocks">
              <dx-data-grid [rtlEnabled]="rtlEnabled"
                            [(dataSource)]="model.buyerAuctionClocks"
                            [showBorders]="true"
                            [allowColumnReordering]="true"
                            [hoverStateEnabled]="true"
                            [columnAutoWidth]="true"
                            [rowAlternationEnabled]="true"
                            (onInitNewRow)="onBuyerClockInitNewRow($event)"
                            (onRowInserted)="onBuyerClockRowInserted($event)"
                            (onEditorPreparing)="onEditorPreparing($event)">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"
                             [useIcons]="true">
                </dxo-editing>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]"
                           [showNavigationButtons]="true">
                </dxo-pager>
                <dxi-column dataField="auctionId" caption="{{ 'SHARED.AUCTION' | translate }}">
                  <dxo-lookup [dataSource]="auctions" displayExpr="name" valueExpr="auctionId">
                  </dxo-lookup>
                </dxi-column>
                <dxi-column dataField="clockId" caption="{{ 'BUYER.CLOCK' | translate }}">
                  <dxo-lookup [dataSource]="getFilteredClocks" [displayExpr]="translateSelectBoxes" valueExpr="clockId">
                  </dxo-lookup>
                </dxi-column>
                <dxi-column dataField="blockedForBuying"
                            dataType="boolean"
                            caption="{{ 'BUYER.BLOCKED_FOR_BUYING' | translate }}"
                            [(allowEditing)]="!model.blockedOnCreditLimit"
                            [(allowUpdating)]="!model.blockedOnCreditLimit">
                </dxi-column>
              </dx-data-grid>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'SHARED.SUBBUYER' | translate }}">
            <!--<sub-buyers-component [buyers]="allItems" [data]="model.subbuyers" (dataChanged)="saveWithoutClose()"></sub-buyers-component>-->
            <dx-data-grid [rtlEnabled]="rtlEnabled"
                          [(dataSource)]="model.subbuyers"
                          [showBorders]="true"
                          [allowColumnReordering]="true"
                          [hoverStateEnabled]="true"
                          [columnAutoWidth]="true"
                          [rowAlternationEnabled]="true"
                          (onEditorPreparing)="onEditorPreparing($event)"
                          (onRowValidating)="onRowValidating($event)"
                          (onRowInserted)="onRowInserted($event)"
                          (onRowUpdated)="onRowUpdated($event)"
                          (onRowRemoved)="onRowRemoved($event)"
                          (onInitialized)="initSubBuyersTable($event)">
              <dxo-selection mode="single"></dxo-selection>
              <dxo-editing mode="cell"
                           [allowUpdating]="true"
                           [allowAdding]="true"
                           [allowDeleting]="true"
                           [useIcons]="true">
              </dxo-editing>
              <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]" [showNavigationButtons]="true">
              </dxo-pager>
              <dxi-column dataField="buyerId" caption="{{ 'SHARED.SUBBUYER' | translate }}"
                          [editorOptions]="{showClearButton: true}">
                <dxo-lookup [dataSource]="buyersPaginated" displayExpr="name" valueExpr="buyerId">
                </dxo-lookup>
                <dxi-validation-rule type="required" message="{{ 'SHARED.SUBBUYER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
              </dxi-column>
              <dxi-column dataField="orderNumber" caption="{{ 'SHARED.SUBNUMBER' | translate }}"
                          dataType="number">
                <dxi-validation-rule type="required" message="{{ 'SHARED.SUBNUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
                </dxi-validation-rule>
                <dxi-validation-rule type="custom" [validationCallback]="validateScope"
                                     message="{{ 'BUYER.NUMBER_OUT_OF_SCOPE' | translate }}">
                </dxi-validation-rule>
              </dxi-column>
              <dxi-column dataField="buyerSubBuyerOverrule" caption="{{ 'BUYER.OVERRULE' | translate }}"
                          dataType="boolean">
              </dxi-column>
            </dx-data-grid>
          </dxi-tab>
          <dxi-tab *ngIf="secondRestrictionMasterDataName" title="{{ secondRestrictionMasterDataName | translate }}">
            <dxi-item>
              <dx-data-grid [rtlEnabled]="rtlEnabled"
                            [(dataSource)]="model.buyerSecondRestrictionMasterData"
                            [showBorders]="true"
                            [allowColumnReordering]="true"
                            [hoverStateEnabled]="true"
                            [columnAutoWidth]="true"
                            [rowAlternationEnabled]="true"
                            (onInitNewRow)="onBuyerRestrictionInitNewRow($event)"
                            (onInitialized)="initSecondRestrictionTable($event)">
                <dxo-selection mode="single"></dxo-selection>
                <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="true" [allowDeleting]="true"
                             [useIcons]="true">
                </dxo-editing>
                <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]"
                           [showNavigationButtons]="true">
                </dxo-pager>
                <dxi-column dataField="masterDataListRowId" caption="{{ secondRestrictionMasterDataName | translate }}">
                  <dxo-lookup [dataSource]="masterDataListValues" displayExpr="masterDataListValue" valueExpr="masterDataListRowId">
                  </dxo-lookup>
                </dxi-column>
                <dxi-column dataField="blocked"
                            dataType="boolean"
                            caption="{{ 'BUYER.BLOCKED_FOR_BUYING' | translate }}">
                </dxi-column>
              </dx-data-grid>
            </dxi-item>
          </dxi-tab>
        </dxi-item>
      </dx-form>
      <div class="au-dx-popup-padder">
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnBuyerSubmit" disabled="disabled">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<save-confirmation-component #saveConfirmation title="{{ 'SHARED.LEAVE_PAGE' | translate }}" buttonCloseText="OK"
                             cancellable="true" (close)="onCancel();"></save-confirmation-component>
<confirmation-component #licenseConfirmation buttonCloseText="{{ 'OK' | translate }}" title="{{ 'ERRORS.ALL_BUYER_LICENCES_USED' | translate }}"></confirmation-component>
