import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';

// components
import { DxFormComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// models
import { Language } from '../../shared/models/language';
import { Product } from '../../shared/models/product';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';

import { Calculation, CalculationFieldTypes, CalculationMathTypes, CalculationFrontEndTypes } from '../shared/models/calculation';

// services
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../shared/services/language.service';

import { CalculationsService } from '../shared/services/calculations.service';
import { ProductService } from '../shared/services/product.service';


@Component({
    selector: 'calculation-component',
    templateUrl: 'calculation.component.html'
})
export class CalculationComponent extends ItemDetailsComponent<Calculation> implements OnInit, OnDestroy {

    @ViewChild('calculationName') calculationName: TranslatableFieldComponent;

    auctionClusterId: number;
    languages: Array<Language> = [];
    calculationTypes: any = CalculationFieldTypes;
    propertyTypes: any = ProductPropertyTypeEnum;
    mathTypes: any = CalculationMathTypes;
    frontEndTypes: any = CalculationFrontEndTypes;
    products: Array<Product> = [];
    translationSelectBoxes: any;
    validFields: boolean = false;

    rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
    private _subscription: Subscription;
    @ViewChild('editCalculationsForm', { read: DxFormComponent }) editCalculationsForm: DxFormComponent;

    constructor(
        protected injector: Injector,
        private dataService: CalculationsService,
        private languageService: LanguageService,
        private productService: ProductService,
        private translateService: TranslateService,
        private route: ActivatedRoute
    ) {
        super(injector);
        this.calculationTypes = Object.keys(this.calculationTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.calculationTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
        this.propertyTypes = Object.keys(this.propertyTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.propertyTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
        this.mathTypes = Object.keys(this.mathTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.mathTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
        this.frontEndTypes = Object.keys(this.frontEndTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.frontEndTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
        this._subscription = this.languageService.direction.subscribe(dir => {
            this.rtlEnabled = dir;
        });
        this.translate.get('SHARED').subscribe((res: string) => {
            this.translationSelectBoxes = res;
        });
    }

    ngOnInit() {
        this.model = new Calculation();
        this.auctionClusterId = +this.route.snapshot.params['id'];
        this.setTranslations('CALCULATIONS');
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    open(calculationId: number) {
        forkJoin(
            this.productService.getProducts(this.auctionClusterId, this.translateService.currentLang),
            this.languageService.getAuctionClusterLanguages(this.auctionClusterId)
        ).subscribe(async results => {
            this.products = results[0];
            this.languages = results[1]

            // EDIT
            if (calculationId != null) {
                this.modalTitle = this.translations.EDIT;
                this.isEditMode = true;
                this.spinner.show();
                this.dataService.getCalculation(this.auctionClusterId, calculationId, this.translateService.currentLang)
                    .subscribe((res: Calculation) => {
                        this.model = res;
                        this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.model.name);

                        this.isOpened = true;
                        this.spinner.hide();
                    },
                        error => {
                            this.errorService.show(error);
                            this.spinner.hide();
                        });
                // NEW
            } else {
                this.modalTitle = this.translations.ADD_NEW;
                this.model = new Calculation();
                this.isEditMode = false;
                this.isOpened = true;

                // Create empty JSON object for translation fields
                const emptyTranslation = {};
                this.languages.forEach(lang => {
                    emptyTranslation[lang.code] = '';
                });

                this.model.name = JSON.stringify(emptyTranslation);
            }
        },
            error => {
                this.errorService.show(error);
                this.spinner.hide();
            });
    }

    save() {
        this.model.name = this.calculationName.data;
        this.model.calculationFields.forEach(_ => _.productProperty = null);

        if (this.isEditMode) {
            this.spinner.show();
            this.dataService.edit(this.model)
                .subscribe((res: any) => {
                    this.model = new Calculation();
                    // this.detailsForm.reset();
                    this.close(true);
                    this.errorMessage = null;
                    this.spinner.hide();
                },
                    error => {
                        this.errorService.show(error);
                        this.spinner.hide();
                    });
        } else {
            this.spinner.show();
            this.model.auctionClusterId = this.auctionClusterId;

            this.dataService.save(this.model)
                .subscribe((res: any) => {
                    this.model = new Calculation();
                    // this.detailsForm.reset();
                    this.close(true);
                    this.errorMessage = null;
                    this.spinner.hide();
                },
                    error => {
                        this.errorService.show(error);
                        this.spinner.hide();
                    });
        }
    }

    onFieldDataChanged(e: any) {
        if ((e.component._isReady && e.component.NAME !== 'dxPopup') || e.component.NAME === 'dxForm') {
            const result = e.component.validate();
            if (result.brokenRules.length == 0 || this.validFields) {
                document.getElementsByName('btnCalculationsSubmit')[0].removeAttribute('disabled');
            } else {
                document.getElementsByName('btnCalculationsSubmit')[0].setAttribute('disabled', 'disabled');
            }
        } else {
            if (this.isEditMode && this.validFields) {
                document.getElementsByName('btnCalculationsSubmit')[0].removeAttribute('disabled');
            } else {
                document.getElementsByName('btnCalculationsSubmit')[0].setAttribute('disabled', 'disabled');
            }
        }
    }

    onChangeTranslationField = (e: any, fieldName: string) => {
        if (fieldName === 'name') {
            this.validFields = this.languageService.checkDefaultLanguageNotEmpty(this.languages, this.calculationName.data);
            this.editCalculationsForm.instance.updateData(fieldName, this.calculationName.data);
        }
    }

    translateSelectBoxes = (item: any) => {
        if (item) {
            const label = this.translationSelectBoxes[item.name];
            return label;
        }
    }
}
