import { Component, Output, OnInit, Injector, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { Language } from '../../shared/models/language';
import { MasterData } from '../../shared/models/master-data';
import { ProductProperty, ProductPropertyType } from '../../shared/models/product';
import { SystemProductProperty } from '../../shared/models/system-product-property';

import { Calculation } from '../shared/models/calculation';
import { DataFormat } from '../shared/models/product-property-types';
import { LookupTable } from '../shared/models/lookup-table';

// services
import { LanguageService } from '../../shared/services/language.service';

import { CalculationsService } from '../shared/services/calculations.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { ProductPropertyTypeService } from '../shared/services/product-property-type.service';


@Component({
  selector: 'product-property-component',
  templateUrl: 'product-property.component.html'
})
export class ProductPropertyComponent extends ItemDetailsComponent<ProductProperty> implements OnInit {
  languages: Array<Language> = [];
  productPropertyTypes: Array<ProductPropertyType> = [];
  masterDatas: Array<MasterData> = [];
  lookupTables: Array<LookupTable> = [];
  calculations: Array<Calculation> = [];
  systemProductPropertyTypes: Array<SystemProductProperty> = [];
  filteredSystemProductPropertyTypes: Array<SystemProductProperty> = [];
  filteredMasterDatas: Array<MasterData> = [];
  dataFormats: Array<DataFormat> = [];
  productProperty: ProductProperty;
  productId: number;
  isSystemField: boolean;
  availablePropertiesForCaption: Array<ProductProperty> = [];


  @ViewChild('productPropertyName') productPropertyName;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<ProductProperty>();
  @Output() onUpdated = new EventEmitter<ProductProperty>();
  // tslint:enable:no-output-on-prefix

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private lookupTableService: LookupTableService,
    private dataFormatsService: ProductPropertyTypeService,
    private calculationService: CalculationsService,
    protected route: ActivatedRoute
  ) {
    super(injector);
  }

  ngOnInit() {
    this.model = new ProductProperty();
  }

  getCalculations() {
    const ppId = this.model.propertyTypeId ? this.model.propertyTypeId : this.productProperty.propertyTypeId;
    const productId = this.model.productId ? this.model.productId : this.productId;

    if (!ppId || !productId) {
      this.calculations = [];
      return;
    }

    this.route.paramMap
        .subscribe(params => {
          if (params.get('id')) {
            this.calculationService.getCalculationForPropertyType(+params.get('id'), productId, ppId)
              .subscribe(c => {
                this.calculations = c;
             });
          }
        });
  }

  getLookups() {
    const ppId = this.model.propertyTypeId ? this.model.propertyTypeId : this.productProperty.propertyTypeId;
    const mdlId = this.model.masterDataListId ? this.model.masterDataListId : this.productProperty.masterDataListId;

    if (!ppId) {
      this.lookupTables = [];
      return;
    }

    if (ppId === ProductPropertyTypeEnum.MASTER_DATA) {
      if (!mdlId) {
        this.lookupTables = [];
        return;
      }
      this.route.paramMap
        .subscribe(params => {
          if (params.get('id')) {
            this.lookupTableService.getLookupTablesForMasterDataProperty(+params.get('id'),
              this.productId, mdlId)
              .subscribe((res: any) => {
                this.lookupTables = res;
                this.lookupTables.forEach(lt => {
                  lt.lookupTableText = this.languageService.getTranslatableText(lt.name);
                });
              },
                error => {
                  this.lookupTables = [];
                });
          }
        });
    } else {
      this.route.paramMap
        .subscribe(params => {
          if (params.get('id')) {
            this.lookupTableService.getLookupTablesForResultProperty(+params.get('id'),
              this.productId, ppId)
              .subscribe((res: any) => {
                this.lookupTables = res;
                this.lookupTables.forEach(lt => {
                  lt.lookupTableText = this.languageService.getTranslatableText(lt.name);
                });
              },
                error => {
                  this.lookupTables = [];
                });
          }
        });
    }
  }

  resetPropertyTypeFormat() {
    this.model.propertyTypeFormatId = null;
    this.model.imageCaptionId = null;
  }

  resetImageCaption() {
    this.model.imageCaptionId = null;
  }

  filterDataFormats() {
    if (this.model.propertyTypeId === ProductPropertyTypeEnum.DATE || this.model.propertyTypeId === ProductPropertyTypeEnum.DECIMAL
      || this.model.propertyTypeId === ProductPropertyTypeEnum.URL) {
      this.dataFormatsService.getFormats(this.model.propertyTypeId).subscribe(res => {
        this.dataFormats = res;
      });
    }
  }

  open(productProperties: Array<ProductProperty>, productProperty: ProductProperty, productId: number,
    languages: Array<Language>, productPropertyTypes: Array<ProductPropertyType>,
    masterDataList: Array<MasterData>, systemProductPropertyTypes: Array<SystemProductProperty>) {

    this.allItems = productProperties;
    this.availablePropertiesForCaption = productProperties.filter(_ => _.productPropertyId !== productProperty.productPropertyId);
    this.productProperty = productProperty;
    this.languages = languages;
    this.masterDatas = masterDataList;
    this.productPropertyTypes = productPropertyTypes.filter(ppt => ppt.propertyTypeId !== ProductPropertyTypeEnum.IMAGE);
    this.systemProductPropertyTypes = systemProductPropertyTypes;
    this.productId = productId;

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

   

    if (productProperty.name !== null && productProperty.name !== undefined) {
      this.isEditMode = true;
      this.model = productProperty;
      this.filterSystemPropertyTypes(false);
      this.filterMasterDatas();
      this.filterDataFormats();
      this.isOpened = true;
    } else {
      this.model = productProperty;
      this.isEditMode = false;
      this.isOpened = true;

      // Create empty JSON object for translation fields
      const emptyTranslation = {};
      this.languages.forEach(lang => {
        emptyTranslation[lang.code] = '';
      });

      this.model.name = JSON.stringify(emptyTranslation);
    }

    this.getLookups();
    this.getCalculations();
  }

  getDateFilterProductPropertyOptions() {
    return this.allItems.filter(p => {
      return p.propertyTypeId === ProductPropertyTypeEnum.DATE;
    });
  }

  isDateFilterDropdownVisible() {
    if (this.model.lookupTableId) {
      const lookup = this.lookupTables.find(lt => {
        return lt.lookupTableId === this.model.lookupTableId;
      });

      if (lookup && lookup.hasPeriodFilter) {
        return true;
      }
    }

    return false;
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  save() {
    this.model.name = this.productPropertyName.data;

    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new ProductProperty();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  filterSystemPropertyTypes(resetSystemFieldId: boolean) {
    if (resetSystemFieldId) {
      this.model.systemProductPropertyType = null;
    }
    this.filteredSystemProductPropertyTypes = this.systemProductPropertyTypes.filter(sppt => sppt.propertyTypeId === this.model.propertyTypeId); // tslint:disable-line:max-line-length
  }

  filterMasterDatas() {
    this.filteredMasterDatas = [];
    if (this.model.systemProductPropertyType) {
      this.isSystemField = true;
      const systemProductProp = this.filteredSystemProductPropertyTypes.find(sppt => sppt.systemProductPropertyId === this.model.systemProductPropertyType); // tslint:disable-line:max-line-length
      if (systemProductProp) {
        const masterData = this.masterDatas.find(md => md.systemListType === systemProductProp.masterDataSystemType);
        if (masterData) {
          this.model.masterDataListId = masterData.masterDataListId;
          this.getLookups();
        } else {
          // this.model.masterDataListId = null;
          this.isSystemField = false;
        }
      }
    } else {
      this.isSystemField = false;
      this.filteredMasterDatas = this.masterDatas;
    }
  }
}
