import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import DataGrid from 'devextreme/ui/data_grid';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';

// components
import { AuctionTransactionDetailComponent } from './auction-transaction-detail.component';
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';

// models
import { ImportField, ImportSchema } from '../../shared/import/import-schema';
import { ExportConstants } from '../../shared/constants/export.constants';
import { DateTimeTypeEnum } from '../../shared/enum/dateTimeTypeEnum';
import { NotificationTypeEnum } from '../../shared/enum/notificationTypeEnum';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { UrlTypeEnum } from '../../shared/enum/urlTypeEnum';
import { AuctionCluster } from '../../shared/models/auction-cluster';
import { AuctionClusterNotificationTimeSent } from '../../shared/models/auction-cluster-notification-time-sent';
import { AuctionClusterPermissionEnum } from '../../shared/models/user-permissions';
import { Catalog } from '../../shared/models/catalog';
import { ConfirmationStatus, LotStatus, TransactionMonitorLot, TransactionMonitorLotColumn, TransactionMonitorLotRow } from '../../shared/models/transaction-monitor-lot';
import { SystemReportDesignTypes } from '../../shared/models/report';
import { User } from '../../shared/models/user';

// services
import { TranslateService } from '@ngx-translate/core';
import { TitleService } from '../../shared/services/title.service';
import { UserService } from '../../shared/services/user.service';

import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';
import { AuctionClusterReportService } from '../shared/services/report.service';
import { AuctionTransactionMonitorService } from '../shared/services/auction-transaction-monitor.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'auction-transaction-monitor-component',
  templateUrl: 'auction-transaction-monitor.component.html',
  styleUrls: ['./auction-transaction-monitor.component.scss']
})
export class AuctionTransactionMonitorComponent extends ItemListComponent<TransactionMonitorLotRow> implements OnInit, OnDestroy {

  auctionClusterId: number = null;
  auctionClusters: Array<AuctionCluster> = [];
  currentLot: TransactionMonitorLot = null;
  catalogId: number = null;
  auctionId: number = null;
  catalogs: Array<Catalog> = [];
  date: Date = new Date();
  columns: Array<any> = [];
  data: Array<any> = new Array<any>();
  objectKeys: Array<string> = new Array<string>();
  items = [];
  filterPrebids = false;
  filterMarked = false;
  reportId = 0;
  user: User = new User();

  exportConstants: ExportConstants = new ExportConstants();
  exportUrl: SafeUrl;
  exportFilename: string;

  @ViewChild('exportLink') exportLink: ElementRef;
  @ViewChild('details') detailDialog: AuctionTransactionDetailComponent;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dateFilterItems = [];
  dataGridInstance: DataGrid;
  gridColumns = [];
  allMode: string;
  checkBoxesMode: string;
  selectedRows: Array<number> = [];
  gridItems = [];
  allAuctionClusterNotificationTimeSent: Array<AuctionClusterNotificationTimeSent> = new Array<AuctionClusterNotificationTimeSent>();
  lastTimeSentNotification = '';

  constructor(
    protected injector: Injector,
    private transactionMonitorService: AuctionTransactionMonitorService,
    private reportService: AuctionClusterReportService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private translateService: TranslateService,
    private notificationService: AuctionClusterNotificationService,
    private userService: UserService
  ) {
    super(injector, TransactionMonitorLotRow);
    this.title.set('TRANSACTION_MONITOR.TITLE');
    this._subscription = this.language.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  async ngOnInit() {
    this.setTranslations('TRANSACTION_MONITOR');

    this.auctionClusterId = this.id;
    this.getReportDesignId();
    this.filterCatalogs();
    this.getAllAuctionClusterNotificationTimeSent();

    this.user = await this.userService.getCurrentUser().toPromise();
  }

  ngOnDestroy() {
    // Make sure to call ngOnDestroy method of inherited class to do the necessary
    // unsubscriptions and other cleanups which might cause app to hang if not propery handled
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    if (!this.auctionClusterId || !this.catalogId) {
      return;
    }

    // Fetch lots without columns refresh
    this.fetchLots(false);
  }

  fetchLots(refreshColumns: boolean) {
    // Reset any existing filtering if column refresh is requested
    if (refreshColumns) {
      this.columns = [];
    }

    this.data = [];
    this.items = [];
    var dateFormatted = moment(this.date).local().toISOString();
    this.spinner.show();

    this.transactionMonitorService.getLots(this.auctionClusterId, this.catalogId, dateFormatted)
      .subscribe(result => {
        this.currentLot = result;
        this.items = result.rows;
        if (refreshColumns) {
          // Parse columns only if explicitly requested
          this.parseColumns(result.columnTitles);
        }
        this.parseObject(result.rows, result.columnTitles);
        this.generateTable(result);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  exportToCsv() {
    let csvString = '';
    let delimiter = ';';
    var currentLanguageCode = this.translateService.currentLang;

    this.language.getLanguages().subscribe(l => {
      var currentLanguge = l.filter(x => x.code === currentLanguageCode)[0]
      if (currentLanguge !== undefined) {
        delimiter = currentLanguge.csvDelimiter;
      }
    });

    this.columns.forEach(c => {
      csvString += `"${c.name}"${delimiter}`;
    });

    csvString += `
    `;

    this.data.forEach(d => {
      this.objectKeys.forEach(key => {
        // Remove line breaks for export
        let value = this.removeLineBreaks(d[key]);
        csvString += `"${value}"${delimiter}`;
      });
      csvString += `
      `;
    });

    var blob = new Blob([this.exportConstants.BLOB_HEADER + csvString], { type: this.exportConstants.BLOB_TYPE });
    this.exportUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    var title = this.titleService.text.getValue();
    this.exportFilename = `${title}.${this.exportConstants.FILE_EXTENSION_CSV}`;

    window.setTimeout(() => { // anchor requires time interval to refresh its state
      this.exportLink.nativeElement.click();
      csvString = '';
    });
  }

  clearAll() {
    this.data.forEach(d => {
      if (d.__item__) {
        d.__item__.selected = false;
      }
    });
  }

  private parseObject(rows: Array<TransactionMonitorLotRow>, columns: Array<TransactionMonitorLotColumn>) {
    this.data = [];

    // Ignore parsing if there are no columns defined
    if (this.columns.length === 0) {
      return;
    }

    rows.forEach(row => {
      if (row.groupedIds) {
        if (row.groupedIds.length == 0) {
          row.values.unshift("");
        }
        else {
          let ids = "";
          row.groupedIds.forEach((id, i) => {
            ids += id;
            if (i != (row.groupedIds.length - 1)) {
              ids += ", ";
            }
          });
          row.values.unshift(ids);
        }

      }
      var obj = new Object();
      this.columns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypeEnum.DATE) {
          if(column.propertyTypeFormatId == DateTimeTypeEnum.SHORT_DATE)
          {
            let date = new Date(row.values[i]);
            date.setHours(12);
            date.setMinutes(0);
            obj['key' + i] = (date).toISOString();
          }
          else
          {
            obj['key' + i] = this.addLineBreaks(row.values[i], column.propertyTypeId, column.propertyTypeFormatId);
          }
        }
        else if (column.propertyTypeId === ProductPropertyTypeEnum.TEXT
          || column.propertyTypeId === ProductPropertyTypeEnum.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypeEnum.IMAGE
          || column.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i]; //this.format(Number(row.values[i]), column.propertyTypeId);
        }
      });
      obj['__item__'] = row;

      this.data.push(obj);
    });

    // Reset default page size
    this.setPageSize(this.pageSizeValue);
  }

  private parseColumns(columns: Array<TransactionMonitorLotColumn>) {
    var _columns = [];

    // Additional columns for displaying transaction lot ids
    _columns.push({
      name: this.getTranslation(this.translations.IDS),
      propertyTypeId: ProductPropertyTypeEnum.TEXT,
      propertyTypeFormatId: null
    });

    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });

    var _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.objectKeys = _objectKeys;
    this.columns = _columns;
    this.createDataSchema();
  }

  filterLots() {
    // Fetch data and force columns refresh
    this.fetchLots(true);
  }

  filterCatalogs() {
    if (!this.auctionClusterId) {
      this.catalogs = [];
      this.catalogId = null;
      return;
    }

    this.transactionMonitorService.getCatalogsForPermissions(this.auctionClusterId, [AuctionClusterPermissionEnum.TransactionMonitor])
      .subscribe(result => {
        this.catalogs = result;
        if (this.catalogs.length !== 0) {
          this.catalogId = this.catalogs[0].catalogId;
          this.auctionId = this.catalogs[0].auctionId;
        }
      });
  }

  anyRowSelected(excludeDeleted: boolean) {
    if (!this.data) {
      return false;
    }
    var selectedData = this.data.filter(d => d.__item__ && d.__item__.selected === true);

    if (excludeDeleted && selectedData.some(d => d.__item__.isDeleted)) {
      return false;
    }
    return selectedData.length > 0;
  }

  printTransactions() {
    var ids = this.data.filter(d => d.__item__.selected).map(d => d.__item__.id);

    this.spinner.show();
    this.transactionMonitorService.printTransactions(this.auctionClusterId, this.catalogId, ids)
      .subscribe(r => {
        this.clearAll();
        this.spinner.hide();
      }, error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  dateChanged(dateTime: Moment) {
    this.date = moment(dateTime).toDate();
  }

  sendNotification(event: Event) {
    this.spinner.show();
    var dateFormatted = moment(this.date).local().format('YYYY-MM-DD');
    this.notificationService.sendNotifications(this.auctionId, NotificationTypeEnum.TransactionSummary, 
      this.catalogId, null, dateFormatted,new Date().getTimezoneOffset())
    .subscribe((result) => {
      //refresh last sent
      this.getAuctionClusterNotificationLastTimeSent();
      this.spinner.hide();
      this.getAllAuctionClusterNotificationTimeSent();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  async resendTransactions() {
    this.spinner.show();
    var idList = this.data.filter(d => d.__item__.selected).map(d => d.__item__.id);
    try {
      await this.transactionMonitorService.resendTransactions(this.auctionClusterId, idList).toPromise();
    }
    catch (error) {
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  editTransaction = (e: any) => {
    this.dataGridInstance.editRow(e.row.dataIndex);
  }

  openEditor = (e: any) => {
    this.detailDialog.open(this.auctionClusterId, e.row.key.id);
  }

  rejectTransaction = async (e: any) => {
    this.spinner.show();
    try {
      var transactionId = e.row.key.id;
      await this.transactionMonitorService.rejectTransaction(this.auctionClusterId, transactionId).toPromise();

      this.getData();
    }
    catch (error) {
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  askConfirmation = async (e: any) => {
    this.spinner.show();
    try {
      var transactionId = e.row.key.id;
      await this.transactionMonitorService.askForConfirmation(this.auctionClusterId, transactionId).toPromise();

      this.getData();
    }
    catch (error) {
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  cancelConfirmation = async (e: any) => {
    this.spinner.show();
    try {
      var transactionId = e.row.key.id;
      await this.transactionMonitorService.cancelConfirmation(this.auctionClusterId, transactionId).toPromise();

      this.getData();
    }
    catch (error) {
      this.errorService.show(error);
    }
    finally {
      this.spinner.hide();
    }
  }

  updateTransaction = async (e: any) => {
    if (e.newData.Price != e.oldData.Price) {
      this.spinner.show();
      try {
        var transactionId = e.key.id;
        await this.transactionMonitorService.updateTransactionPrice(this.auctionClusterId, transactionId, e.newData.Price, false).toPromise();

        this.getData();
      }
      catch (error) {
        this.errorService.show(error);
      }
      finally {
        this.spinner.hide();
      }
    }
  }

  getAuctionClusterNotificationLastTimeSent() {
    this.notificationService.getAuctionClusterNotificationLastTimeSent(NotificationTypeEnum.TransactionSummary, this.auctionClusterId, this.catalogId)
    .subscribe((res: Date) => {
      if (res != null) {
        this.lastTimeSentNotification =  moment.utc(res).local().format('DD.MM.YYYY. HH:mm:ss');
      }
      else {
        this.lastTimeSentNotification = '';
      } 
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  getAllAuctionClusterNotificationTimeSent() {
    this.notificationService.getAllAuctionClusterNotificationTimeSent(NotificationTypeEnum.TransactionSummary, this.auctionClusterId)
    .subscribe((res: Array<AuctionClusterNotificationTimeSent>) => {
      if (res != null) {
        this.allAuctionClusterNotificationTimeSent = [];
        res.forEach(element => {
          this.allAuctionClusterNotificationTimeSent.push(element);
        });

        this.setAuctionClusterNotificationLastTimeSent();
      }
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }

  setAuctionClusterNotificationLastTimeSent() {
    this.lastTimeSentNotification = '';

    this.allAuctionClusterNotificationTimeSent.forEach(element => {
      if (this.catalogId === element.catalogId) {
        this.lastTimeSentNotification =  moment.utc(element.lastTimeSent).local().format('DD.MM.YYYY. HH:mm:ss');
      }
    });
  }

  getReportDesignId() {
    this.reportService.getReportBySystemReportDesignType(this.auctionClusterId, SystemReportDesignTypes.TransactionMonitorReport).subscribe(report => {
      this.reportId = report.reportId;
    });
  }

  openReport = (e: any) => {
    let url = '/#/auction/reports/' + this.auctionClusterId + '/report/' + this.reportId + '?clockStartGuid=' + e.row.data.clockStartGuid;
    window.open(url);
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
  }

  generateTable = (rows: any) => {
    this.gridColumns = [];
    var gridCurrentColums = this.dataGridInstance.getVisibleColumns();

    if (!gridCurrentColums.some(col => col.dataField === this.translations.IDS)) {
      this.dataGridInstance.addColumn({
        dataField: this.getTranslation(this.translations.IDS),
        caption: this.translations.IDS,
        visibleIndex: 0,
        allowEditing: false,
        encodeHtml: false,
        formItem: { visible: false },
        editorOptions: {
          showClearButton: true
        }
      });
    }
    this.gridColumns.push(this.translations.IDS);


    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (this.gridColumns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (this.gridColumns.includes(columnName));
      }
      this.gridColumns.push(columnName);
      var isColumn = gridCurrentColums.find(c => c.dataField === columnName);
      var isEditable = row.uniqueName == 'PRICE';
      if (!isColumn) {
        this.dataGridInstance.addColumn({
          dataField: columnName,
          name: row.uniqueName,
          caption: this.getTranslation(row.name),
          visibleIndex: i + 1,
          allowEditing: isEditable,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          cellTemplate: this.assignDefaultCellTemplate(row.propertyTypeId, row.propertyTypeFormatId),
          formItem: {
            editorOptions: {
              inputAttr: { 'style': `background-color: ${isEditable ? '#FFFFFF' : '#EEEEEE'}` }
            }
          }
        });
      }
    });

    this.gridColumns.push('id');
    if (!gridCurrentColums.some(col => col.dataField === 'id')) {
      this.dataGridInstance.addColumn({
        dataField: 'id',
        caption: 'id',
        visible: false,
        formItem: { visible: false },
        allowEditing: false
      });
    }

    this.gridColumns.push('clockStartGuid');
    if (!gridCurrentColums.some(col => col.dataField === 'clockStartGuid')) {
      this.dataGridInstance.addColumn({
        dataField: 'clockStartGuid',
        caption: 'clockStartGuid',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('isTransactionAutoCreated');
    if (!gridCurrentColums.some(col => col.dataField === 'isTransactionAutoCreated')) {
      this.dataGridInstance.addColumn({
        dataField: 'isTransactionAutoCreated',
        caption: 'isTransactionAutoCreated',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('canAskForConfirmation');
    if (!gridCurrentColums.some(col => col.dataField === 'canAskForConfirmation')) {
      this.dataGridInstance.addColumn({
        dataField: 'canAskForConfirmation',
        caption: 'canAskForConfirmation',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('canEditTransaction');
    if (!gridCurrentColums.some(col => col.dataField === 'canEditTransaction')) {
      this.dataGridInstance.addColumn({
        dataField: 'canEditTransaction',
        caption: 'canEditTransaction',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('status');
    if (!gridCurrentColums.some(col => col.dataField === 'status')) {
      this.dataGridInstance.addColumn({
        dataField: 'status',
        caption: 'status',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('transactionConfirmationStatus');
    if (!gridCurrentColums.some(col => col.dataField === 'transactionConfirmationStatus')) {
      this.dataGridInstance.addColumn({
        dataField: 'transactionConfirmationStatus',
        caption: 'transactionConfirmationStatus',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.gridColumns.push('isDeleted');
    if (!gridCurrentColums.some(col => col.dataField === 'isDeleted')) {
      this.dataGridInstance.addColumn({
        dataField: 'isDeleted',
        caption: 'isDeleted',
        formItem: { visible: false },
        visible: false,
        allowEditing: false
      });
    }

    this.generateTableData();
  }

  assignDefaultCellTemplate(propertyTypeId: ProductPropertyTypeEnum, propertyTypeFormatId?: UrlTypeEnum) {
    /*if (propertyTypeId === ProductPropertyTypeEnum.URL && propertyTypeFormatId === UrlTypeEnum.IMAGE_URL) {
      return 'imageCellTemplate';
    } else */if (propertyTypeId === ProductPropertyTypeEnum.TEXT) {
      return 'textCellTemplate';
    } else {
      return 'cellTemplate';
    }
  }


  generateTableData() {
    this.gridItems = [];
    this.data.forEach((row, i) => {
      let gridItem = '';
      this.gridColumns.forEach((column, j) => {
        if (column === 'id') {
          gridItem += '"' + column + '" : "' + row.__item__.id + '",';
        } else if (column === 'clockStartGuid') {
          gridItem += '"' + column + '" : "' + row.__item__.clockStartGuid + '",';
        } else if (column === 'isTransactionAutoCreated') {
          gridItem += '"' + column + '" : "' + row.__item__.isTransactionAutoCreated + '",';
        } else if (column === 'canEditTransaction') {
          gridItem += '"' + column + '" : "' + this.catalogs.find(c => c.catalogId == row.__item__.catalogId).allowEditTransactionPrice + '",';
        } else if (column === 'canAskForConfirmation') {
          gridItem += '"' + column + '" : "' + this.catalogs.find(c => c.catalogId == row.__item__.catalogId).allowMarkForConfirmation + '",';
        } else if (column === 'status') {
          gridItem += '"' + column + '" : "' + row.__item__.status + '",';
        } else if (column === 'transactionConfirmationStatus') {
          gridItem += '"' + column + '" : "' + row.__item__.transactionConfirmationStatus + '",';
        } else if (column === 'isDeleted') {
          gridItem += '"' + column + '" : "' + row.__item__.isDeleted + '",';
        } else {
          gridItem += '"' + column + '" : "' + this.getTranslation(row['key' + j]) + '",';
        }
      });
      this.gridItems.push(JSON.parse('{ ' + gridItem.slice(0, -1) + '}'));
    });

    var rows = this.dataGridInstance.getDataSource();
    if (rows === null) {
      if (this.gridItems.length > 0) {
        this.dataGridInstance.option('dataSource', this.gridItems);
      }
    }
    else {
      this.dataGridInstance.option('dataSource', this.gridItems);
    }
  }

  isTransactionNotAutoCreated = (e: any) => {
    return e.row.data.isTransactionAutoCreated === 'true';
  }

  canEditTransaction = (e: any) => {
    return e.row.data.canEditTransaction === 'true' && e.row.data.status == LotStatus.Sold && e.row.data.transactionConfirmationStatus != ConfirmationStatus.Disabled && e.row.data.isDeleted === 'false';
  }

  canAskForConfirmation = (e: any) => {
    return e.row.data.canAskForConfirmation === 'true' && e.row.data.status == LotStatus.Sold && e.row.data.transactionConfirmationStatus != ConfirmationStatus.Disabled && e.row.data.transactionConfirmationStatus != ConfirmationStatus.WaitingForConfirmation && e.row.data.isDeleted === 'false';
  }

  isAwaitingConfirmation = (e: any) => {
    return e.row.data.transactionConfirmationStatus == ConfirmationStatus.WaitingForConfirmation;
  }

  confirmationStatusText = (e: any) => {
    switch (Number(e.transactionConfirmationStatus)) {
      case ConfirmationStatus.Rejected:
        return 'WITHDRAWN';
      case ConfirmationStatus.Confirmed:
        return 'CONFIRMED';
      default:
        return '';
    }
  }

  buttonsVisible = () => {
    if (this.dataGridInstance != undefined) {
      var rows = this.dataGridInstance.getVisibleRows();
      return (rows.some(r => r.data.isTransactionAutoCreated === 'false') && this.reportId && this.reportId !== 0) || rows.some(r => r.data.status == LotStatus.Sold && r.data.isDeleted == 'false' && (r.data.canEditTransaction == 'true' || r.data.canAskForConfirmation == 'true'));
    }
  }

  statusVisible = () => {
    if (this.dataGridInstance != undefined) {
      var rows = this.dataGridInstance.getVisibleRows();
      return (rows.some(r => (r.data.canEditTransaction == 'true' || r.data.canAskForConfirmation == 'true')));
    }
  }

  canRejectTransaction = (e: any) => {
    return e.row.data.isDeleted === 'false' && e.row.data.status == LotStatus.Sold && e.row.data.transactionConfirmationStatus != ConfirmationStatus.Disabled && e.row.data.canAskForConfirmation === 'true';
  }

  selectionChangedHandler = (e: any) => {
    this.data.forEach(d => {
      d.__item__.selected = false;
    });
    this.selectedRows.forEach(row => {
      var index = this.gridItems.findIndex(data => data === row);
      this.data[index].__item__.selected = true;
    });
  }

  editRowProperties(e: any) {
    if (e.rowType == 'data') {
      if (e.data.isDeleted == 'true') {
        e.rowElement.style.textDecoration = 'line-through';
      }

      if (e.data.transactionConfirmationStatus != ConfirmationStatus.None && e.data.transactionConfirmationStatus != ConfirmationStatus.Disabled) {
        var backgroundColor = '#FFFFFF'
        // the plus character forces the enum to compare as number,
        // because trying to use enums as a switch/case parameter is a good reminder that under the fancy typescript hood this is still all javascript fuckery
        switch (+e.data.transactionConfirmationStatus) { 
          case ConfirmationStatus.WaitingForConfirmation:
            backgroundColor = '#FF9955'; // orange
            break;
          case ConfirmationStatus.Rejected:
            backgroundColor = '#FF8888'; // red
            break;
          case ConfirmationStatus.Confirmed:
            backgroundColor = '#71B74B'; // green
            break;
        }

        e.rowElement.style.background = backgroundColor;
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", ""); // needed because alternate rowcolors override our custom rowcolor
        e.rowElement.className += " inverted";
      }
    }
  }

  onDetailsComponentClosed(e: any) {
    this.getData();
  }
}
