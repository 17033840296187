<div class="master-details-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-switch *ngIf="isMultiEditorModeEnabled"
              class="switch-button" 
              [value]="!isTouchMode"
              switchedOffText="Touch"
              switchedOnText="Desktop"
              [width]="80"
              (onValueChanged)="switchEditorMode()"
              hint="{{ 'LOTS.CHANGE_SUPPLY_EDITOR_MODE' | translate }}"></dx-switch>
    <dx-button hint="{{ 'SHARED.ADD' | translate }}" icon="add" (onClick)="add()"></dx-button>
  </div>
  <br/>
  <dx-data-grid #masterDetailsGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                (onInitialized)="initColumn($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [(selectedRowKeys)]="selectedRows"
                (onRowClick)="rowClick($event)"
                (onSelectionChanged)="selectionChangedHandler($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection *ngIf="!isTouchMode"
                   [selectAllMode]="allMode"
                   [showCheckBoxesMode]="checkBoxesMode"
                   mode="multiple"></dxo-selection>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-state-storing [enabled]="true" type="localStorage" [storageKey]="'storage_master_details_'+ id"></dxo-state-storing>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column dataField="__buttons" type="buttons" [width]="100" [showInColumnChooser]="false">
      <dxi-button *ngIf="!isTouchMode" hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="editItem"></dxi-button>
      <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="parseLotItems" [visible]="viewGridDeleteIcon"></dxi-button>
      <dxi-button *ngIf="!isForecastCatalog" hint="{{ 'SHARED.PRINT' | translate }}" icon="print" [onClick]="printItem"></dxi-button>
    </dxi-column>
    <dxi-column dataField="isPublished" caption="{{ 'LOTS.IS_PUBLISHED' | translate }}" [cellTemplate]="'iconTemplate'">
    </dxi-column>
    <dxi-column [visible]="!isForecastCatalog" dataField="isInManualGroup" caption="{{ 'AUCTION.MASTER_DETAILS.IS_IN_MANUAL_GROUP' | translate }}" [cellTemplate]="'iconTemplate'">
    </dxi-column>
    <div *dxTemplate="let data of 'iconTemplate'">
      <i *ngIf="data.value" class="dx-icon-check"></i>
      <i *ngIf="!data.value" class="dx-icon-close"></i>
    </div>
  </dx-data-grid>

  <dx-popup width="90%"
            height="500"
            [showTitle]="true"
            title="{{ 'GROUP_FILTER.TITLE' | translate }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="groupFiltersShowing">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <group-filter-component #groupFilter (close)="hideGroupFilter()"></group-filter-component>
        </div>
    </dx-scroll-view>
  </dx-popup>

  <button type="button" class="btn btn-primary" (click)="publishLots()" [disabled]="!data || data.length <= 0">{{ 'LOTS.PUBLISH' | translate }}</button>
  <button *ngIf="catalog && !isForecastCatalog && !isTouchMode && (catalog.typeOfGrouping === 2 || catalog.typeOfGrouping === 3)" type="button" class="btn btn-primary" (click)="showGroupFilters()" [disabled]="!data || data.length <= 0">{{ 'GROUP_FILTER.TITLE' | translate }}</button>
  <!--<button [disabled]="!anyRowSelected()" type="button" class="btn btn-primary" (click)="manuallyGroupSelected()">{{ 'AUCTION.MASTER_DETAILS.MANUALLY_GROUP_SELECTED' | translate }}</button>-->
  <button type="button" class="btn btn-primary" (click)="goBack()">{{ 'SHARED.BACK' | translate }}</button>
  <button type="button" class="btn btn-primary" (click)="sendNotification($event)" [disabled]="isSendNotificationDisabled()">{{ 'SHARED.SEND_NOTIFICATION' | translate }}</button>
  <span *ngIf="hasLastTimeSentNotification()" class="last-time-sent">{{ 'LOTS.LAST_SENT_NOTIFICATION' | translate }} {{lastTimeSentNotification}}</span>
  
  <publish-lot-component #publishLotComponent (publish)="manualRefresh()"></publish-lot-component>
  <confirmation-component #removeLotsConfirmation title="{{ 'LOTS.DELETE_LOTS' | translate }}"
                          message="{{ (showActionsMessage ? 'LOTS.DELETE_LOTS_MESSAGE_WITH_ACTION' : 'LOTS.DELETE_LOTS_MESSAGE') | translate:{numberOfLots: itemsToRemove.length} }}"
                          buttonCloseText="DELETE" cancellable="true" (close)="removeSelectedLots();"></confirmation-component>
</div>
