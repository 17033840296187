import { ProductPropertyEditorSelectionDetails } from './product';
import { Supplier } from './supplier';
import { LookupTable } from '../../auction/shared/models/lookup-table';

export class PagedLots
{
  lots: Array<Lot>;
  rowCount: number;
}

export class Lot {
  lotId: number;
  catalogId: number;
  isPublished: boolean;
  state: number;
  alreadyServedInClock: boolean;
  prebidAmountMinPercentage: number;
  prebidAmountMaxPercentage: number;
  missingRequiredProperties: boolean;
  amountWanted: number;
  amountBought: number;
  averagePricePaid: number;
  lotProperties: Array<LotProperty> = [];
  displayLotProperties: Array<LotProperty> = [];
  lotPropertyGroups: Array<LotPropertyGroup> = [];
  maskResults: any = {};
  lookupTables: Array<LookupTable> = [];
  suppliers: Array<Supplier> = [];
  errorCode: number;
  inEditMode: boolean;

  // displayable fields
  locationName: string;
  supplierName: string;
  percentageBought: number;
  rowColor: string;

  //helperFields
  selected = true;
}

export class LotPropertyGroup {
  isHidden: boolean;
  lotPropertyGroupRows: Array<LotPropertyGroupRow> = [];

  // helper fields
  name: string;
  collapse: boolean;
}

export class LotPropertyGroupRow {
  lotProperties: Array<LotProperty> = [];
}

export class LotProperty {
  lotPropertyId: number;
  lotId: number;
  productPropertyId: number;
  intValue: number;
  decimalValue: number;
  stringValue: string;
  dateTimeValue: Date;
  masterDataListRowId: number;
  booleanValue = false;
  urlValue: string;

  displayValue: any;
  orderNumber: number;
  width: number;

  // helping fields
  name: string;
  propertyTypeId: number;
  minValue: number;
  maxValue: number;
  maxLength: number;
  translatable: boolean;
  productPropId: number;
  productPropertyEditorSelectionDetails: ProductPropertyEditorSelectionDetails = new ProductPropertyEditorSelectionDetails();
  changed: boolean;
  readOnlyOnceSelected: boolean;
  propertyTypeFormatId: number;
  masterDataListFieldId: number;
  masterDataListId: number;
  manuallyDisabled = false;
  systemProductPropertyType: number;
  selected = true;
  displayRequiredWarning = false;
  isWeighingScaleOn: boolean = false;
  valueChanged = false;

  // displayable fields
  displayUrlImageValue: string;
  displayUrlTextValue: string;
}
