import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';

import { Location } from '../models/location';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class LocationService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'auctioncluster';
  }

  getLocations(auctionClusterId: number): Observable<Array<Location>> {
    return this.webApiService.getList(this.apiPath + '/' + auctionClusterId + '/location');
  }

  getLocation(auctionClusterId: number, locationId: number): Observable<Location> {
    return this.webApiService.get(this.apiPath + '/' + auctionClusterId + '/location', locationId);
  }

  save(auctionClusterId: number, location: Location): Observable<any> {
    return this.webApiService.save<Location>(this.apiPath + '/' + auctionClusterId + '/location', location);
  }

  edit(auctionClusterId: number, location: Location): Observable<any> {
    return this.webApiService.edit<Location>(this.apiPath + '/' + auctionClusterId + '/location', location.locationId, location);
  }

  delete(auctionClusterId: number, locationId: number): Observable<any> {
    return this.webApiService.delete(this.apiPath + '/' + auctionClusterId + '/location', locationId);
  }
}
