import { Component, Output, OnInit, Injector, EventEmitter, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';

// models
import { GroupingActionOnPropertyEnum } from '../../shared/enum/groupingActionOnPropertyEnum';
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import {
  ProductProperty,
  ProductPropertyType,
  LotEditorProperty,
  ProductPropertyGroup,
  ActionOnNewLotEnum,
  ProductPropertyEditorSelectionDetails,
  AllowMultilpleValuesWithingFieldDependency,
  FocusTypeEnum,
  EffectOfMaskOnDataEnum, NonGroupingSelection, SystemProductPropertyTypesEnum
} from '../../shared/models/product';
import { Language } from '../../shared/models/language';
import { MasterData, MasterDataListField } from '../../shared/models/master-data';
import { ProductPropertyStyling } from '../../shared/models/product-property-styling';

import { Mask } from '../shared/models/mask';

// services
import { LanguageService } from '../../shared/services/language.service';

import { MaskService } from '../shared/services/mask.service';
import { MasterDataService } from '../shared/services/master-data.service';

const ESC_KEYCODE = 27;
const CONTROL_KEYCODE = 17;
const SHIFT_KEYCODE = 16;
const ALT_KEYCODE = 18;

@Component({
  selector: 'current-lot-property-component',
  templateUrl: 'current-lot-property.component.html',
  styleUrls: ['./current-lot-property.component.scss']
})
export class CurrentLotPropertyComponent extends ItemDetailsComponent<LotEditorProperty> implements OnInit {

  productPropertyTypes: Array<ProductPropertyType> = [];
  productPropertyStylings: Array<ProductPropertyStyling> = [];
  systemClockProperties: Array<string> = ['buyer', 'coinCode', 'currentLotInfo', 'gallerySeatNumber', 'price', 'rest', 'secondPrice', 'subbuyer', 'thirdPrice', 'total', 'waitTimeIndicators', 'transactioninfo', 'user', 'minAmount', 'maxAmount', 'clockRound'];
  masterDatas: Array<MasterData> = [];
  productProperties: Array<ProductProperty> = [];
  propertyGroups: Array<ProductPropertyGroup> = [];
  isMasterData: boolean;
  canBeEditable: boolean;
  editorProperty: boolean;
  clockFaceProperty: boolean;
  showTouchScreenSettings: boolean;
  masterDataListFields: Array<MasterDataListField> = [];
  auctionClusterId: number;
  property: LotEditorProperty;
  actionsOnNewLot: any = ActionOnNewLotEnum;
  actionsOnLot: any = ActionOnNewLotEnum;
  allowMultilpleValuesWithingFieldDependency: any = AllowMultilpleValuesWithingFieldDependency;
  focusTypes: any = FocusTypeEnum;
  masks: Array<Mask> = [];
  maskEffects: any = EffectOfMaskOnDataEnum;
  languages: Array<Language> = [];
  propertyGroupingShowing = false;
  automaticProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  manualProductPropertyNonGroupingSelections: Array<NonGroupingSelection> = [];
  automaticGroupingProperties = [];
  manualGroupingProperties = [];
  groupingActions: any = GroupingActionOnPropertyEnum;
  groupingActionsTranslations: any;
  showWidth = false;
  lotInPreparationProperties = false;
  isKeyEditorOpened: boolean = false;
  shortkey: string;
  isDecimalProperty: boolean = false;

  // tslint:disable:no-output-on-prefix
  @Output() onAdded = new EventEmitter<LotEditorProperty>();
  @Output() onUpdated = new EventEmitter<LotEditorProperty>();
  // tslint:enable:no-output-on-prefix

  @ViewChild('name') name: TranslatableFieldComponent;

  @Input('propertyGroupingShowing')
  set propertyGroupingShowingSetter(value: boolean) {
    this.propertyGroupingShowing = value;
  }

  @Input('showWidth')
  set showWidthSetter(value: boolean) {
    this.showWidth = value;
  }

  @Input('automaticProductPropertyNonGroupingSelections')
  set automaticProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.automaticProductPropertyNonGroupingSelections = value;
    this.setAutomaticGroupingProperties();
  }

  @Input('manualProductPropertyNonGroupingSelections')
  set manualProductPropertyNonGroupingSelectionsValues(value: Array<NonGroupingSelection>) {
    this.manualProductPropertyNonGroupingSelections = value;
    this.setManualGroupingProperties();
  }

  @Input('lotInPreparationProperties')
  set shortkeyLabelShown(value: boolean) {
    this.lotInPreparationProperties = value;
  }

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private masterDataService: MasterDataService,
    private maskService: MaskService,
  ) {
    super(injector);
    this.auctionClusterId = +this.route.snapshot.params['id'];
    this.actionsOnNewLot = Object.keys(this.actionsOnNewLot).filter(f => !isNaN(Number(f))).map(key => ({ name: this.actionsOnNewLot[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.actionsOnLot = this.actionsOnNewLot.filter(_ => _.value != 3);// tslint:disable-line:max-line-length
    this.allowMultilpleValuesWithingFieldDependency = Object.keys(this.allowMultilpleValuesWithingFieldDependency).filter(f => !isNaN(Number(f))).map(key => ({ name: this.allowMultilpleValuesWithingFieldDependency[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.focusTypes = Object.keys(this.focusTypes).filter(f => !isNaN(Number(f))).map(key => ({ name: this.focusTypes[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.maskEffects = Object.keys(this.maskEffects).filter(f => !isNaN(Number(f))).map(key => ({ name: this.maskEffects[key], value: Number(key) })); // tslint:disable-line:max-line-length
    this.groupingActions = Object.keys(this.groupingActions).filter(f => !isNaN(Number(f))).map(key => ({ name: this.groupingActions[key], value: Number(key) })); // tslint:disable-line:max-line-length

    this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
      this.groupingActionsTranslations = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('GROUPING_ACTIONS').subscribe((res: string) => {
        this.groupingActionsTranslations = res;
      });
    });
  }

  ngOnInit() {
    this.model = new LotEditorProperty();
  }

  getGroupingActionTranslated(id: number) {
    const action = this.groupingActions.find(a => a.value === id);
    if (action) {
      return ` - ${this.groupingActionsTranslations[action.name]}`;
    }

    return '';
  }


  setAutomaticGroupingProperties() {
    if (!this.model || !this.model.productPropertyId) {
      return;
    }

    const newArray = [];
    this.automaticProductPropertyNonGroupingSelections.forEach(p => {
      if (p.productPropertyId === this.model.productPropertyId) {
        newArray.push({
          id: p.productPropertyGroupingSelectionId,
          label: `${p.productPropertyName}${this.getGroupingActionTranslated(p.actionOnGrouping)}`
        });
      }
    });

    this.automaticGroupingProperties = newArray;
  }

  setManualGroupingProperties() {
    if (!this.model || !this.model.productPropertyId) {
      return;
    }

    const newArray = [];
    this.manualProductPropertyNonGroupingSelections.forEach(p => {
      if (p.productPropertyId === this.model.productPropertyId) {
        newArray.push({
          id: p.productPropertyGroupingSelectionId,
          label: `${p.productPropertyName}${this.getGroupingActionTranslated(p.actionOnGrouping)}`
        });
      }
    });

    this.manualGroupingProperties = newArray;
  }

  setName() {
    const prodProp = this.productProperties.find(p => p.productPropertyId === this.model.productPropertyId);

    if (prodProp && this.name) {
      this.name.data = prodProp.name;
    }
  }

  getMasks() {
    const ppId = this.model.productPropertyId ? this.model.productPropertyId : this.property.productPropertyId;
    const productProperty = this.productProperties.find(pp => {
      return pp.productPropertyId === ppId;
    });

    if (productProperty) {
      this.maskService.getMasksForPropertyId(this.auctionClusterId, productProperty.productId,
        productProperty.productPropertyId).subscribe(masks => {
          this.masks = masks;
        });

      if (productProperty.propertyTypeId === 2) {
        this.isDecimalProperty = true;
      } else {
        this.isDecimalProperty = false;
      }
    }

    this.setAutomaticGroupingProperties();
    this.setManualGroupingProperties();
  }

  open(properties: Array<LotEditorProperty>, property: LotEditorProperty,
    productProperties: Array<ProductProperty>, productPropertyTypes: Array<ProductPropertyType>, productPropertyStylings: Array<ProductPropertyStyling>,
    propertyGroups: Array<ProductPropertyGroup>, masterDataList: Array<MasterData>, editorProperty: boolean, clockFaceProperty: boolean, languages: Array<Language>,
    showTouchScreenSettings: boolean) {

    this.allItems = properties;
    this.productProperties = productProperties;
    this.propertyGroups = propertyGroups;
    this.masterDatas = masterDataList;
    this.productPropertyTypes = productPropertyTypes;
    this.productPropertyStylings = productPropertyStylings;
    this.editorProperty = editorProperty;
    this.clockFaceProperty = clockFaceProperty;
    this.property = property;
    this.languages = languages;
    this.showTouchScreenSettings = showTouchScreenSettings;

    const prop = this.productProperties.find(pp => pp.productPropertyId === property.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
      this.isMasterData = true;
    }
    else {
      this.isMasterData = false;
    }

    if (prop) {
      this.canBeEditable = prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop1
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop2
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop3
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop4
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinAmount
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MaxAmount
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.Multitude
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.StartPrice;
    }
    else{
      this.canBeEditable = false;
    }

    this.translateProductProperties();
    this.translateProductPropertiesSelections();

    this.masterDatas.forEach(masterData => {
      masterData.masterDataNameText = this.languageService.getTranslatableText(masterData.name);
    });

    this.getMasks();

    // Create empty JSON object for translation fields
    const emptyTranslation = {};
    this.languages.forEach(lang => {
      emptyTranslation[lang.code] = '';
    });

    if (property.productPropertySelectionId !== null && property.productPropertySelectionId !== undefined) {
      this.isEditMode = true;
      this.model = property;
      this.model.productPropertyEditorSelectionDetails = this.model.productPropertyEditorSelectionDetails ?
        this.model.productPropertyEditorSelectionDetails : new ProductPropertyEditorSelectionDetails();
      this.setAutomaticGroupingProperties();
      this.setManualGroupingProperties();
      this.isOpened = true;
      this.filterMasterDataFields();
    } else {
      this.model = property;
      this.isEditMode = false;
      this.isOpened = true;
    }
    this.model.name = this.model.name || JSON.stringify(emptyTranslation);

    if (this.model.productPropertyEditorSelectionDetails.shortkey !== undefined) {
      try {
        var e = JSON.parse(this.model.productPropertyEditorSelectionDetails.shortkey);
        this.shortkey = this.getShortkey(e);
      } catch (e) {
        this.shortkey = "";
      }
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  translateProductProperties() {
    this.productProperties.forEach(productProperty => {
      productProperty.productPropertyNameText = this.languageService.getTranslatableText(productProperty.name);
    });
  }

  translateProductPropertiesSelections() {
    this.allItems.forEach(lotEditorProperty => {
      lotEditorProperty.productPropertySelectionName = this.languageService.getTranslatableText(lotEditorProperty.name);
    });
  }

  save() {
    this.model.name = this.name.data;
    if (this.isEditMode) {
      this.onUpdated.emit(this.model);
    } else {
      this.onAdded.emit(this.model);
    }

    this.model = new LotEditorProperty();
    this.detailsForm.reset();
    this.close(true);
    this.errorMessage = null;
  }

  filterMasterDataFields() {
    if (this.editorProperty) {
      return;
    }
    const prop = this.productProperties.find(pp => pp.productPropertyId === this.model.productPropertyId);
    if (prop && prop.propertyTypeId === ProductPropertyTypeEnum.MASTER_DATA) {
      const mdl = this.masterDatas.find(m => m.masterDataListId === prop.masterDataListId);
      if (mdl) {
        this.masterDataListFields = mdl.fields;
        this.isMasterData = true;
      }
    } else {
      this.isMasterData = false;
    }
    if (prop) {
      this.canBeEditable = prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop1
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop2
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop3
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinPriceStop4
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MinAmount
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.MaxAmount
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.Multitude
        || prop.systemProductPropertyType == SystemProductPropertyTypesEnum.StartPrice;
    }
    else{
      this.canBeEditable = false;
    }
  }

  restartMasterDataField() {
    this.model.masterDataListFieldId = null;
    this.model.masterDataFieldName = null;
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }

  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE && !this.isKeyEditorOpened) {
      super.handleWindowKeyDownEvent(event);
    }
  }

  manageKeyEditorPopup(opened: boolean) {
    if (opened) {
      this.isKeyEditorOpened = true;
      window.onkeydown = (e: KeyboardEvent) => {
        switch (e.keyCode) {
          case CONTROL_KEYCODE:
          case SHIFT_KEYCODE:
          case ALT_KEYCODE:
            break;
          default: {
            this.model.productPropertyEditorSelectionDetails.shortkey = JSON.stringify({
              ctrlKey: e.ctrlKey,
              shiftKey: e.shiftKey,
              altKey: e.altKey,
              keyCode: e.keyCode,
              key: e.key
            });

            this.shortkey = this.getShortkey(e);

            window.onkeydown = null;
            this.isKeyEditorOpened = false;
            return false;
          }
        }
      };
    }
    else {
      window.onkeydown = null;
      this.isKeyEditorOpened = false;
    }
  }

  getShortkey(e: KeyboardEvent) {
    var shortkey = "";

    if (e.ctrlKey) {
      shortkey += "control+";
    }

    if (e.shiftKey) {
      shortkey += "shift+";
    }

    if (e.altKey) {
      shortkey += "alt+";
    }

    shortkey += e.key.toLowerCase();
    return shortkey;
  }

  clearShorkey() {
    this.model.productPropertyEditorSelectionDetails.shortkey = null;
    this.model.productPropertyEditorSelectionDetails.shortkeyLabel = null;
    this.shortkey = null;
  }
}
